import { ReactNode, useEffect } from 'react';
import useCustomDispatch from 'redux/dispatch';
import { RootReducerTypes } from 'redux/rootReducer';
import { handleCurrentFrontendBuildNumber } from 'redux/slices/versioning';
import { RootState, useSelector } from 'redux/store';
import releaseConfig from 'releaseConfig';

type BuildVersionGuardProps = {
  children: ReactNode;
};

const BuildVersionGuard = ({ children }: BuildVersionGuardProps) => {
  const customDispatch = useCustomDispatch();
  const { frontendVersioning } = useSelector((state: RootState) => state.versioning);

  //on reload page, handle current frontend buildNumber
  useEffect(() => {
    if (!customDispatch) return;
    customDispatch({
      action: handleCurrentFrontendBuildNumber,
      actionParameters: releaseConfig.frontendBuildNumber,
      disableAllMessages: true,
      onSuccess: (successResponse) => {
        if (successResponse?.extraSuccessInfo === RootReducerTypes.REFRESH_ON_NEW_VERSION) {
          //wipe relevant data and do a hard refresh of page
          customDispatch({
            actionParameters: { type: RootReducerTypes.REFRESH_ON_NEW_VERSION },
            disableSuccessMessage: true
          });
        }
      }
    });
  }, [customDispatch]);

  const hasWipedSinceLastBuildUpdate =
    frontendVersioning.buildNumberUsedSinceWipe === frontendVersioning.latestBuildNumber;

  return <div>{hasWipedSinceLastBuildUpdate && children}</div>;
};

export default BuildVersionGuard;
