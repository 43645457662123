import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from 'redux/store';
import { ProvidersState, ProviderType } from '../../@types/providers';

// ----------------------------------------------------------------------
const initialState: ProvidersState = {
  isLoading: {},
  currentEmailProvider: undefined,
  currentSmsProvider: undefined
};

const slice = createSlice({
  name: 'seats',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    stopLoading(state, action) {
      state.isLoading[action.payload] = false;
    },
    setCurrentEmailProvider(state, action) {
      state.currentEmailProvider = action.payload;
    },
    setCurrentSmsProvider(state, action) {
      state.currentSmsProvider = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
//export const {} = slice.actions;

// ----------------------------------------------------------------------

export const getCurrentEmailProvider = () => {
  return async () => {
    dispatch(slice.actions.startLoading('getCurrentEmailProvider'));
    try {
      const response = await axios.get('/organization/emailprovider');
      dispatch(slice.actions.setCurrentEmailProvider(response.data));
      dispatch(slice.actions.stopLoading('getCurrentEmailProvider'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Current email provider fetched'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading('getCurrentEmailProvider'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch current email provider'
      });
    }
  };
};

export const getCurrentSmsProvider = () => {
  return async () => {
    dispatch(slice.actions.startLoading('getCurrentSmsProvider'));
    try {
      const response = await axios.get('/organization/smsprovider');
      dispatch(slice.actions.setCurrentSmsProvider(response.data));
      dispatch(slice.actions.stopLoading('getCurrentSmsProvider'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Current sms provider fetched'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading('getCurrentSmsProvider'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch current sms provider'
      });
    }
  };
};

type ChangeProviderProps = {
  providerType: ProviderType;
  newProviderName: string;
};
export const changeProvider = ({ providerType, newProviderName }: ChangeProviderProps) => {
  return async () => {
    dispatch(slice.actions.startLoading('changeProvider'));
    try {
      const response = await axios.put(`/organization/${providerType}provider`, {
        name: newProviderName
      });
      const newProvider = {
        provider: newProviderName
      };
      switch (providerType) {
        case ProviderType.EMAIL:
          dispatch(slice.actions.setCurrentEmailProvider(newProvider));
          break;
        case ProviderType.SMS:
          dispatch(slice.actions.setCurrentSmsProvider(newProvider));
          break;
        default:
          break;
      }

      dispatch(slice.actions.stopLoading('changeProvider'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: `${providerType} provider changed to ${newProviderName}`
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading('changeProvider'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not change ${providerType} provider to ${newProviderName}`
      });
    }
  };
};
