import { Prompt } from 'react-router-dom';
import { RootState, useSelector } from 'redux/store';

const UnsavedChangesPrompt = () => {
  const { hasUnsavedChanges } = useSelector((state: RootState) => state.editing);
  return (
    <Prompt
      when={hasUnsavedChanges}
      message="You have made unsaved changes. If you continue, these changes are lost."
    />
  );
};

export default UnsavedChangesPrompt;
