import { FunctionComponent } from 'react';
import { Integration } from '../../../@types/integrations';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

interface IntegrationLogoProps {
  integration: Integration | null;
  fallbackText?: string;
}

export const IntegrationLogo: FunctionComponent<IntegrationLogoProps> = ({
  integration,
  fallbackText
}) => {
  return integration?.logo ? (
    <LogoWrapper>
      <Logo alt={`${integration?.name} logo`} src={integration?.logo || undefined} />
    </LogoWrapper>
  ) : (
    <Typography>{fallbackText}</Typography>
  );
};

const LogoWrapper = styled('div')({
  height: '2rem',
  display: 'flex'
});

const Logo = styled('img')({
  width: '100%',
  maxHeight: '100%',
  marginBlock: 'auto'
});
