import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GridColDef } from '@mui/x-data-grid';
import MoreMenuButton from 'components/util/MoreMenuButton';
import ViewImageModal from 'components/util/ViewImageModal';
import { useState, useEffect } from 'react';
import useCustomDispatch from 'redux/dispatch';
import { deleteLogo, getLogos } from 'redux/slices/organization';
import { RootState, useSelector } from 'redux/store';
import {
  CustomDataGrid,
  CustomGridToolbar,
  CustomLoadingOverlay,
  CustomPagination
} from '../../../components/datagrid/Custom';
import { DataGridKey } from 'redux/slices/datagrid';

const LogosList = () => {
  const { logos, isLogoLoading } = useSelector((state: RootState) => state.organization);
  const customDispatch = useCustomDispatch();

  useEffect(() => {
    customDispatch({ action: getLogos, disableSuccessMessage: true });
  }, [customDispatch]);

  const [displayImgUrl, setDisplayImgUrl] = useState('');
  const DATA_GRID_KEY = DataGridKey.LogoList;

  const ThumbImgStyle = styled('img')(({ theme }) => ({
    maxHeight: '100%',
    margin: theme.spacing(0, 2),
    '&:hover': {
      cursor: 'zoom-in',
      '@media all and (display-mode: fullscreen)': {
        cursor: 'zoom-out'
      }
    }
  }));

  const columns: GridColDef[] = [
    {
      field: 'cover',
      headerName: 'Logo',
      align: 'center',
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <ThumbImgStyle src={params.row.url} onClick={() => setDisplayImgUrl(params.row.url)} />
        );
      }
    },
    {
      field: 'fileName',
      headerName: 'File name',
      flex: 2
    },
    {
      field: 'edit',
      sortable: false,
      filterable: false,
      headerName: '',
      editable: false,
      disableColumnMenu: true,
      hideable: false,
      align: 'center',
      hideSortIcons: true,
      renderCell: (cellValues) => {
        const res = cellValues.row;
        return (
          <MoreMenuButton
            itemType="logo"
            onDelete={async () => {
              customDispatch({ action: deleteLogo, actionParameters: res.fileName });
            }}
          />
        );
      }
    }
  ];

  return (
    <>
      <Box>
        <CustomDataGrid
          dataGridKey={DATA_GRID_KEY}
          autoHeight
          loading={isLogoLoading}
          pageSize={25}
          pagination
          disableSelectionOnClick
          rows={logos?.map((logoUrl, index) => {
            const startOfFileNameIndex = logoUrl.lastIndexOf('/') + 1;
            const fileName = logoUrl.substring(startOfFileNameIndex);
            return { url: logoUrl, fileName: fileName, id: index };
          })}
          columns={columns}
          components={{
            Toolbar: CustomGridToolbar,
            Pagination: CustomPagination,
            LoadingOverlay: CustomLoadingOverlay
          }}
        />
      </Box>
      <ViewImageModal imgUrl={displayImgUrl} resetImageUrl={() => setDisplayImgUrl('')} />
    </>
  );
};

export default LogosList;
