import { Stack } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslator } from 'translation/useTranslator';
import { Organisation } from '../../../@types/organization';
import { SummaryList } from 'components/summary/SummaryList';

interface OrganizationActiveStateSummaryProps {
  selectedOrganizations: Organisation[];
}
export const OrganizationActiveStateSummary: FunctionComponent<
  OrganizationActiveStateSummaryProps
> = ({ selectedOrganizations }) => {
  const translator = useTranslator();
  const orgsWhichWillBeMadeInactive = selectedOrganizations
    .filter((o) => o.active === true)
    .map((o) => o.organizationName);
  const orgsWhichWillBeMadeActive = selectedOrganizations
    .filter((o) => o.active === false)
    .map((o) => o.organizationName);

  return (
    <Stack gap={2}>
      <SummaryList
        items={orgsWhichWillBeMadeInactive}
        title={translator.makeOrganizationsInactive()}
        active={false}
      />
      <SummaryList
        items={orgsWhichWillBeMadeActive}
        title={translator.makeOrganizationsActive()}
        active={true}
      />
    </Stack>
  );
};
