import { IconType } from '../../../@types/icons';
import StatusIcon from './StatusIcon';

type EnabledIconProps = {
  tooltip?: string;
  size?: string;
  color?: string;
};
const EnabledIcon = ({ tooltip, size, color = 'success.main' }: EnabledIconProps) => {
  return (
    <StatusIcon tooltip={tooltip ?? 'Enabled'} size={size} color={color} icon={IconType.success} />
  );
};

export default EnabledIcon;
