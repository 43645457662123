//import { styled } from '@mui/material';
import { useTheme } from '@mui/material';
import Icon from 'components/icons/Icon';
const BetaIcon = () => {
  const theme = useTheme();
  return <Icon type="beta" style={{ color: theme.palette.primary.main, fontSize: '1rem' }} />;
  //return <StyledBetaIcon>BETA</StyledBetaIcon>;
};

export default BetaIcon;

/*
const StyledBetaIcon = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.text.primary,
  fontSize: '.5rem',
  lineHeight: '.5rem',
  padding: '.2rem',
  borderRadius: '.2rem',
  fontFamily: 'monospace',
  width: 'min-content',
  height: 'min-content'
}));


*/
