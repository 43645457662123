import { useEffect } from 'react';
import { useCustomDispatchWithoutPromise } from 'redux/dispatch';
import { setHasUnsavedChanges } from 'redux/slices/editing';

export const useRegisterUnsavedFormChanges = (isFormDirty: boolean) => {
  const customDispatchWithoutPromise = useCustomDispatchWithoutPromise();

  useEffect(() => {
    customDispatchWithoutPromise({
      action: setHasUnsavedChanges,
      actionParameters: isFormDirty,
      disableAllMessages: true
    });

    //set hasUnsavedChanges to false when unmounting
    return () => {
      customDispatchWithoutPromise({
        action: setHasUnsavedChanges,
        actionParameters: false,
        disableAllMessages: true
      });
    };
  }, [isFormDirty, customDispatchWithoutPromise]);
};
