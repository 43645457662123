import { createSlice } from '@reduxjs/toolkit';
import { Response, ResponseDataState } from '../../@types/response';
// utils
import axios from '../../utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: ResponseDataState = {
  isLoading: false,
  response: null,
  isDeleteLoading: false
};

const slice = createSlice({
  name: 'response',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      // state.responseData = initialState.responseData;
      state.isLoading = true;
    },

    // START LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    setResponse(state, action) {
      state.response = action.payload;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setResponse } = slice.actions;

// ----------------------------------------------------------------------

export function editResponse(response: Response) {
  return async () => {
    await axios
      .put('/responses/' + response.campaignMembershipGuid, response)
      .catch((error: any) => {
        return Promise.reject({
          error: error,
          defaultErrorMessage: 'Response could not be edited'
        });
      });

    dispatch(slice.actions.setResponse({ ...response }));
    return Promise.resolve({
      defaultSuccessMessage: 'Edited response'
    });
  };
}

export function resendSurveys({ campaignId, ids }: { campaignId: number; ids: number[] }) {
  return async () => {
    let resendSurveysCounter = 0;
    let currentRespondentId = 0;
    try {
      for (const respondentId of ids ?? []) {
        currentRespondentId = respondentId;
        await axios.post(`/campaigns/${campaignId}/respondents/${respondentId}/resend`);
        resendSurveysCounter++;
      }
      return await Promise.resolve({
        defaultSuccessMessage: `${resendSurveysCounter} survey${
          resendSurveysCounter > 1 ? 's' : ''
        } was resend`
      });
    } catch (error) {
      return await Promise.reject({
        error: error,
        defaultErrorMessage: currentRespondentId
          ? `Could not resend survey with id ${currentRespondentId}`
          : `An error occurred while resending surveys`
      });
    }
  };
}
