import { createSlice } from '@reduxjs/toolkit';
import { TimeSpan } from '../../@types/dashboard';
import { Campaign, CampaignState, CampaignSummary } from '../../@types/campaign';
// utils
import axios from '../../utils/axios';
import { dispatch, store } from '../store';
import { getFromToFilterFromTimespan } from 'components/util/datePicker/FromToDatePicker';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import {
  combineFilters,
  getFilterQueryParam,
  getOrderbyQueryParam,
  getSkipQueryParam,
  getTopQueryParam
} from 'utils/getQueryParams';

// ----------------------------------------------------------------------
const { from: initialFrom, to: initialTo } = getFromToFilterFromTimespan(TimeSpan.ALL_TIME);
export const initialState: CampaignState = {
  isLoading: false,
  isCampaignSummaryLoading: false,
  isSaveLoading: false,
  isDeleteLoading: false,
  campaigns: { skip: 0, take: 10, total: 0, results: [] },
  campaign: null,
  campaignsSummary: [],
  availableCampaignCategories: {},
  lastUpdatedTimestamp: undefined,
  timespan: TimeSpan.ALL_TIME,
  fromDateFilter: initialFrom,
  toDateFilter: initialTo,
  campaignAnalytics: {
    spread: {
      detractors: 0,
      nps: 0,
      passives: 0,
      promoters: 0,
      total: 0
    },
    respondentCount: 0
  },
  count: 0,
  isRemindersScheduledLoading: {},
  remindersScheduled: {
    StartTime: undefined,
    EndTime: undefined,
    SendTime: new Date().getTime(),
    timespan: TimeSpan.LAST_30_DAYS,
    rescheduled: 0,
    newRemindersToSend: 0
  }
};

export const campaignPersistWhitelist = []; //Removed the following, as the persistent state of the timespan causes confusion 'timespan', 'fromDateFilter', 'toDateFilter'

const slice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START SAVE/ADD Campaign/Template
    startSaveCampaign(state) {
      state.isSaveLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    // STOP SAVE/ADD Campaign/Template
    stopSaveCampaign(state) {
      state.isSaveLoading = false;
    },

    // START Campaign Summary LOADING
    startLoadingSummary(state) {
      state.isCampaignSummaryLoading = true;
    },

    // START Campaign Summary LOADING
    stopLoadingSummary(state) {
      state.isCampaignSummaryLoading = false;
    },

    setCampaign(state, action) {
      state.campaign = action.payload;
    },

    // GET CAMPAIGNS
    getCampaignsSuccess(state, action) {
      state.isLoading = false;
      state.campaigns = action.payload;
    },

    // GET CAMPAIGN
    getCampaignSuccess(state, action) {
      state.isLoading = false;
      state.campaign = action.payload;
    },

    postCampaignSuccess(state, action) {
      state.isSaveLoading = false;
    },

    putCampaignSuccess(state, action) {
      state.isSaveLoading = false;
    },

    deleteCampaignStartLoading(state) {
      state.isDeleteLoading = true;
    },

    deleteCampaignStopLoading(state) {
      state.isDeleteLoading = false;
    },

    setCampaignsSummary(state, action) {
      const sortedCampaignsSummary = action.payload as CampaignSummary[];
      state.campaignsSummary =
        sortedCampaignsSummary?.sort(function (a, b) {
          return a?.created < b?.created ? 1 : a?.created > b?.created ? -1 : 0;
        }) || [];
    },

    setDateFilter(state, action) {
      state.fromDateFilter = action.payload.from;
      state.toDateFilter = action.payload.to;
      state.timespan = action.payload.timespan;
    },

    setCampaignAnalytics(state, action) {
      state.campaignAnalytics = action.payload;
      state.isLoading = false;
      state.lastUpdatedTimestamp = new Date().getTime();
    },

    setCampaignsCount(state, action) {
      state.count = action.payload;
    },

    setAvailableCampaignCategories(state, action) {
      state.availableCampaignCategories[action.payload.campaignId] = action.payload.categories;
    },

    addAvailableCampaignCategories(state, action) {
      state.availableCampaignCategories[action.payload.campaignId] = [
        ...state.availableCampaignCategories[action.payload.campaignId],
        action.payload.newCategory
      ];
    },

    setRemindersScheduled(state, action) {
      state.remindersScheduled = { ...state.remindersScheduled, ...action.payload };
    },

    setIsPreviewRemindersScheduledLoading(state, action) {
      state.isRemindersScheduledLoading.preview = action.payload;
    },

    setIsSetScheduledRemindersLoading(state, action) {
      state.isRemindersScheduledLoading.setReminders = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getCampaignSuccess, stopLoading, stopSaveCampaign, setRemindersScheduled } =
  slice.actions;

// ----------------------------------------------------------------------

export function getAvailableCampaignCategory(campaignId: number) {
  return async () => {
    try {
      const response = await axios.get(`/campaigns/${campaignId}/categories`);
      dispatch(
        slice.actions.setAvailableCampaignCategories({ campaignId, categories: response.data })
      );
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched available campaign categories'
      });
    } catch (error) {
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetched available campaign categories`
      });
    }
  };
}

export const updateCampaignAnalytics = (id: string | undefined) => {
  const currentState = store.getState().campaign;
  const start = currentState.fromDateFilter ? new Date(currentState.fromDateFilter) : undefined;
  const end = currentState.toDateFilter ? new Date(currentState.toDateFilter) : undefined;
  const params = {
    start,
    end,
    campaignId: id
  };
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/analytics/responses`, { params });
      dispatch(slice.actions.setCampaignAnalytics(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched response analytics'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch response analytics`
      });
    }
  };
};

export const updateDateFilter = ({
  from,
  to,
  timespan
}: {
  from: any;
  to: any;
  timespan: TimeSpan;
}) => {
  return () => {
    dispatch(
      slice.actions.setDateFilter({
        from:
          typeof from === 'number'
            ? from
            : from?.getFullYear?.()?.toString?.().length === 4
            ? from?.getTime?.()
            : undefined,
        to:
          typeof to === 'number'
            ? to
            : to?.getFullYear?.()?.toString?.().length === 4
            ? to?.getTime?.()
            : undefined,
        timespan: timespan
      })
    );
    return Promise.resolve({
      defaultSuccessMessage: 'Campaign date filter updated'
    });
  };
};

export function resetCampaign() {
  return async () => {
    dispatch(slice.actions.setCampaign(null));
    return Promise.resolve({
      defaultSuccessMessage: 'Campaign reset'
    });
  };
}

export function getCampaign(id: string | undefined) {
  return async () => {
    if (!id) {
      dispatch(slice.actions.getCampaignSuccess(null));
      return Promise.resolve({
        defaultSuccessMessage: 'Campaign cleared'
      });
    }
    dispatch(slice.actions.startLoading());

    /**
     * Look for campaign, inm already fetched campaigns. This ensures that the campaign data is showed is soon as posible.
     * Still fetch the campaign again, to ensure that the data is up to date.
     */
    const currentState = store.getState().campaign;
    const campaignInStore = currentState.campaigns?.results?.find(
      (campaign) => campaign.id === Number(id)
    );
    if (campaignInStore) {
      dispatch(slice.actions.setCampaign(campaignInStore));
    }

    try {
      const response = await axios.get('/campaigns/' + id);
      dispatch(slice.actions.getCampaignSuccess(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Updated camapign'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not get campaign with id ${id}`
      });
    }
  };
}

export function getCampaigns() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/campaigns?includeAnalytics=true');
      dispatch(slice.actions.getCampaignsSuccess(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched campaigns'
      });
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch campaigns`
      });
    }
  };
}

export function getCampaignsList({
  sortModel,
  filterModel,
  enforcedFilterModel,
  modifyFilterModel = (filterModel) => filterModel,
  top,
  skip
}: {
  sortModel: GridSortModel | undefined;
  filterModel: GridFilterModel | undefined;
  enforcedFilterModel?: GridFilterModel | undefined;
  modifyFilterModel?: (newModel?: GridFilterModel) => GridFilterModel | undefined;
  top: number;
  skip: number;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      //Sort model items, with the column name ending on '_analytics', should be removed from the oData query params,
      //and instead be used for the 'analyticsOrderBy' query param
      const campaignSortModel: GridSortModel | undefined = [];
      const analyticsSortModel: GridSortModel | undefined = [];
      sortModel?.forEach((sortField) => {
        if (sortField.field?.endsWith('_analytics')) {
          analyticsSortModel?.push({
            ...sortField,
            field: sortField.field?.replace('_analytics', '')
          });
        } else {
          campaignSortModel?.push(sortField);
        }
      });

      const filter = combineFilters({
        filters: [
          getFilterQueryParam(modifyFilterModel(filterModel)),
          getFilterQueryParam(enforcedFilterModel)
        ],
        operator: 'and'
      });

      const response = await axios.get(
        `/campaigns/list?analyticsOrderBy=${getOrderbyQueryParam(
          analyticsSortModel,
          true,
          true
        )}${getOrderbyQueryParam(campaignSortModel)}${filter}${getSkipQueryParam(
          skip
        )}${getTopQueryParam(top)}`
      );
      dispatch(slice.actions.getCampaignsSuccess(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched campaigns'
      });
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch campaigns`
      });
    }
  };
}

export function postCampaign(campaign: Campaign) {
  return async () => {
    dispatch(slice.actions.startSaveCampaign());
    const response = await axios.post('/campaigns', campaign).catch((error: any) => {
      dispatch(slice.actions.stopSaveCampaign());
      return Promise.reject({
        error: error,
        defaultErrorMessage: `Could create new campaign`
      });
    });
    dispatch(slice.actions.postCampaignSuccess(response.data));
    return Promise.resolve({
      result: response,
      defaultSuccessMessage: `Created new campaign ${campaign.name}`
    });
  };
}

export function putCampaign(campaign: Campaign) {
  return async () => {
    dispatch(slice.actions.startSaveCampaign());

    try {
      let response = await axios.put('/campaigns/' + campaign.id, campaign);
      dispatch(slice.actions.putCampaignSuccess(response?.data));
      return {
        result: response,
        defaultSuccessMessage: 'Campaign saved'
      };
    } catch (error: any) {
      dispatch(slice.actions.stopSaveCampaign());
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not save campaign'
      });
    }
  };
}

export function deleteCampaign({ campaign }: { campaign: Campaign }) {
  return async () => {
    dispatch(slice.actions.deleteCampaignStartLoading());
    try {
      await axios.delete(`/campaigns/${campaign.id}`);
      dispatch(slice.actions.deleteCampaignStopLoading());
      return await Promise.resolve({
        defaultSuccessMessage: `${campaign?.name ?? 'Campaign'} deleted.`
      });
    } catch (error: any) {
      dispatch(slice.actions.deleteCampaignStopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `${campaign?.name ?? 'Campaign'} was not deleted.`
      });
    }
  };
}

export function deleteAlert({ templateId, alertId }: { templateId: number; alertId: number }) {
  return async () => {
    await axios.delete(`/templates/${templateId}/alerts/${alertId}`).catch((error) => {
      dispatch(slice.actions.deleteCampaignStopLoading());
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not delete alert'
      });
    });
    return Promise.resolve({
      defaultSuccessMessage: 'Alert deleted'
    });
  };
}

export function deleteCategory({
  templateId,
  categoryId
}: {
  templateId: number;
  categoryId: number;
}) {
  return async () => {
    await axios.delete(`/templates/${templateId}/categories/${categoryId}`).catch((error) => {
      dispatch(slice.actions.deleteCampaignStopLoading());
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not delete category'
      });
    });
    return Promise.resolve({
      defaultSuccessMessage: 'Category deleted'
    });
  };
}

export function addCategoryToCompaign({
  campaignId,
  templateId,
  newCategory
}: {
  campaignId: number;
  templateId: string | undefined;
  newCategory: string;
}) {
  return async () => {
    if (!templateId) {
      return Promise.reject({
        defaultErrorMessage: 'Could not add category, as no template id is available'
      });
    }

    const response = await axios
      .post(`/templates/${templateId}/categories/`, {
        name: newCategory
      })
      .catch((error: any) => {
        return Promise.reject({
          error: error,
          defaultErrorMessage: 'New category could not be added'
        });
      });

    dispatch(
      slice.actions.addAvailableCampaignCategories({ campaignId, newCategory: response.data })
    );
    return Promise.resolve({
      result: response,
      defaultSuccessMessage: 'New category added'
    });
  };
}

export function forwardResponse({
  campaignId,
  campaignMemberId,
  emailTo,
  message
}: {
  campaignId: string;
  campaignMemberId: string;
  emailTo: string;
  message: string | undefined;
}) {
  return async () => {
    await axios
      .post(`/campaigns/${campaignId}/responses/${campaignMemberId}/forward`, {
        emailTo,
        message
      })
      .catch((error: any) => {
        return Promise.reject({
          error: error,
          defaultErrorMessage: 'Response could not be forwarded'
        });
      });
    return Promise.resolve({
      defaultSuccessMessage: 'Response forwarded'
    });
  };
}

export function cloneCampaign({ campaign }: { campaign: Campaign }) {
  return async () => {
    try {
      await axios.post(`/campaigns/${campaign.id}/clone`);
      return await Promise.resolve({
        defaultSuccessMessage: `${campaign?.name ?? 'Campaign'} cloned.`
      });
    } catch (error: any) {
      return Promise.reject({
        error: error,
        defaultErrorMessage: `${campaign?.name ?? 'Campaign'} was not cloned.`
      });
    }
  };
}

export const getCampaignsSummary = () => {
  return async () => {
    dispatch(slice.actions.startLoadingSummary());
    try {
      const response = await axios.get('/campaigns', {
        params: {
          min: true
        }
      });
      dispatch(slice.actions.setCampaignsSummary(response.data));
      dispatch(slice.actions.stopLoadingSummary());

      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Updated camapign summaries'
      });
    } catch (error) {
      dispatch(slice.actions.stopLoadingSummary());

      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not get campaign summary'
      });
    }
  };
};

type GetScheduledRemindersPreviewProps = {
  campaignId: number;
  StartTime?: string;
  EndTime?: string;
};
export const getScheduledRemindersPreview = ({
  campaignId,
  StartTime,
  EndTime
}: GetScheduledRemindersPreviewProps) => {
  return async () => {
    dispatch(slice.actions.setIsPreviewRemindersScheduledLoading(true));
    try {
      const response = await axios.post(`campaigns/${campaignId}/remindersPreview`, {
        StartTime: StartTime ? new Date(StartTime).toISOString() : undefined,
        EndTime: EndTime ? new Date(EndTime).toISOString() : undefined
      });
      dispatch(slice.actions.setRemindersScheduled(response.data));
      dispatch(slice.actions.setIsPreviewRemindersScheduledLoading(false));

      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched affected campaign members for scheduling of reminders'
      });
    } catch (error) {
      dispatch(slice.actions.setIsPreviewRemindersScheduledLoading(false));

      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not get a preview of affected campaign members'
      });
    }
  };
};

type ScheduleRemindersProps = {
  campaignId: number;
  StartTime?: string;
  EndTime?: string;
  SendTime?: string;
};
export const scheduleReminders = ({
  campaignId,
  StartTime,
  EndTime,
  SendTime
}: ScheduleRemindersProps) => {
  return async () => {
    const currentState = store.getState().campaign;
    const totalScheduled =
      currentState.remindersScheduled.newRemindersToSend +
      currentState.remindersScheduled.rescheduled;

    dispatch(slice.actions.setIsSetScheduledRemindersLoading(true));
    try {
      const response = await axios.post(`campaigns/${campaignId}/reminders`, {
        StartTime: StartTime ? new Date(StartTime).toISOString() : undefined,
        EndTime: EndTime ? new Date(EndTime).toISOString() : undefined,
        SendTime: SendTime ? new Date(SendTime).toISOString() : undefined
      });

      dispatch(slice.actions.setRemindersScheduled(response.data));
      dispatch(slice.actions.setIsSetScheduledRemindersLoading(false));

      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: `${totalScheduled} reminder${
          totalScheduled > 1 ? 's' : ''
        } scheduled`
      });
    } catch (error) {
      dispatch(slice.actions.setIsSetScheduledRemindersLoading(false));

      return Promise.reject({
        error: error,
        defaultErrorMessage: `Could not schedule reminder${totalScheduled > 1 ? 's' : ''}`
      });
    }
  };
};
