import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from 'redux/store';
import { ThrottlingGroup, ThrottlingGroupState } from '../../@types/throttlingGroup';

// ----------------------------------------------------------------------

const initialState: ThrottlingGroupState = {
  hasError: false,
  isLoading: {},
  throttlingGroups: []
};

const slice = createSlice({
  name: 'throttlingGroup',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    onGetThrottlingGroupsSuccess(state, action) {
      state.throttlingGroups = action.payload;
      state.isLoading.getThrottlingGroups = false;
      state.hasError = false;
    },
    onGetThrottlingGroupsError(state) {
      state.isLoading.getThrottlingGroups = false;
      state.hasError = true;
    },
    onAddThrottlingGroupSuccess(state) {
      state.isLoading.addThrottlingGroup = false;
      state.hasError = false;
    },
    onAddThrottlingGroupError(state) {
      state.isLoading.addThrottlingGroup = false;
      state.hasError = true;
    },
    onEditThrottlingGroupSuccess(state) {
      state.isLoading.editThrottlingGroup = false;
      state.hasError = false;
    },
    onEditThrottlingGroupError(state) {
      state.isLoading.editThrottlingGroup = false;
      state.hasError = true;
    },
    onDeleteThrottlingGroupSuccess(state) {
      state.isLoading.deleteThrottlingGroup = false;
      state.hasError = false;
    },
    onDeleteAddThrottlingGroupError(state) {
      state.isLoading.deleteThrottlingGroup = false;
      state.hasError = true;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

export const getThrottlingGroups = () => {
  return async () => {
    try {
      dispatch(slice.actions.startLoading('getThrottlingGroups'));

      const response = await axios.get(`/throttlingGroups`);

      dispatch(slice.actions.onGetThrottlingGroupsSuccess(response.data));
      return await Promise.resolve({
        defaultSuccessMessage: 'Throttling groups fetched'
      });
    } catch (error: any) {
      dispatch(slice.actions.onGetThrottlingGroupsError());
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch throttling groups'
      });
    }
  };
};

type AddThrottlingGroupProps = {
  name: string;
};
export const addThrottlingGroup = ({ name }: AddThrottlingGroupProps) => {
  return async () => {
    try {
      dispatch(slice.actions.startLoading('addThrottlingGroup'));

      await axios.post(`/throttlingGroups`, { name });

      dispatch(slice.actions.onAddThrottlingGroupSuccess());
      return await Promise.resolve({
        defaultSuccessMessage: 'Throttling group added'
      });
    } catch (error: any) {
      dispatch(slice.actions.onAddThrottlingGroupError());
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not add throttling group'
      });
    }
  };
};

type EditThrottlingGroupProps = {
  updatedThrottlingGroup: ThrottlingGroup;
};
export const editThrottlingGroup = ({ updatedThrottlingGroup }: EditThrottlingGroupProps) => {
  return async () => {
    try {
      dispatch(slice.actions.startLoading('editThrottlingGroup'));

      await axios.put(`/throttlingGroups/${updatedThrottlingGroup.id}`, updatedThrottlingGroup);

      dispatch(slice.actions.onEditThrottlingGroupSuccess());
      return await Promise.resolve({
        defaultSuccessMessage: 'Throttling group updated'
      });
    } catch (error: any) {
      dispatch(slice.actions.onEditThrottlingGroupError());
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not update throttling group'
      });
    }
  };
};

type DeleteThrottlingGroupProps = {
  id: string;
};
export const deleteThrottlingGroup = ({ id }: DeleteThrottlingGroupProps) => {
  return async () => {
    try {
      dispatch(slice.actions.startLoading('deleteThrottlingGroup'));

      await axios.delete(`/throttlingGroups/${id}`);

      dispatch(slice.actions.onDeleteThrottlingGroupSuccess());
      return await Promise.resolve({
        defaultSuccessMessage: 'Throttling group deleted'
      });
    } catch (error: any) {
      dispatch(slice.actions.onDeleteAddThrottlingGroupError());
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not delete throttling group'
      });
    }
  };
};
