import { Fab, Popover } from '@mui/material';
import { Box } from '@mui/material';
import { FloatingActionButtonType } from '../../../@types/floatingActionButtons';
import { useState } from 'react';
import { RootState, useSelector } from 'redux/store';
import CampaignBuilderMergeFields from './CampaignBuilderMergeFields';
import Documentation from './Documentation';
import Icon from 'components/icons/Icon';

const FloatingActionButtons = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedType, setSelectedType] = useState<FloatingActionButtonType | null>(null);
  const open = Boolean(anchorEl);
  const { display } = useSelector((state: RootState) => state.floatingActionButtons);

  const handleOpen = (type: FloatingActionButtonType) => (event: any) => {
    setSelectedType(type);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedType(null);
  };

  return (
    <Box
      style={{
        right: '1rem',
        bottom: '1rem',
        zIndex: 100,
        position: 'fixed',
        display: 'flex',
        gap: '1rem'
      }}
    >
      {display[FloatingActionButtonType.CAMPAIGN_BUILDER_MERGE_FIELDS] && (
        <CustomFab
          isSelected={selectedType === FloatingActionButtonType.CAMPAIGN_BUILDER_MERGE_FIELDS}
          onClick={handleOpen(FloatingActionButtonType.CAMPAIGN_BUILDER_MERGE_FIELDS)}
        >
          <Icon type="curlybrackets" />
        </CustomFab>
      )}
      {display[FloatingActionButtonType.DOCUMENTATION] && (
        <CustomFab
          isSelected={selectedType === FloatingActionButtonType.DOCUMENTATION}
          onClick={handleOpen(FloatingActionButtonType.DOCUMENTATION)}
        >
          <Icon type="questionwithoutcircle" />
        </CustomFab>
      )}
      <Popover
        style={{ marginBottom: '1rem' }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: -10,
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        disableScrollLock
      >
        {(selectedType === FloatingActionButtonType.CAMPAIGN_BUILDER_MERGE_FIELDS && (
          <CampaignBuilderMergeFields handleClose={handleClose} />
        )) ||
          (selectedType === FloatingActionButtonType.DOCUMENTATION && (
            <Documentation handleClose={handleClose} />
          ))}
      </Popover>
    </Box>
  );
};

export default FloatingActionButtons;

type CustomFabProps = {
  children: React.ReactNode;
  onClick: (event: any) => void;
  isSelected: boolean;
};
const CustomFab = ({ children, onClick, isSelected }: CustomFabProps) => {
  return (
    <Fab
      style={{ opacity: isSelected ? 0.8 : 1 }}
      color="primary"
      aria-label="add"
      onClick={onClick}
      size="small"
    >
      {children}
    </Fab>
  );
};
