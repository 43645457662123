import { IconButton } from '@mui/material';
import Icon from 'components/icons/Icon';
import { FunctionComponent } from 'react';

interface EditListItemProps {
  onClick: () => void;
}

export const EditListItem: FunctionComponent<EditListItemProps> = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <IconButton>
        <Icon type="edit" />
      </IconButton>
    </div>
  );
};
