import { ResponseData } from './response';

export type DashboardState = {
  lastUpdated: number | undefined;
  isDashboardLoading: boolean;
  isAnswerListLoading: boolean;
  spread: {
    detractors: number;
    nps: number;
    passives: number;
    promoters: number;
    total: number;
  };
  respondentCount: number;
  responseData: ResponseData;
  sortValue: string;
  filterValue: string;
  searchRespondentValue: string;
  timespan: TimeSpan;
  fromDateFilter: number | undefined;
  toDateFilter: number | undefined;
  campaignDropdownKeys: (number | string)[];
};

export enum TimeSpan {
  CUSTOM = 'Custom',
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  CURRENT_WEEK = 'Current Week',
  LAST_WEEK = 'Last Week',
  LAST_30_DAYS = 'Last 30 Days',
  CURRENT_MONTH = 'Current Month',
  LAST_MONTH = 'Last Month',
  CURRENT_QUARTER = 'Current Quarter',
  LAST_QUARTER = 'Last Quarter',
  CURRENT_YEAR = 'Current Year',
  LAST_YEAR = 'Last Year',
  ALL_TIME = 'All Time'
}
