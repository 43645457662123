import { createSlice } from '@reduxjs/toolkit';
import { CLAIMS } from '../../@types/claims';
import { AdminState } from '../../@types/admin';

// ----------------------------------------------------------------------

const initialState: AdminState = {
  claimsToIgnore: [CLAIMS.management.superadmin], //hide superadmin functionality pr default
  extraClaims: [] //used to add any claims that are not in the jwt token, such as the BETA claim, for showing features in development
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setClaimsToIgnore(state, action) {
      state.claimsToIgnore = action.payload;
    },
    setExtraClaims(state, action) {
      state.extraClaims = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setClaimsToIgnore, setExtraClaims } = slice.actions;
