import * as Yup from 'yup';
import { translator } from 'translation/useTranslator';

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const isPhoneNumberValid = (
  phoneNumber: string | undefined | null,
  initialValue?: string
): boolean => {
  //no number is valid
  if (!phoneNumber) {
    return true;
  }

  //if there is an initial value, and it has not been changed, accept the phone number
  if (initialValue && initialValue === phoneNumber) {
    return true;
  }

  try {
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber);
    return phoneUtil.isValidNumber(number);
  } catch (e) {
    return false;
  }
};

export default isPhoneNumberValid;

type YupTestFunction = (this: Yup.TestContext, value: string | undefined | null) => boolean;

interface YupContext {
  [key: string]: any;
}

interface YupTestConfig {
  name: string;
  message: string;
  test: YupTestFunction;
}

export const getPhoneNumberYupTest = (): YupTestConfig => {
  const testFunction: YupTestFunction = function (this, phoneNumber) {
    const context = this.options.context as YupContext;
    const initialValue = context?.[`initial${this.path}`] ?? '';
    return isPhoneNumberValid(phoneNumber, initialValue);
  };

  return {
    name: 'isPhoneNumberValid',
    message: translator?.invalidPhonuNumber() ?? '',
    test: testFunction
  };
};
