import GlobalStyles from './theme/globalStyles';
import RtlLayout from './components/util/RtlLayout';
import ScrollToTop from './components/util/ScrollToTop';
import GoogleAnalytics from './components/util/GoogleAnalytics';
import ThemePrimaryColor from './components/util/ThemePrimaryColor';
import ThemeLocalization from './components/util/ThemeLocalization';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { ProgressBarStyle } from './components/util/LoadingScreen';
import { reactPlugin, setupAppInsights } from 'appInsights';
import { AppInsightsContext, withAITracking } from '@microsoft/applicationinsights-react-js';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import enGbLocale from 'date-fns/locale/en-GB';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import BuildVersionGuard from 'guards/BuildVersionGuard';
import CustomSnowfall from 'components/CustomSnowfall';
import ThemeConfig from 'theme';
import UnsavedChangesPrompt from 'components/form/UnsavedChangesPrompt';
import { MaintenancePage } from 'pages/maintenance/MaintenancePage';
import releaseConfig from 'releaseConfig';
import { useRemoteConfig } from 'utils/firebase/useRemoteConfig';
import {
  RemoteConfigKeyConfiguration,
  UnderMaintenanceConfigDev
} from 'utils/firebase/remoteConfigKey';
import AdminApp from 'AdminApp';
import { Route, Routes } from 'react-router-dom';
import NpsCalculatorApp from 'NpsCalculator/NpsCalculatorApp';

setupAppInsights();

const App = () => {
  const { getRemoteConfigValue } = useRemoteConfig();

  const keyConfig: RemoteConfigKeyConfiguration = releaseConfig.underMaintenanceKey.startsWith('#{')
    ? UnderMaintenanceConfigDev
    : { key: releaseConfig.underMaintenanceKey, type: 'boolean' };
  const underMaintenance = getRemoteConfigValue(keyConfig);

  return underMaintenance ? (
    <MaintenancePage />
  ) : (
    <div style={{ position: 'relative' }}>
      <CustomSnowfall />
      <AppInsightsContext.Provider value={reactPlugin}>
        <ThemeConfig>
          <ThemePrimaryColor>
            <ThemeLocalization>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                localeText={{ start: 'From', end: 'To' }}
                adapterLocale={enGbLocale}
              >
                <RtlLayout>
                  <BuildVersionGuard>
                    <GlobalStyles />
                    <ProgressBarStyle />
                    <BaseOptionChartStyle />
                    <ScrollToTop />
                    <GoogleAnalytics />

                    <Routes>
                      <Route path="/nps-calculator" element={<NpsCalculatorApp />} />
                      <Route path="*" element={<AdminApp />} />
                    </Routes>
                  </BuildVersionGuard>
                </RtlLayout>
              </LocalizationProvider>
            </ThemeLocalization>
          </ThemePrimaryColor>
        </ThemeConfig>
      </AppInsightsContext.Provider>
      <UnsavedChangesPrompt />
    </div>
  );
};

export default withAITracking(reactPlugin, App);
