import { useState } from 'react';
import { Box, Card, Grid, Stack, TextField, Typography } from '@mui/material';
import CustomTextField from 'components/util/CustomTextField';
import { Form, FormikProvider, useFormik } from 'formik';
import AddLogoDialog from 'pages/logos/components/AddLogoDialog';
import { useRef } from 'react';
import ReactTimeAgo from 'react-time-ago';
import * as Yup from 'yup';
import { OrganizationProfile } from '../../../@types/organization';
import { updateOrganizationProfile, updateOrgLogo } from '../../../redux/slices/organization';
import { RootState, useSelector } from '../../../redux/store';
import MyAvatar from 'components/util/MyAvatar';
import useCustomDispatch from 'redux/dispatch';
import { CustomLoadingButton } from 'components/util/CustomButton';
import { getPhoneNumberYupTest } from 'utils/isPhoneNumberValid';
import PhoneNumberInputField from 'components/util/PhoneNumberInputField';
import { useRegisterUnsavedFormChanges } from 'components/form/useRegisterUnsavedFormChanges';
import getFormikValidateFunction from 'utils/getFormikValidateFunction';

export default function OrganizationGeneral() {
  const customDispatch = useCustomDispatch();
  const [displayAddLogoDialog, setDisplayAddLogoDialog] = useState(false);
  const { organizationProfile, isLogoLoading } = useSelector(
    (state: RootState) => state.organization
  );

  const UpdateOrgSchema = Yup.object().shape({
    displayName: Yup.string().min(2).required('Name is required'),
    contactPhone: Yup.string().test(getPhoneNumberYupTest()),
    organizationPhone: Yup.string().nullable().test(getPhoneNumberYupTest()),
    website: Yup.string().matches(
      /^(((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?)?\)?$/,
      'Invalid URL'
    )
  });

  const formik = useFormik<OrganizationProfile>({
    enableReinitialize: true,
    initialValues: {
      id: organizationProfile.id || '',
      displayName: organizationProfile.displayName || '',
      organizationName: organizationProfile.organizationName || '',
      organizationPhone: organizationProfile.organizationPhone || '',
      contactName: organizationProfile.contactName || '',
      contactPhone: organizationProfile.contactPhone || '',
      contactEmail: organizationProfile.contactEmail || '',
      invoiceEmail: organizationProfile.invoiceEmail || '',
      cvr: organizationProfile.cvr || '',
      agreementId: organizationProfile.agreementId,
      active: organizationProfile.active,
      subscriptionType: organizationProfile.subscriptionType,
      adminSeats: organizationProfile.adminSeats,
      userSeats: organizationProfile.userSeats,
      outlookSeats: organizationProfile.outlookSeats,
      emailLimit: organizationProfile.emailLimit,
      smsEnabled: organizationProfile.smsEnabled,
      smsLimit: organizationProfile.smsLimit,
      logoLink: organizationProfile.logoLink || '',
      sid: organizationProfile.sid || '',
      reviewDescription: organizationProfile.reviewDescription || '',
      enableHumanVerification: organizationProfile.enableHumanVerification || false,
      created: organizationProfile.created || null,
      website: organizationProfile.website || ''
    },
    validate: getFormikValidateFunction(UpdateOrgSchema, {
      initialorganizationPhone: organizationProfile.organizationPhone,
      initialcontactPhone: organizationProfile.contactPhone
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      customDispatch({
        action: updateOrganizationProfile,
        actionParameters: values,
        onFinally: () => {
          setSubmitting(false);
        }
      });
    }
  });

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, dirty } = formik;

  useRegisterUnsavedFormChanges(dirty);

  const createdString = getFieldProps('created')?.value;
  const createdDate = new Date(createdString);

  const ref = useRef(null);

  return (
    <>
      <Card sx={{ py: 3, mt: 3, width: '100%' }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3} sx={{ p: 3 }}>
              <Grid item xs={12}>
                <Stack spacing={{ xs: 2, md: 3 }}>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <CustomTextField
                      fullWidth
                      label="Display Name"
                      {...getFieldProps('displayName')}
                      error={Boolean(touched.displayName && errors.displayName)}
                      helperText={touched.displayName && errors.displayName}
                      description="For internal use - displayed as the account name on the platform."
                    />
                    <CustomTextField
                      fullWidth
                      label="Organization Name"
                      {...getFieldProps('organizationName')}
                      error={Boolean(touched.organizationName && errors.organizationName)}
                      helperText={touched.organizationName && errors.organizationName}
                      description={`For external use - displayed at the bottom of surveys: "Sent by ${
                        values.organizationName.length ? values.organizationName : 'X'
                      }".`}
                    />
                  </Stack>

                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <PhoneNumberInputField
                      fieldName="organizationPhone"
                      label="Organization Phone"
                      formik={formik}
                      initialValue={organizationProfile.organizationPhone || ''}
                    />
                    <TextField
                      fullWidth
                      label="Contact Name"
                      {...getFieldProps('contactName')}
                      error={Boolean(touched.contactName && errors.contactName)}
                      helperText={touched.contactName && errors.contactName}
                    />
                  </Stack>

                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <PhoneNumberInputField
                      fieldName="contactPhone"
                      label="Contact Phone"
                      formik={formik}
                      initialValue={organizationProfile.contactPhone || ''}
                    />
                    <TextField
                      fullWidth
                      label="Contact Email"
                      {...getFieldProps('contactEmail')}
                      error={Boolean(touched.contactEmail && errors.contactEmail)}
                      helperText={touched.contactEmail && errors.contactEmail}
                    />
                  </Stack>

                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <TextField
                      fullWidth
                      label="Invoice Email"
                      {...getFieldProps('invoiceEmail')}
                      error={Boolean(touched.invoiceEmail && errors.invoiceEmail)}
                      helperText={touched.invoiceEmail && errors.invoiceEmail}
                    />
                    <TextField
                      fullWidth
                      label="VAT"
                      {...getFieldProps('cvr')}
                      error={Boolean(touched.cvr && errors.cvr)}
                      helperText={touched.cvr && errors.cvr}
                    />
                  </Stack>

                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <CustomTextField
                      enableCopyToClipboard
                      fullWidth
                      label="Website"
                      {...getFieldProps('website')}
                      error={Boolean(touched.website && errors.website)}
                      helperText={touched.website && errors.website}
                    />
                    <CustomTextField
                      enableCopyToClipboard
                      disabled
                      fullWidth
                      label="Account ID"
                      {...getFieldProps('id')}
                      error={Boolean(touched.id && errors.id)}
                      helperText={touched.id && errors.id}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <CustomTextField
                      enableShowHide
                      enableCopyToClipboard
                      inputRef={ref}
                      disabled
                      fullWidth
                      label="SID"
                      {...getFieldProps('sid')}
                      error={Boolean(touched.sid && errors.sid)}
                      helperText={touched.sid && errors.sid}
                    />
                    <Stack
                      direction={{ xs: 'column' }}
                      sx={{ width: '100%' }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      {createdString && (
                        <>
                          <Stack
                            direction={{ xs: 'row' }}
                            sx={{ width: '100%' }}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography>Account created&nbsp;</Typography>
                            <ReactTimeAgo date={createdDate} locale="en-US" timeStyle="round" />
                          </Stack>
                          <Typography>{createdDate?.toLocaleDateString()}</Typography>
                        </>
                      )}
                    </Stack>
                  </Stack>
                </Stack>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <CustomLoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!dirty}
                  >
                    Save Changes
                  </CustomLoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Card>
      <Card
        sx={{
          py: 3,
          mt: 3,
          width: '100%',
          display: 'flex',
          gap: '1rem',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'inherit'
        }}
      >
        <MyAvatar
          style={{
            width: '10rem',
            height: '10rem'
          }}
        />

        <CustomLoadingButton
          loading={isLogoLoading}
          variant="contained"
          onClick={() => setDisplayAddLogoDialog(true)}
          sx={{ width: 'max-content' }}
        >
          Change Account Logo
        </CustomLoadingButton>
      </Card>
      <AddLogoDialog
        display={displayAddLogoDialog}
        hideModal={() => setDisplayAddLogoDialog(false)}
        uploadLogo={updateOrgLogo}
      />
    </>
  );
}
