// hooks
import useSettings from 'hooks/useSettings';
import { RootState, useSelector } from '../../redux/store';
//
import { MAvatarProps } from '../@material-extend/MAvatar';
import Avatar from '../Avatar';

// ----------------------------------------------------------------------

type MyAvatarProps = {
  src?: string;
  style?: {};
};
export default function MyAvatar({ src, style = {}, ...other }: MAvatarProps & MyAvatarProps) {
  const { organizationProfile } = useSelector((state: RootState) => state.organization);
  const { enableChristmas } = useSettings();

  return (
    <div style={{ position: 'relative' }}>
      <Avatar
        imgProps={{
          sx: {
            objectFit: 'contain',
            borderRadius: '50%',
            width: 'max-content'
          }
        }}
        src={src ?? organizationProfile?.logoLink ?? '/favicon/maskable_icon_x192.png'}
        alt={organizationProfile?.displayName}
        color={'default'}
        style={style}
        {...other}
      />
      {enableChristmas && (
        <img
          alt=""
          src="/static/christmas/christmasElfHat.png"
          style={{
            height: '50%',
            position: 'absolute',
            top: '-35%',
            left: '-20%'
          }}
        />
      )}
    </div>
  );
}
