import { useCallback, useEffect, useRef, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from '../../../utils/axios';
import { EventType, InteractionStatus } from '@azure/msal-browser';
import { useNavigate } from 'react-router-dom';
import useDisplayMessage from 'components/messages/useMessage';
import useAuth from 'hooks/useAuth';
import { Organisation } from '../../../@types/organization';
import { loginRequest } from 'authConfig';
import { setSession } from 'utils/jwt';
import { PATH_AUTH } from 'routes/paths';
import useCurrentLoginMethod from './useCurrentLoginMethod';

type UseMicrosoftLoginProps = {
  userOrganisations: Organisation[];
  setUserOrganisations: (organisations: Organisation[]) => void;
};

const useMicrosoftLogin = ({
  userOrganisations,
  setUserOrganisations
}: UseMicrosoftLoginProps): {
  handleLoginWithMicrosoft: () => Promise<void>;
  isMicrosoftSsoLoading: boolean;
} => {
  const loginMethod = useCurrentLoginMethod();
  const { instance, inProgress } = useMsal();
  const navigate = useNavigate();
  const displayMessage = useDisplayMessage();
  const { login } = useAuth();
  const tokenAcquiredRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleLoginWithMicrosoft = async () => {
    await instance.handleRedirectPromise();
    instance.setActiveAccount(null); // remove active account from MS
    await instance.loginRedirect(loginRequest);
  };

  const acquireTokenWithMicrosoft = useCallback(
    async (accessToken: string) => {
      if (tokenAcquiredRef.current) return;
      tokenAcquiredRef.current = true;
      await instance.handleRedirectPromise();

      const account = instance.getActiveAccount();

      axios
        .get('/user/token/microsoft', {
          headers: {
            Authorization: `bearer ${accessToken}`
          }
        })
        .then(async (response) => {
          setSession(response.data.access_token);
          if (response.data.status === 'multipleAccounts') {
            setUserOrganisations(response.data.organizations);
          } else {
            setUserOrganisations([]);
            await login();
          }
        })
        .catch(() => {
          displayMessage.error({
            message: {
              title: 'User does not have an account',
              description:
                `Please register ${account?.username}, or sign in with a registered mail` ?? ''
            }
          });
          navigate(PATH_AUTH.login);
        });
    },
    [instance, displayMessage, login, navigate, setUserOrganisations]
  );

  useEffect(() => {
    instance.addEventCallback((event: any) => {
      if (
        (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.LOGIN_SUCCESS) &&
        instance.getActiveAccount() !== null &&
        event.payload.accessToken !== null
      ) {
        const accessToken = event.payload.accessToken;
        acquireTokenWithMicrosoft(accessToken);
      }
    });
  }, [acquireTokenWithMicrosoft, instance]);

  useEffect(() => {
    setIsLoading(
      inProgress !== InteractionStatus.None ||
        (loginMethod === 'microsoft' && !userOrganisations.length)
    );
  }, [inProgress, loginMethod, userOrganisations.length]);

  return {
    handleLoginWithMicrosoft,
    isMicrosoftSsoLoading: isLoading
  };
};

export default useMicrosoftLogin;
