import { useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { RootState, useSelector } from 'redux/store';
import { Card, Grid, Stack, Typography, Box } from '@mui/material';
import useCustomDispatch from 'redux/dispatch';
import { CustomLoadingButton } from 'components/util/CustomButton';
import { useRegisterUnsavedFormChanges } from 'components/form/useRegisterUnsavedFormChanges';
import { setCallDurationLimit } from 'redux/slices/organization';
import { getCallDurationLimit } from '../../../redux/slices/organization';
import { OrganizationState } from '../../../@types/organization';
import CustomSwitch from 'components/util/CustomSwitch';
import CustomTextField from 'components/util/CustomTextField';
import LinearProgress from '@mui/material/LinearProgress';

const IVRSettings = () => {
  const customDispatch = useCustomDispatch();
  const { callDurationLimit, loading } = useSelector((state: RootState) => state.organization);

  const [isInitialFetched, setIsInitialFetched] = useState(false);
  useEffect(() => {
    customDispatch({
      action: getCallDurationLimit,
      disableSuccessMessage: true,
      onSuccess: () => {
        setIsInitialFetched(true);
      }
    });
  }, [customDispatch]);

  const formik = useFormik<
    Pick<OrganizationState, 'callDurationLimit'> & { initialCallDurationLimit: number }
  >({
    enableReinitialize: true,
    initialValues: {
      callDurationLimit: callDurationLimit ?? 10,
      initialCallDurationLimit: callDurationLimit ?? 10
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      customDispatch({
        action: setCallDurationLimit,
        actionParameters: {
          seconds: values.callDurationLimit || 0
        },
        onSuccess: () => {
          customDispatch({
            action: getCallDurationLimit,
            disableSuccessMessage: true,
            onSuccess: () => {
              formik.resetForm();
            }
          });
        },
        onFinally: () => {
          setSubmitting(false);
        }
      });
    }
  });

  const { values, isSubmitting, handleSubmit, dirty, errors, getFieldProps, setFieldValue } =
    formik;

  useRegisterUnsavedFormChanges(dirty);

  const isMinCallDurationEnabled = values.initialCallDurationLimit > 0;
  const formFields = [
    <Stack spacing={1} key={`callDurationLimit`}>
      <CustomSwitch
        label="Minimum Call Duration"
        labelPlacement="end"
        switchProps={{
          checked: isMinCallDurationEnabled,
          onChange: (e) => {
            if (e.target.checked) {
              setFieldValue('callDurationLimit', 10);
              setFieldValue('initialCallDurationLimit', 10);
            } else {
              setFieldValue('callDurationLimit', 0);
              setFieldValue('initialCallDurationLimit', 0);
            }
          }
        }}
      />
      <Typography variant={'caption'}>
        This setting defines the minimum duration a call must last before triggering an IVR survey.
        Shorter calls will not initiate a survey. This can be beneficial as it helps prevent sending
        surveys following brief or inconclusive calls, ensuring more accurate and valuable feedback.
        It's important to note that some IVR providers offer a feature where you can set a call
        duration limit specifically for an individual IVR connection. In cases where this specific
        value is set, it overrides the general minimum call duration setting.
      </Typography>
      {values.initialCallDurationLimit > 0 && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap="1rem"
        >
          <CustomTextField
            label="Seconds"
            type="number"
            {...getFieldProps('callDurationLimit')}
            style={{ width: '5rem' }}
            error={!!errors.callDurationLimit}
          />
          {!values.callDurationLimit && (
            <Typography variant={'caption'}>
              Setting Minimum Call Duration below 1 second effectively turns it off.
            </Typography>
          )}
        </Box>
      )}
    </Stack>
  ];

  return (
    <>
      {loading.getCallDurationLimit && <LinearProgress />}
      {isInitialFetched && (
        <Card sx={{ p: 3 }} style={{ maxWidth: '30rem' }}>
          <FormikProvider value={formik}>
            <Form id="ivrSettings" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3} alignItems="flex-end">
                <Grid container spacing={3}>
                  {formFields.map((item, index) => (
                    <Grid key={index} item xs={12}>
                      {item}
                    </Grid>
                  ))}
                </Grid>
                <CustomLoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting || loading.setCallDurationLimit}
                  disabled={
                    !dirty ||
                    isSubmitting ||
                    loading.setCallDurationLimit ||
                    loading.getCallDurationLimit
                  }
                >
                  Save
                </CustomLoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </Card>
      )}
    </>
  );
};

export default IVRSettings;
