import useAuth from 'hooks/useAuth';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { getFunctionsUrl } from 'utils/urls/getUrls';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

type SubscribeToCampaignMemberProcessingParams = {
  campaignId: number;
  onImportStarted: (count: number) => void;
  onCampaignMemberAdded: () => void;
  onCampaignMemberNotSent: (contactInfo: string, additionStatus: string) => void;
  onCampaignMemberAddFailed: (email: string, message: string) => void;
};

type SignalRContextProps = {
  subscribeToCampaignMemberProcessing: (params: SubscribeToCampaignMemberProcessingParams) => void;
};

const initialState: SignalRContextProps = {
  subscribeToCampaignMemberProcessing: () => {}
};

const SignalRContext = createContext(initialState);

type SignalRProviderProps = {
  children: ReactNode;
};

function SignalRProvider({ children }: SignalRProviderProps) {
  const { selectedOrg, user } = useAuth();
  const [connection, setConnection] = useState<HubConnection>();
  const appInsights = useAppInsightsContext();

  useEffect(() => {
    if (!selectedOrg?.organizationId || !user) {
      return;
    }
    function createHubConnection() {
      const functionsUrl = getFunctionsUrl();
      const hubConnection = new HubConnectionBuilder()
        .withUrl(`${functionsUrl}/api/campaign-members?userid=${user?.userId}`)
        .build();

      setConnection(hubConnection);
      hubConnection
        .start()
        .then()
        .catch((error) => {
          appInsights.trackException({ exception: error, properties: [{ userId: user?.userId }] });
        });
      return () => {
        hubConnection.stop();
      };
    }
    const stopper = createHubConnection();
    return stopper;
  }, [selectedOrg?.organizationId, user, appInsights]);

  const orgId = selectedOrg?.organizationId;

  const subscribeToCampaignMemberProcessing = ({
    campaignId,
    onImportStarted,
    onCampaignMemberAdded,
    onCampaignMemberNotSent,
    onCampaignMemberAddFailed
  }: SubscribeToCampaignMemberProcessingParams) => {
    connection?.on(
      `CampaignMemberImportStarted${orgId}${campaignId}`,
      (count, receivedCampaignId, receivedOrgId) => {
        if (receivedCampaignId === campaignId && receivedOrgId === orgId) {
          onImportStarted(count);
        }
      }
    );

    connection?.on(
      `CampaignMemberAddSucceeded${orgId}${campaignId}`,
      (receivedCampaignId, receivedOrgId) => {
        if (receivedCampaignId === campaignId && receivedOrgId === orgId) {
          onCampaignMemberAdded();
        }
      }
    );

    connection?.on(
      `CampaignMemberAddedButNotSent${orgId}${campaignId}`,
      (receivedCampaignId, campaignMemberIdentifier, additionStatus, receivedOrgId) => {
        if (receivedCampaignId === campaignId && receivedOrgId === orgId) {
          onCampaignMemberNotSent(campaignMemberIdentifier, additionStatus);
        }
      }
    );

    connection?.on(
      `CampaignMemberAddFailed${orgId}${campaignId}`,
      (receivedCampaignId, campaignMemberIdentifier, message, receivedOrgId) => {
        if (receivedCampaignId === campaignId && receivedOrgId === orgId) {
          onCampaignMemberAddFailed(campaignMemberIdentifier, message);
        }
      }
    );
  };

  return (
    <SignalRContext.Provider
      value={{
        subscribeToCampaignMemberProcessing
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
}

export { SignalRProvider, SignalRContext };
