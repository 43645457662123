import {
  Box,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip
} from '@mui/material';
import Icon from 'components/icons/Icon';
import FurtherInfo from 'components/util/FurtherInfo';
import { useEffect, useRef, useState } from 'react';

type TimeInputProps = {
  label: string;
  value: string | number;
  setValue: (newValue: string | number) => void;
  range: number; //from 0 to this number
  isFixedChoices?: boolean;
  enableLeadingZero?: boolean;
  disableZero?: boolean;
  enableAddMoreOptions?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  width?: string;
  furtherInfo?: string | JSX.Element;
};
export const TimeInput = ({
  label,
  value,
  setValue,
  range,
  isFixedChoices,
  enableLeadingZero,
  disableZero,
  enableAddMoreOptions,
  disabled,
  hasError,
  width = '5rem',
  furtherInfo
}: TimeInputProps) => {
  const [amountOfItems, setAmountOfItems] = useState(
    isFixedChoices || Number(value) <= range ? range : Math.ceil(Number(value) / range) * range
  );

  useEffect(() => {
    if (isFixedChoices) {
      setAmountOfItems(range);
    }
  }, [range, isFixedChoices, setAmountOfItems]);

  const [minWidthPx, setMinWidthPx] = useState(0);
  const labelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (labelRef.current) {
      const minWidth = labelRef.current.offsetWidth + 24; //24 is for the label placement
      setMinWidthPx(minWidth);
    }
  }, [label, furtherInfo]);

  return (
    <>
      {value !== undefined && (
        <Box>
          <FormControl>
            <TextField
              disabled={disabled}
              value={value ?? ''}
              onChange={(event: any) => {
                setValue(event.target.value);
              }}
              label={
                <div ref={labelRef}>
                  {label} {furtherInfo && <FurtherInfo description={furtherInfo} isText />}
                </div>
              }
              sx={{
                width: width,
                minWidth: `${minWidthPx}px`
              }}
              select
              error={hasError}
            >
              {Array.from(Array(amountOfItems + 1).keys()).map((key) => {
                const time = key;
                const timeString =
                  typeof value === 'string'
                    ? time < 10 && enableLeadingZero
                      ? `0${time}`
                      : time.toString()
                    : time;
                if (disableZero && key < 1) {
                  return null;
                }
                return (
                  <MenuItem key={key} value={timeString}>
                    {time}
                  </MenuItem>
                );
              })}
              {/* If the selected value is above the defauklt max value, render the menuitem for the selected value */}
              {amountOfItems + 1 < Number(value) && (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              )}
              {enableAddMoreOptions && (
                <div key="addMore">
                  <Divider />
                  <Box display="flex" justifyContent="space-around" sx={{ width: '100%' }}>
                    <Tooltip title="More options">
                      <IconButton
                        color="primary"
                        size="medium"
                        onClick={() => setAmountOfItems((prevState) => prevState + range)}
                      >
                        <Icon type="add" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </div>
              )}
            </TextField>
          </FormControl>
        </Box>
      )}
    </>
  );
};

type TimeSpanPickerProps = {
  defaultValue?: string; //format xx:xx:xx, or xx:xx:xx:xx (not yet handled by backend)
  defaultTicks?: number;
  handleChange: (newValue: string | number) => void;
  disabled?: boolean;
};

const secondsInAMinute = 60;
const secondsInAnHour = secondsInAMinute * 60;
const secondsInADay = secondsInAnHour * 24;
const TimeSpanPicker = ({
  defaultValue,
  defaultTicks,
  handleChange,
  disabled
}: TimeSpanPickerProps) => {
  const usingTicks = useRef(defaultTicks !== undefined).current;
  const handleChangeRef = useRef(handleChange);
  const [days, setDays] = useState<number | string>('0');
  const [hours, setHours] = useState<number | string>('00');
  const [minutes, setMinutes] = useState<number | string>('00');
  const [seconds, setSeconds] = useState<number | string>('00');

  useEffect(() => {
    if (usingTicks && defaultTicks !== undefined) {
      setDays(Math.floor(defaultTicks / secondsInADay));
      setHours(Math.floor((defaultTicks % secondsInADay) / secondsInAnHour));
      setMinutes(Math.floor(((defaultTicks % secondsInADay) % secondsInAnHour) / secondsInAMinute));
      setSeconds(((defaultTicks % secondsInADay) % secondsInAnHour) % secondsInAMinute);
    } else {
      //get array of time span values, and reverse array, so at starts from seconds to days
      const timeSpanArray = defaultValue?.split(/\.|:/)?.reverse();
      setSeconds(timeSpanArray?.[0] ?? '00');
      setMinutes(timeSpanArray?.[1] ?? '00');
      setHours(timeSpanArray?.[2] ?? '00');
      setDays(timeSpanArray?.[3] ?? '0');
    }
  }, [defaultValue, defaultTicks, usingTicks]);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (usingTicks) {
      handleChangeRef.current(
        Number(days) * secondsInADay +
          Number(hours) * secondsInAnHour +
          Number(minutes) * secondsInAMinute +
          Number(seconds)
      );
      return;
    }
    handleChangeRef.current(`${days}.${hours}:${minutes}:${seconds}`);
  }, [days, hours, minutes, seconds, handleChangeRef, usingTicks]);

  return (
    <Stack direction="row" justifyContent="center" gap="1rem">
      <TimeInput
        label="Days"
        value={days}
        setValue={setDays}
        range={7}
        enableAddMoreOptions
        disabled={disabled}
      />
      <TimeInput
        label="Hours"
        value={hours}
        setValue={setHours}
        range={23}
        enableLeadingZero
        disabled={disabled}
      />
      <TimeInput
        label="Minutes"
        value={minutes}
        setValue={setMinutes}
        range={59}
        enableLeadingZero
        disabled={disabled}
      />
      <TimeInput
        label="Seconds"
        value={seconds}
        setValue={setSeconds}
        range={59}
        enableLeadingZero
        disabled={disabled}
      />
    </Stack>
  );
};

export default TimeSpanPicker;
