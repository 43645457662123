import { styled } from '@mui/material/styles';
import { Dialog, DialogContent } from '@mui/material';

type UnsavedChangesModalProps = {
  imgUrl: string;
  resetImageUrl: () => void;
};

const ViewImageModal = ({ imgUrl, resetImageUrl }: UnsavedChangesModalProps) => {
  return (
    <Dialog open={imgUrl.length > 0} onClose={resetImageUrl}>
      <DialogContent>
        <StyledImg
          src={imgUrl}
          alt="view"
          style={{
            backgroundColor: '#fff',
            backgroundImage:
              'linear-gradient(45deg,#efefef 25%,rgba(239,239,239,0) 25%,rgba(239,239,239,0) 75%,#efefef 75%,#efefef),linear-gradient(45deg,#efefef 25%,rgba(239,239,239,0) 25%,rgba(239,239,239,0) 75%,#efefef 75%,#efefef)',
            backgroundPosition: '0 0,10px 10px',
            backgroundSize: '21px 21px'
          }}
          onClick={resetImageUrl}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ViewImageModal;

const StyledImg = styled('img')(({ theme }) => ({
  '&:hover': {
    cursor: 'zoom-out'
  }
}));
