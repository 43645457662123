import { useState, useEffect } from 'react';
import { RootState, useSelector } from 'redux/store';

type IntegrationImageProps = {
  integrationName: string;
};
const IntegrationImage = ({
  integrationName,
  ...other
}: IntegrationImageProps &
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
  const { integrations } = useSelector((state: RootState) => state.integrations);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const integration = integrations.find(
      (integration) => integration.name.toLocaleLowerCase() === integrationName.toLocaleLowerCase()
    );
    if (integration) {
      setImageUrl(integration.logo || '');
    }
  }, [integrationName, integrations]);

  return (
    <img
      {...other}
      style={{ objectFit: 'scale-down', maxHeight: '100%', ...other.style }}
      src={imageUrl}
      alt=""
    />
  );
};

export default IntegrationImage;
