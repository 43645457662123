import { styled } from '@mui/material';

const RjsfWrapper = styled('div')(({ theme }) => ({
  '#root': {
    border: 0
  },

  '.array-item:nth-child(1):nth-last-child(1) .array-item-remove': {
    display: 'none'
  },

  '#root__title': {
    color: theme.palette.primary.main,
    fontSize: '1.125rem',
    fontWeight: 'bold'
  },

  'fieldset.field.field-array': {
    padding: '1rem',
    borderRadius: '8px',
    borderColor: 'rgba(145, 158, 171, 0.32)'
  },

  '.error-detail': {
    display: 'none'
  },

  '.control-label': {
    display: 'none'
  },

  '.form-group': {
    marginBlock: '1rem'
  },

  '.array-item': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '1rem',
    '& > *': {
      width: '100%'
    }
  },

  '.array-item-toolbox': {
    height: '100%',
    width: 'max-content'
  },

  '.array-item-move-up': {
    background: 'url(/static/icons/rjsf/ic_move-up.svg) no-repeat center center / contain'
  },
  '.array-item-move-down': {
    background: 'url(/static/icons/rjsf/ic_move-down.svg) no-repeat center center / contain'
  },
  '.array-item-remove': {
    background: 'url(/static/icons/rjsf/ic_remove.svg) no-repeat center center / contain'
  },
  '.btn-add': {
    background: 'url(/static/icons/rjsf/ic_add.svg) no-repeat center center / contain'
  },
  '.array-item-move-up, .array-item-move-down, .array-item-remove, .btn-add': {
    height: '1.5rem',
    width: '1.5rem',
    border: 'none',
    marginInline: '.5rem',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '.array-item-add': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default RjsfWrapper;
