import { IconButton, Tooltip } from '@mui/material';
import Icon from 'components/icons/Icon';
import useDisplayMessage from 'components/messages/useMessage';
import { CSSProperties, FunctionComponent } from 'react';
import { useTranslator } from 'translation/useTranslator';

interface CopyButtonProps {
  textToCopy: string;
  successText?: string;
  tooltipText?: string;
  style?: CSSProperties;
}

export const CopyButton: FunctionComponent<CopyButtonProps> = ({
  textToCopy,
  successText,
  tooltipText,
  style
}) => {
  const displayMessage = useDisplayMessage();
  const translator = useTranslator();
  return (
    <Tooltip title={tooltipText ?? translator.copyToClipboard()}>
      <IconButton
        style={style}
        aria-label="copy to clipboard"
        onClick={() => {
          navigator.clipboard.writeText(textToCopy);
          displayMessage.success({
            message: { title: successText ?? translator.copiedTextToClipboard() }
          });
        }}
        edge="end"
      >
        <Icon type="copy" />
      </IconButton>
    </Tooltip>
  );
};
