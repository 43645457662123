// ----------------------------------------------------------------------

import { CampaignsTab } from 'pages/campaigns/Campaigns';
import { OrdersTab } from 'pages/orders/Orders';

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '';
export const ROOTS_APP = '/home';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  resetPassword: path(ROOTS_AUTH, '/update-password:token:userId'),
  signUp: path(ROOTS_AUTH, '/sign-up'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_APP,
  general: {
    app: path(ROOTS_APP, '/dashboard')
  },
  companies: {
    root: path(ROOTS_APP, '/companies')
  },
  campaigns: {
    root: path(ROOTS_APP, '/campaigns'),
    tabs: path(ROOTS_APP, '/campaigns/tabs'),
    newCampaign: path(ROOTS_APP, '/campaigns/new'),
    editCampaignByID: path(ROOTS_APP, '/campaigns/:id/edit'),
    campaign: path(ROOTS_APP, '/campaigns/:id'),
    sendSurvey: path(ROOTS_APP, '/campaigns/:id/send-survey'),
    campaignMember: path(ROOTS_APP, '/campaigns/:id/campaignMembers/:campaignMembersId/:edit')
  },
  organization: {
    root: path(ROOTS_APP, '/account/'),
    users: path(ROOTS_APP, '/account/users')
  },
  templates: {
    root: path(ROOTS_APP, `/campaigns/tabs/${CampaignsTab.TEMPLATES}`),
    edit: path(ROOTS_APP, '/campaigns/templates/:id'),
    newTemplate: path(ROOTS_APP, '/campaigns/templates/new'),
    overview: path(ROOTS_APP, '/campaigns/templates/overview')
  },
  logos: {
    root: path(ROOTS_APP, `/campaigns/tabs/${CampaignsTab.LOGOS}`)
  },
  npsNotifications: {
    root: path(ROOTS_APP, `/campaigns/tabs/${CampaignsTab.NPS_NOTIFICATIONS}`),
    edit: path(ROOTS_APP, '/campaigns/npsNotifications/:id'),
    new: path(ROOTS_APP, '/campaigns/npsNotifications/new')
  },
  pGap: {
    root: path(ROOTS_APP, '/p-gap')
  },
  integrationStore: {
    root: path(ROOTS_APP, '/integration-store')
  },
  ivr: {
    root: path(ROOTS_APP, '/ivr'),
    tab: path(ROOTS_APP, '/ivr/:tab')
  },
  outlook: {
    root: path(ROOTS_APP, '/outlook'),
    invite: path(ROOTS_APP, '/outlook/invite')
  },
  eTrack1: {
    root: path(ROOTS_APP, '/etrack1'),
    invite: path(ROOTS_APP, '/outlook/invite')
  },
  productConfigurations: {
    root: path(ROOTS_APP, '/product-configurations')
  },
  zapier: {
    root: path(ROOTS_APP, '/zapier')
  },
  reports: {
    root: path(ROOTS_APP, '/reports')
  },
  reviews: {
    root: path(ROOTS_APP, '/reviews')
  },
  user: {
    root: path(ROOTS_APP, '/user'),
    profile: path(ROOTS_APP, '/user/profile'),
    cards: path(ROOTS_APP, '/user/cards'),
    list: path(ROOTS_APP, '/user/list'),
    newUser: path(ROOTS_APP, '/user/new'),
    editById: path(ROOTS_APP, `/user/reece-chung/edit`),
    account: path(ROOTS_APP, '/user/account'),
    settings: path(ROOTS_APP, '/user/settings')
  },
  employees: {
    root: path(ROOTS_APP, '/employees')
  },
  respondents: {
    root: path(ROOTS_APP, '/respondents'),
    respondent: path(ROOTS_APP, '/respondents/:id')
  },
  admin: {
    root: path(ROOTS_APP, '/admin')
  },
  orders: {
    root: path(ROOTS_APP, '/orders'),
    tabs: path(ROOTS_APP, '/orders/tabs'),
    surveyOrdersTab: path(ROOTS_APP, `/orders/tabs/${OrdersTab.SURVEY_ORDERS}`),
    productOrdersTab: path(ROOTS_APP, `/orders/tabs/${OrdersTab.PRODUCT_ORDERS}`),
    billableHoursOrdersTab: path(ROOTS_APP, `/orders/tabs/${OrdersTab.BILLABLE_HOURS_ORDERS}`),
    surveyOrders: path(ROOTS_APP, '/orders/survey-orders'),
    productOrders: path(ROOTS_APP, '/orders/product-orders'),
    billableHoursOrders: path(ROOTS_APP, '/orders/billable-hours-orders'),
    newSurveyOrder: path(ROOTS_APP, '/orders/survey-orders/new'),
    newBillableHoursOrder: path(ROOTS_APP, '/orders/billable-hours-orders/new'),
    newProductOrder: path(ROOTS_APP, '/orders/product-orders/new')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
