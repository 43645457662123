// material
import { Card, styled, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { capitalCase } from 'change-case';
import { Form, FormikProvider, getIn, useFormik } from 'formik';
import { useState } from 'react';
import { IFileWithMeta, StatusValue } from 'react-dropzone-uploader';
import { sendInvite, sendInvitesExcel } from 'redux/slices/outlook';
import { PATH_DASHBOARD } from 'routes/paths';
import * as Yup from 'yup';
// components
import Page from '../../components/util/Page';
// hooks
import 'react-dropzone-uploader/dist/styles.css';
import { useNavigate } from 'react-router';
import useCustomDispatch from 'redux/dispatch';
import { CustomLoadingButton } from 'components/util/CustomButton';
import IntegrationImage from 'components/integration/IntegrationImage';
import CustomDropzone from 'components/CustomDropzone';
import releaseConfig from 'releaseConfig';

const OutlookInvite = () => {
  const customDispatch = useCustomDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState<IFileWithMeta | null>(null);

  const Schema = Yup.object().shape({
    emailAddress: Yup.string()
      .email('Email must be a valid email address')
      .required('Must enter email address')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      emailAddress: ''
    },
    validationSchema: Schema,
    onSubmit: async (_, { setSubmitting }) => {
      customDispatch({
        action: sendInvite,
        actionParameters: { email: values.emailAddress },
        onSuccess: () => {
          onInviteSuccess();
        },
        onFinally: () => {
          setSubmitting(false);
        }
      });
    }
  });
  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    isValid,
    submitForm
  } = formik;

  const onValidateDropzone = (file: IFileWithMeta | null) => {
    new Promise(async (resolve, reject) => {
      setFile(file);
    });
  };

  const onChangeStatusDropzone = (_: IFileWithMeta, status: StatusValue) => {
    if (status === 'removed') {
      onValidateDropzone(null);
    }
  };

  const onInviteSuccess = () => {
    navigate(PATH_DASHBOARD.outlook.root);
  };

  const TABS = [
    {
      value: 'Excel upload',
      component: (
        <div style={{ width: '20rem' }}>
          <CustomDropzone
            disabled={isSubmitting}
            inputContent={
              <Typography key="dropzoneInputContent">Drag Files or Click to Browse</Typography>
            }
            accept=".xlsx"
            onChangeStatus={onChangeStatusDropzone}
            validate={onValidateDropzone}
            maxFiles={1}
          />
          <Typography variant="caption">
            To invite users to use the Outlook feature, your file must contain exactly one column
            named&nbsp; 'user_email'. Download our template&nbsp;
            <a href={`${releaseConfig.uploadTemplatesUrl}/nps_invite_template.xlsx`} download>
              here
            </a>
            &nbsp;to get started.
          </Typography>
        </div>
      )
    },
    {
      value: 'Single invite',
      component: (
        <TextField
          sx={{ width: 'max-content' }}
          label="E-mail Address"
          {...getFieldProps('emailAddress')}
          error={Boolean(getIn(errors, 'emailAddress') && getIn(touched, 'emailAddress'))}
          helperText={getIn(touched, 'emailAddress') && getIn(errors, 'emailAddress')}
        />
      )
    }
  ];
  const [selectedTab, setSelectedTab] = useState(TABS[0].value);

  const isReadyToInvite =
    (selectedTab === TABS[1].value && isValid && values.emailAddress.length > 0) ||
    (selectedTab === TABS[0].value && file);

  const onClickSendInvite = async () => {
    //excel
    if (selectedTab === TABS[0].value) {
      formik.setSubmitting(true);
      customDispatch({
        action: sendInvitesExcel,
        actionParameters: { file: file?.file },
        onSuccess: () => {
          onInviteSuccess();
        },
        onFinally: () => {
          formik.setSubmitting(false);
        }
      });
    } else {
      submitForm();
    }
  };

  const onTabChange = (value: any) => {
    setSelectedTab(value);
    setFile(null);
  };

  return (
    <>
      <Page
        pageIcon={<IntegrationImage integrationName="outlook" />}
        title="Invite"
        prevLinks={[{ name: 'Outlook', href: PATH_DASHBOARD.outlook.root }]}
      >
        <Tabs
          value={selectedTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(e, value) => onTabChange(value)}
        >
          {TABS.map((tab) => (
            <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} value={tab.value} />
          ))}
        </Tabs>
        <Box sx={{ mb: 5 }} />
        <FormikProvider value={formik}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <div
              style={{
                width: 'max-content',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <StyledCard>{TABS.find((tab) => tab.value === selectedTab)?.component}</StyledCard>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end',
                  paddingTop: '1rem'
                }}
              >
                <CustomLoadingButton
                  color="primary"
                  variant="contained"
                  loading={isSubmitting}
                  onClick={onClickSendInvite}
                  disabled={!isReadyToInvite}
                >
                  Invite
                </CustomLoadingButton>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </Page>
    </>
  );
};

export default OutlookInvite;

const StyledCard = styled(Card)({
  height: '100%',
  padding: '1rem'
});
