import { createSlice } from '@reduxjs/toolkit';
import { NpsNotification, NpsNotificationState } from '../../@types/npsNotification';
import { dispatch } from 'redux/store';
import axios, { axiosHtmlFilesInstance } from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: NpsNotificationState = {
  npsNotification: {
    id: '',
    name: '',
    subject: '',
    previewText: '',
    isDefault: false,
    logoWidth: undefined,
    logoAlign: 'center',
    logoUrl: undefined,
    colorScheme: undefined,
    content: '',
    bottomContent: '',
    lineHeight: undefined,
    fontFamily: 'Arial'
  },
  npsNotifications: [],
  htmlFile: '',
  isLoading: {}
};

const slice = createSlice({
  name: 'npsNotifications',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      state.isLoading[action.payload] = true;
    },

    // END LOADING
    endLoading(state, action) {
      state.isLoading[action.payload] = false;
    },
    setNpsNotifications(state, action) {
      state.npsNotifications = action.payload;
    },
    setHtmlFile(state, action) {
      state.htmlFile = action.payload;
    },
    setNpsNotification(state, action) {
      state.npsNotification = action.payload;
    },
    resetNpsNotification(state) {
      state.npsNotification = initialState.npsNotification;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { resetNpsNotification } = slice.actions;

export function getNpsNotifications() {
  dispatch(slice.actions.startLoading('getNpsNotifications'));
  return async () => {
    try {
      const response = await axios.get('/notifications');
      dispatch(slice.actions.setNpsNotifications(response.data));
      dispatch(slice.actions.endLoading('getNpsNotifications'));
      return await Promise.resolve({
        defaultSuccessMessage: `Fetched NPS Notifications`
      });
    } catch (error: any) {
      dispatch(slice.actions.endLoading('getNpsNotifications'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch NPS Notifications`
      });
    }
  };
}

type GetNpsNotification = {
  npsNotificationId: string;
  isDefault?: boolean;
};
export function getNpsNotification({ npsNotificationId, isDefault }: GetNpsNotification) {
  dispatch(slice.actions.startLoading('getNpsNotification'));
  return async () => {
    try {
      let response;
      response = await axios.get(
        `/notifications/${isDefault ? 'defaults/' : ''}${npsNotificationId}`
      );

      dispatch(slice.actions.setNpsNotification(response.data));
      dispatch(slice.actions.endLoading('getNpsNotification'));
      return await Promise.resolve({
        defaultSuccessMessage: `Fetched NPS Notification`
      });
    } catch (error: any) {
      dispatch(slice.actions.endLoading('getNpsNotification'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch NPS Notification`
      });
    }
  };
}

type SaveNpsNotification = {
  npsNotification: NpsNotification;
};
export function saveNpsNotification({ npsNotification }: SaveNpsNotification) {
  dispatch(slice.actions.startLoading('saveNpsNotification'));
  return async () => {
    try {
      let response;
      if (npsNotification.id) {
        response = await axios.put(`/notifications`, { ...npsNotification });
      } else {
        response = await axios.post(`/notifications`, { ...npsNotification });
      }

      dispatch(slice.actions.setNpsNotification(response.data));
      dispatch(slice.actions.endLoading('saveNpsNotification'));
      return await Promise.resolve({
        defaultSuccessMessage: `NPS Notification saved`
      });
    } catch (error: any) {
      dispatch(slice.actions.endLoading('saveNpsNotification'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not save NPS Notification`
      });
    }
  };
}

type DeleteNpsNotification = {
  npsNotificationId: string;
};
export function deleteNpsNotification({ npsNotificationId }: DeleteNpsNotification) {
  dispatch(slice.actions.startLoading('deleteNpsNotification'));
  return async () => {
    try {
      await axios.delete(`/notifications/${npsNotificationId}`);

      dispatch(slice.actions.endLoading('deleteNpsNotification'));
      return await Promise.resolve({
        defaultSuccessMessage: `NPS Notification deleted`
      });
    } catch (error: any) {
      dispatch(slice.actions.endLoading('deleteNpsNotification'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not delete NPS Notification`
      });
    }
  };
}

type GetHtmlFileProps = {
  htmlFileName: string;
};
export function getHtmlFile({ htmlFileName }: GetHtmlFileProps) {
  dispatch(slice.actions.startLoading('getHtmlFile'));
  return async () => {
    try {
      const response = await axiosHtmlFilesInstance.get(`/${htmlFileName}.html`);
      dispatch(slice.actions.setHtmlFile(response.data.toString()));
      dispatch(slice.actions.endLoading('getHtmlFile'));
      return await Promise.resolve({
        defaultSuccessMessage: `Fetched html file`
      });
    } catch (error: any) {
      dispatch(slice.actions.endLoading('getHtmlFile'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch html file`
      });
    }
  };
}

type CloneNpsNotioficationProps = {
  npsNotification: NpsNotification;
};
export function cloneNpsNotiofication({ npsNotification }: CloneNpsNotioficationProps) {
  return async () => {
    try {
      const clonedNpsNotification: NpsNotification = {
        ...npsNotification,
        id: undefined,
        isDefault: false,
        name: ''
      };

      dispatch(slice.actions.setNpsNotification(clonedNpsNotification));
      return await Promise.resolve({
        defaultSuccessMessage: `Cloned NPS Notification`
      });
    } catch (error: any) {
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not clone NPS Notification`
      });
    }
  };
}
