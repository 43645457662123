import { CircularProgress, IconButton, Stack, Tooltip } from '@mui/material';
import ReactTimeAgo from 'react-time-ago';
import { useState, useEffect, useCallback } from 'react';
import Icon from 'components/icons/Icon';

const UpdateButton = ({
  onClickUpdate,
  lastUpdated,
  isLoading,
  disableForSec
}: {
  onClickUpdate: () => void;
  lastUpdated: number | undefined;
  isLoading: boolean;
  disableForSec?: number;
}) => {
  const [disabled, setDisabled] = useState(false);
  const [timeUntilEnabled, setTimeUntilEnabled] = useState<number>(0);

  const updateDisableForSec = useCallback(() => {
    if (disableForSec) {
      const msUntilEnabled = disableForSec * 1000;
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
      }, msUntilEnabled);
      setTimeUntilEnabled(Date.now() + msUntilEnabled);
    }
  }, [disableForSec]);

  const onClick = () => {
    updateDisableForSec();
    onClickUpdate();
  };

  useEffect(() => {
    updateDisableForSec();
  }, [updateDisableForSec]);

  const iconHeight = '2rem';
  return (
    <Stack
      marginRight="auto"
      alignItems="center"
      direction="row"
      style={{ minWidth: lastUpdated ? '5rem' : 'unset' }}
    >
      <Tooltip
        title={
          isLoading ? (
            ''
          ) : !disabled ? (
            'Refresh'
          ) : (
            <span>
              Refresh available in&nbsp;
              <ReactTimeAgo date={timeUntilEnabled as number} locale="en-US" timeStyle="twitter" />
            </span>
          )
        }
      >
        <span>
          <IconButton
            color="primary"
            onClick={onClick}
            disabled={disabled}
            style={{ display: 'flex', alignItems: 'start' }}
          >
            {isLoading ? (
              <CircularProgress style={{ height: iconHeight, width: iconHeight }} />
            ) : (
              <Icon type="reload" style={{ height: iconHeight, width: iconHeight }} />
            )}
          </IconButton>
        </span>
      </Tooltip>
      {lastUpdated && !isLoading && (
        <ReactTimeAgo date={lastUpdated} locale="en-US" timeStyle="twitter" />
      )}
    </Stack>
  );
};

export default UpdateButton;
