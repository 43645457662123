import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { IntegerInput } from 'components/util/input/IntegerInput';
import { useDebounce } from 'hooks/useDebounce';
import { FunctionComponent, useMemo } from 'react';
import { useTranslator } from 'translation/useTranslator';

interface UsageItemProps {
  usage: number;
  quota: number;
  usageType: string;
  usageEditable?: boolean;
  quotaEditable?: boolean;
  loading?: boolean;
  color?: 'primary' | 'warning' | 'error';
  usageText?: string;
  usageError?: string;
  quotaError?: string;
  onUsageChange?: (v: number) => void;
  onQuotaChange?: (v: number) => void;
  allowDecimal?: boolean;
}

export const UsageIndicator: FunctionComponent<UsageItemProps> = ({
  usage,
  quota,
  usageType,
  usageEditable = false,
  quotaEditable = false,
  loading = false,
  color,
  usageText,
  usageError,
  quotaError,
  onUsageChange,
  onQuotaChange,
  allowDecimal
}) => {
  const translator = useTranslator();
  const hasOverusage = usage > quota;
  const overUsage = usage - quota;

  const usageDebounced = useDebounce(usage, 500) as number;
  const quotaDebounced = useDebounce(quota, 500) as number;
  const usagePercentage = useMemo((): number => {
    if (usageDebounced === 0 && quotaDebounced === 0) {
      return 0;
    }
    if (usageDebounced >= quotaDebounced) {
      return 100;
    }
    return (usageDebounced / quotaDebounced) * 100;
  }, [usageDebounced, quotaDebounced]);

  const usageColor = useMemo((): 'primary' | 'warning' | 'error' => {
    if (usagePercentage >= 100) {
      return 'error';
    }
    if (usagePercentage >= 80) {
      return 'warning';
    }
    return 'primary';
  }, [usagePercentage]);

  return (
    <>
      <Box display={'flex'} flexDirection={'column'} gap="0.5rem" width={'100%'}>
        <Typography variant="subtitle1">{`${usageType} Usage`}</Typography>
        <LinearProgress
          variant={loading ? 'indeterminate' : 'determinate'}
          color={color ?? usageColor}
          value={usagePercentage}
          sx={{ height: '1rem', width: '100%' }}
        />
        <Stack direction="row" width={'100%'} justifyContent={'space-between'}>
          <Typography>{usageText ? usageText : `${usage} / ${quota}`}</Typography>
          {hasOverusage && <Typography>{translator.overUsage(`${overUsage}`)}</Typography>}
        </Stack>
      </Box>
      {usageEditable && (
        <>
          <IntegerInput
            label={`${usageType} Usage`}
            value={usage}
            error={!!usageError}
            helperText={usageError}
            onChange={(v) => onUsageChange?.(v as number)}
            allowDecimal={allowDecimal}
          />
        </>
      )}
      {quotaEditable && (
        <>
          <IntegerInput
            label={`${usageType} Quota`}
            value={quota}
            error={!!quotaError}
            helperText={quotaError}
            onChange={(v) => onQuotaChange?.(v as number)}
            allowDecimal={allowDecimal}
          />
        </>
      )}
    </>
  );
};
