import { Tooltip } from '@mui/material';
import Icon from 'components/icons/Icon';

type FurtherInfoProps = {
  description?: string | JSX.Element;
  maxWidth?: string;
  isText?: boolean;
};
const FurtherInfo = ({ description, maxWidth = '10rem', isText = false }: FurtherInfoProps) => {
  return (
    <>
      {description && (
        <Tooltip title={<div style={{ maxWidth: maxWidth }}>{description}</div>}>
          <Icon
            type="question"
            isText={isText}
            style={{ pointerEvents: 'auto', cursor: 'default' }}
            onClick={(event) => event.preventDefault()}
          />
        </Tooltip>
      )}
    </>
  );
};

export default FurtherInfo;
