import { GridColDef } from '@mui/x-data-grid';
import { BillableHoursOrder, BillableHoursType } from '../../../../@types/order';
import { CustomDataGrid } from 'components/datagrid/Custom';
import { useEffect, useMemo } from 'react';
import { DataGridKey } from 'redux/slices/datagrid';
import { PATH_DASHBOARD } from 'routes/paths';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import useCustomDispatch, { useCustomDispatchWithoutPromise } from 'redux/dispatch';
import { getOrders, resetOrders } from 'redux/slices/orders';
import { RootState } from 'redux/store';
import { baseOrderColumns, baseOrderInitialColumnsHidden } from '../baseOrder/baseOrderColumns';
import { TotalHoursUsageIndicator } from './TotalHoursUsageIndicator';

export default function BillableHoursOrderList() {
  const navigate = useNavigate();
  const customDispatch = useCustomDispatch();
  const customDispatchWithoutPromise = useCustomDispatchWithoutPromise();
  const { isLoading, orderData } = useSelector((state: RootState) => state.order);
  useEffect(() => {
    (async () => {
      await customDispatch({
        action: getOrders,
        actionParameters: { endpoint: 'billable-hours-orders' },
        disableSuccessMessage: true
      });
    })();

    return () => {
      customDispatchWithoutPromise({
        action: resetOrders,
        disableAllMessages: true
      });
    };
  }, [customDispatch, customDispatchWithoutPromise]);

  const columns: GridColDef[] = [
    {
      field: 'billablehourstype',
      headerName: 'Type',
      flex: 1,
      valueGetter: (params) => {
        return params.row.billableHoursType as BillableHoursType;
      },
      renderCell: (params) => {
        return BillableHoursType[params.row.billableHoursType as BillableHoursType];
      }
    },
    {
      field: 'hoursusage',
      type: 'number',
      headerName: 'Usage',
      flex: 1,
      renderCell: (params) => {
        const usage = params.row.hoursUsage as number;
        const quota = params.row.hoursQuota as number;
        if (usage !== undefined && quota !== undefined) {
          return `${usage} / ${quota}`;
        }
      }
    },
    ...baseOrderColumns
  ];

  const activeOrders = useMemo(() => {
    return orderData.orders.filter((o) => o.active === true) as BillableHoursOrder[];
  }, [orderData.orders]);

  return (
    <>
      <TotalHoursUsageIndicator activeOrders={activeOrders} isLoading={isLoading} />
      <CustomDataGrid
        intitialColumnsHidden={{
          billablehourstype: true,
          hoursusage: true,
          ...baseOrderInitialColumnsHidden
        }}
        dataGridKey={DataGridKey.Orders}
        columns={columns}
        loading={isLoading}
        rows={orderData.orders}
        onCellClick={(params) => {
          navigate(`${PATH_DASHBOARD.orders.billableHoursOrders}/${params.id}`);
        }}
        pagination
        pageSize={20}
      />
    </>
  );
}
