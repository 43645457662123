import { useState, useEffect } from 'react';
import 'firebase/remote-config';
import remoteConfig from './remoteConfig';
import { Value, fetchAndActivate, getAll } from 'firebase/remote-config';
import { RemoteConfigKeyConfiguration } from './remoteConfigKey';
interface RemoteConfigValues {
  [key: string]: Value;
}
interface RemoteConfigHook {
  getRemoteConfigValue: (
    keyConfiguration: RemoteConfigKeyConfiguration
  ) => boolean | string | number | null;
}
export const useRemoteConfig = (): RemoteConfigHook => {
  const [remoteConfigValues, setRemoteConfigValues] = useState<RemoteConfigValues>({});
  const fetchRemoteConfig = async (): Promise<void> => {
    await fetchAndActivate(remoteConfig);
    const updatedValues = getAll(remoteConfig);
    setRemoteConfigValues(updatedValues);
  };
  useEffect(() => {
    fetchRemoteConfig();
  }, []);
  const getRemoteConfigValue = (
    keyConfiguration: RemoteConfigKeyConfiguration
  ): boolean | string | number | null => {
    const value = remoteConfigValues[keyConfiguration.key];
    if (!value) {
      return null;
    }
    if (keyConfiguration.type === 'boolean') {
      return value.asBoolean();
    }
    if (keyConfiguration.type === 'string') {
      return value.asString();
    }
    return value.asNumber();
  };
  return { getRemoteConfigValue };
};
