import { Card, FormHelperText, Grid, LinearProgress, Stack } from '@mui/material';
import { useRegisterUnsavedFormChanges } from 'components/form/useRegisterUnsavedFormChanges';
import { CustomLoadingButton } from 'components/util/CustomButton';
import CustomSwitch from 'components/util/CustomSwitch';
import { FormikValues } from 'formik';
import AccessGuard, { Access, useAccess } from 'guards/AccessGuard';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { useTranslator } from 'translation/useTranslator';

type SettingsCardProps = {
  formik: FormikValues;
  isGetLoading: boolean;
  isUpdateLoading: boolean;
  switchLabel: string;
  switchChecked: boolean;
  switchOnChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  descriptionParagraphs: ReactNode[];
  additionalFormFieldsWhenSwitchIsOn?: ReactNode[];
  access: keyof typeof Access;
};
const SettingsCard = ({
  formik,
  isGetLoading,
  isUpdateLoading,
  switchLabel,
  switchChecked,
  switchOnChange,
  descriptionParagraphs,
  additionalFormFieldsWhenSwitchIsOn,
  access
}: SettingsCardProps) => {
  const translator = useTranslator();
  useRegisterUnsavedFormChanges(formik.dirty);
  const { canEdit } = useAccess(access);
  const [isInitialFetched, setIsInitialFetched] = useState(false);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (!isMountedRef.current) return;

    //isInitialFetched should only be false on first render, and until the get request is done
    if (!isGetLoading && !isInitialFetched) {
      setIsInitialFetched(true);
    }
  }, [isGetLoading, isInitialFetched, isMountedRef]);

  return (
    <>
      {isGetLoading && <LinearProgress />}

      {isInitialFetched && (
        <AccessGuard access={access}>
          <Card sx={{ p: 3 }} style={{ maxWidth: '30rem' }}>
            <Stack spacing={3} alignItems="flex-end">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack spacing={1} gap={1} key={`callDurationLimit`}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <CustomSwitch
                        label={switchLabel}
                        labelPlacement="end"
                        switchProps={{
                          checked: switchChecked,
                          onChange: switchOnChange,
                          disabled: !canEdit
                        }}
                      />
                      {descriptionParagraphs.map((paragraph, index) => (
                        <div key={index} style={{ display: 'inherit', flexDirection: 'inherit' }}>
                          <FormHelperText>{paragraph}</FormHelperText>
                          {index < descriptionParagraphs.length - 1 && <br />}
                        </div>
                      ))}
                    </div>
                  </Stack>
                </Grid>
                {switchChecked &&
                  additionalFormFieldsWhenSwitchIsOn?.map(
                    (item, index) =>
                      item && (
                        <Grid key={index} item xs={12}>
                          {item}
                        </Grid>
                      )
                  )}
              </Grid>
              {formik.dirty && (
                <CustomLoadingButton
                  onClick={formik.handleSubmit}
                  variant="contained"
                  loading={formik.isSubmitting || isUpdateLoading}
                  disabled={
                    !formik.dirty ||
                    formik.isSubmitting ||
                    isGetLoading ||
                    isUpdateLoading ||
                    !canEdit
                  }
                >
                  {translator.save()}
                </CustomLoadingButton>
              )}
            </Stack>
          </Card>
        </AccessGuard>
      )}
    </>
  );
};

export default SettingsCard;
