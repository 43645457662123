export type ProvidersState = {
  isLoading: Loading;
  currentEmailProvider?: Provider;
  currentSmsProvider?: Provider;
};

export type Provider = {
  orgId: string;
  providerType: string;
  provider: string;
};

type Loading = {
  [key: string]: boolean;
};

export enum ProviderType {
  EMAIL = 'Email',
  SMS = 'SMS'
}
