import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'redux/store';
import axios from '../../utils/axios';
import { Integration, IntegrationsState } from '../../@types/integrations';

// ----------------------------------------------------------------------
const initialState: IntegrationsState = {
  isLoading: {},
  integrations: [],
  hasError: false
};

export const getIntegrationLoadingIndex = (integrationId?: number) => {
  return `integration${integrationId}`;
};

const slice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    startGetIntegrations(state) {
      state.isLoading.integrations = true;
    },
    getIntegrationsSuccess(state, action) {
      state.integrations = action.payload;
      state.isLoading.integrations = false;
      state.hasError = false;
    },
    getIntegrationsError(state) {
      state.integrations = [];
      state.isLoading.integrations = false;
      state.hasError = true;
    },
    startRemoveIntegration(state, action) {
      state.isLoading[getIntegrationLoadingIndex(action.payload)] = true;
    },
    removeIntegrationSuccess(state, action) {
      state.isLoading[getIntegrationLoadingIndex(action.payload)] = false;
    },
    removeIntegrationError(state, action) {
      state.isLoading[getIntegrationLoadingIndex(action.payload)] = false;
    },
    startAddIntegration(state, action) {
      state.isLoading[getIntegrationLoadingIndex(action.payload)] = true;
    },
    addIntegrationSuccess(state, action) {
      state.isLoading[getIntegrationLoadingIndex(action.payload)] = false;
    },
    addIntegrationError(state, action) {
      state.isLoading[getIntegrationLoadingIndex(action.payload)] = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export const getIntegrations = () => {
  return async () => {
    dispatch(slice.actions.startGetIntegrations());
    try {
      const response = await axios.get(`/integrations`);
      dispatch(slice.actions.getIntegrationsSuccess(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched integrations'
      });
    } catch (error: any) {
      dispatch(slice.actions.getIntegrationsError());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch integrations`
      });
    }
  };
};

export const removeIntegration = (integration: Integration) => {
  const integrationId = integration.id;
  return async () => {
    dispatch(slice.actions.startRemoveIntegration(integrationId));
    try {
      await axios.delete(`/integrations/${integrationId}`);
      dispatch(slice.actions.removeIntegrationSuccess(integrationId));
      return await Promise.resolve({
        defaultSuccessMessage: `${integration?.name} integration removed`
      });
    } catch (error: any) {
      dispatch(slice.actions.removeIntegrationError(integrationId));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not remove integration`
      });
    }
  };
};

export const addIntegration = (integration: Integration) => {
  const integrationId = integration.id;
  return async () => {
    dispatch(slice.actions.startAddIntegration(integrationId));
    try {
      await axios.post(`/integrations/${integrationId}`);
      dispatch(slice.actions.addIntegrationSuccess(integrationId));
      return await Promise.resolve({
        defaultSuccessMessage: `${integration?.name} integration added`
      });
    } catch (error: any) {
      dispatch(slice.actions.addIntegrationError(integrationId));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not add integration`
      });
    }
  };
};
