import * as Yup from 'yup';

const getFormikValidateFunction = (
  schema: Yup.ObjectSchema<any>,
  context?: Record<string, any>
) => {
  return async (values: any) => {
    try {
      await schema.validate(values, { context, abortEarly: false });
      return {};
    } catch (err: any) {
      const errors: Record<string, string> = {};
      if (err.inner) {
        err.inner.forEach((validationError: Yup.ValidationError) => {
          if (validationError.path) {
            errors[validationError.path] = validationError.message;
          }
        });
      }
      return errors;
    }
  };
};

export default getFormikValidateFunction;
