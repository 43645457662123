import { createSlice } from '@reduxjs/toolkit';
import { EditingState } from '../../@types/editing';

export const initialState: EditingState = {
  hasUnsavedChanges: false
};

const slice = createSlice({
  name: 'datagrid',
  initialState,
  reducers: {
    setHasUnsavedChanges(state, action) {
      state.hasUnsavedChanges = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setHasUnsavedChanges } = slice.actions;
