import { useRef } from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';
import MessageContent, { Message } from './MessageContent';
import { MIconButton } from 'components/@material-extend';
import Icon from 'components/icons/Icon';
import { CustomDispatchErrorResponse, CustomDispatchSuccessResponse } from 'redux/dispatch';
import { getErrorMessageContent } from 'utils/errorMessageHandling';
import { getSuccessMessageContent } from 'utils/successMessageHandling';

type BaseMessageSetup = {
  autoHideDuration?: number;
};

type MessageSetup = BaseMessageSetup & {
  message: Message;
};

type SuccessResponseOnly = BaseMessageSetup & {
  response?: CustomDispatchSuccessResponse;
  message?: never;
};

type ErrorResponseOnly = BaseMessageSetup & {
  error: CustomDispatchErrorResponse;
  message?: never;
};

type SuccessMessageSetup = MessageSetup | SuccessResponseOnly;
type ErrorMessageSetup = MessageSetup | ErrorResponseOnly;
export type DisplayMessage = {
  success: (messageSetup: SuccessMessageSetup) => void;
  error: (messageSetup: ErrorMessageSetup) => void;
  warning: (messageSetup: MessageSetup) => void;
  info: (messageSetup: MessageSetup) => void;
};
const useDisplayMessage = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const displayMessage: DisplayMessage = useRef({
    success: (messageSetup: SuccessMessageSetup) => {
      let successMessage = messageSetup.message as Message;
      if ('response' in messageSetup && messageSetup.response) {
        successMessage = getSuccessMessageContent({ successResponse: messageSetup.response });
      }
      enqueueSnackbar(<MessageContent content={successMessage} />, {
        variant: 'success',
        action: snackbarActionProp(closeSnackbar),
        autoHideDuration: messageSetup.autoHideDuration ?? 4000
      });
    },
    error: (messageSetup: ErrorMessageSetup) => {
      let errorMessage = messageSetup.message as Message;
      if ('error' in messageSetup) {
        errorMessage = getErrorMessageContent({ errorResponse: messageSetup.error });
      }
      enqueueSnackbar(<MessageContent content={errorMessage} />, {
        variant: 'error',
        action: snackbarActionProp(closeSnackbar),
        autoHideDuration: messageSetup.autoHideDuration ?? 10000
      });
    },
    warning: (messageSetup: MessageSetup) => {
      enqueueSnackbar(<MessageContent content={messageSetup.message} />, {
        variant: 'warning',
        action: snackbarActionProp(closeSnackbar),
        autoHideDuration: messageSetup.autoHideDuration ?? 10000
      });
    },
    info: (messageSetup: MessageSetup) => {
      enqueueSnackbar(<MessageContent content={messageSetup.message} />, {
        variant: 'info',
        action: snackbarActionProp(closeSnackbar),
        autoHideDuration: messageSetup.autoHideDuration ?? 4000
      });
    }
  }).current;

  return displayMessage;
};

export default useDisplayMessage;

const snackbarActionProp =
  (closeSnackbar: (key?: SnackbarKey | undefined) => void) => (key: SnackbarKey) => {
    return (
      <MIconButton size="small" onClick={() => closeSnackbar(key)}>
        <Icon type="clear" />
      </MIconButton>
    );
  };

const collectErrorMessages = (obj: any): string[] => {
  return Object.values(obj).flatMap((value) =>
    typeof value === 'string' ? value : collectErrorMessages(value)
  );
};

export const getMessageFromFormikErrors = (errors: any): Message => {
  const messages = collectErrorMessages(errors);
  return {
    title: 'Could not save',
    description: `Validation error${messages.length > 1 ? 's' : ''}`,
    detailSegements: [messages]
  };
};
