import { Typography } from '@mui/material';
import styles from './MaintenancePage.module.css';
import { IllustrationMaintenance } from 'components/illustrations/IllustrationMaintenance';
import { useRemoteConfig } from 'utils/firebase/useRemoteConfig';
import {
  DownTimeReasonDesciptionConfig,
  DownTimeReasonTitleConfig,
  ExpectedBackAtConfig
} from 'utils/firebase/remoteConfigKey';

export const MaintenancePage = () => {
  const { getRemoteConfigValue } = useRemoteConfig();
  const title = getRemoteConfigValue(DownTimeReasonTitleConfig);
  const description = getRemoteConfigValue(DownTimeReasonDesciptionConfig);
  const expectedBackAt = getRemoteConfigValue(ExpectedBackAtConfig);
  return (
    <div className={styles.container}>
      <Typography variant="h1">{title}</Typography>
      <Typography>{description}</Typography>
      <IllustrationMaintenance />
      <Typography>{expectedBackAt}</Typography>
    </div>
  );
};
