const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

const isPhoneNumberPartiallyFilled = (phoneNumber: string) => {
  try {
    const parsedNumber = phoneUtil.parse(phoneNumber);
    if (parsedNumber.hasNationalNumber()) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export default isPhoneNumberPartiallyFilled;
