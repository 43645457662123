import { Stack } from '@mui/material';
import DisabledIcon from 'components/util/Status/DisabledIcon';
import EnabledIcon from 'components/util/Status/EnabledIcon';
import { FunctionComponent } from 'react';

interface ActiveListItemProps {
  active: boolean;
  activeTooltip?: string;
  inactiveTooltip?: string;
}

export const ActiveListItem: FunctionComponent<ActiveListItemProps> = ({
  active,
  activeTooltip,
  inactiveTooltip
}) => {
  return (
    <Stack alignItems="center" sx={{ width: 1, textAlign: 'center' }}>
      {active ? (
        <EnabledIcon tooltip={activeTooltip} />
      ) : (
        <DisabledIcon tooltip={inactiveTooltip} />
      )}
    </Stack>
  );
};
