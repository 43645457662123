// Gets config from index.html making it possible to release different environments with a single build
// remember to also add inside index.html
const releaseConfig = {
  ...window.MyConfig,
  uploadTemplatesUrl: window.MyConfig.uploadTemplatesUrl.startsWith('#{')
    ? 'https://npstodaydevelopment.blob.core.windows.net/upload-templates'
    : window.MyConfig.uploadTemplatesUrl
};

export default releaseConfig;
