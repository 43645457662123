import { useState, useRef, useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import { FormikValues, getIn } from 'formik';
import MuiPhoneNumber from 'material-ui-phone-number';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import isPhoneNumberPartiallyFilled from 'utils/isPhoneNumberPartiallyFilled';
import isPhoneNumberValid from 'utils/isPhoneNumberValid';
import { debounce } from 'lodash';
import { useTranslator } from 'translation/useTranslator';

type PhoneNumberInputFieldProps = {
  fieldName: string;
  label?: string;
  formik: FormikValues;
  initialValue?: string;
  disabled?: boolean;
  endAdornment?: JSX.Element;
  required?: boolean;
};
const PhoneNumberInputField = ({
  fieldName,
  label = 'Phone',
  formik,
  initialValue,
  disabled = false,
  endAdornment,
  required
}: PhoneNumberInputFieldProps) => {
  const translator = useTranslator();
  const [enforceCountryCode, setEnforceCountryCode] = useState(true);
  useEffect(() => {
    //if the initial value is empty or a valid phonenumber, enforce the country code
    setEnforceCountryCode(!initialValue?.length || isPhoneNumberValid(initialValue));
  }, [initialValue]);

  const { setFieldValue, errors } = formik;

  const hasCountryCode = (phoneNumber: string) => {
    return phoneNumber.startsWith('+');
  };

  const [isEditing, setIsEditing] = useState(false);

  const debouncedPhoneNumberUpdate = useRef(
    debounce((phoneNumber?: string) => {
      setFieldValue(fieldName, phoneNumber ?? '');
    }, 300)
  ).current;

  const getHelperText = () => {
    return initialValue && !hasCountryCode(initialValue) && Boolean(errors[fieldName]) ? (
      <>
        {translator.phoneWithoutCountryCode(initialValue)}
        {isPhoneNumberValid('+' + initialValue) && (
          <Button
            onClick={() => {
              setFieldValue(fieldName, formatPhoneNumber('+' + initialValue));
            }}
          >
            {translator.useFormattedNumber(formatPhoneNumber('+' + initialValue))}
          </Button>
        )}
      </>
    ) : (
      errors[fieldName]
    );
  };

  return (
    <>
      {enforceCountryCode ? (
        <MuiPhoneNumber
          disabled={disabled}
          key={fieldName}
          value={initialValue}
          preferredCountries={['dk']}
          fullWidth
          label={label}
          name={fieldName}
          defaultCountry="dk"
          variant="outlined"
          autoFormat={!isEditing}
          required={required}
          onChange={(event) => {
            const newPhoneNumber = event as string;
            //update form with entered value
            debouncedPhoneNumberUpdate(newPhoneNumber);
          }}
          onFocus={() => {
            setIsEditing(true);
          }}
          onBlur={(event) => {
            setIsEditing(false);
            const newPhoneNumber = event.target.value;
            //if no phonenumber, set form value to '', as to not save the countrycode
            debouncedPhoneNumberUpdate(
              isPhoneNumberPartiallyFilled(newPhoneNumber) ? formatPhoneNumber(newPhoneNumber) : ''
            );
          }}
          error={Boolean(errors[fieldName])}
          helperText={getHelperText()}
          InputProps={{ endAdornment: endAdornment }}
        />
      ) : (
        <TextField
          label={label}
          fullWidth
          {...formik.getFieldProps(fieldName)}
          error={Boolean(getIn(formik.errors, fieldName))}
          helperText={getHelperText()}
        />
      )}
    </>
  );
};

export default PhoneNumberInputField;
