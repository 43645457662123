import { IconButton, InputAdornment, Stack, TextField, TextFieldProps } from '@mui/material';
import { useRef, useState, ChangeEvent, useEffect } from 'react';
import FurtherInfo from './FurtherInfo';
import Icon from 'components/icons/Icon';
import { debounce } from 'lodash';
import { CopyButton } from 'components/button/CopyButton';

type CustomTextFieldProps = {
  enableCopyToClipboard?: boolean;
  enableShowHide?: boolean;
  description?: string | JSX.Element;
  descriptionMaxWidth?: string;
  endAdornments?: JSX.Element[];
  debounceBy?: number;
};

const CustomTextField = (props: TextFieldProps & CustomTextFieldProps) => {
  const {
    enableCopyToClipboard,
    enableShowHide,
    description,
    descriptionMaxWidth,
    endAdornments,
    onChange,
    debounceBy,
    value,
    defaultValue,
    ...rest
  } = props;

  const [internalValue, setInternalValue] = useState(defaultValue);
  const [displaySid, setDisplaySid] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const isAnyEndAdornmentsEnabled = Boolean(
    description ||
      enableCopyToClipboard ||
      enableShowHide ||
      (endAdornments && endAdornments.length > 0)
  );

  const InputProps = rest.InputProps || {};

  /**
   * Debounce logic
   */
  // Set up the debounced onChange handler if debounce props are provided
  const debouncedOnChangeHandlerRef = useRef(
    debounceBy
      ? debounce((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          onChange?.(event);
        }, debounceBy)
      : null
  );

  // Function to handle the change events
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // If debouncing is enabled, use the debounced handler
    if (debouncedOnChangeHandlerRef.current) {
      debouncedOnChangeHandlerRef.current(event);
    } else {
      // If no debouncing is needed, invoke the regular onChange prop
      onChange?.(event);
    }

    // Update the internal value for controlled component
    setInternalValue(event.target.value);
  };

  useEffect(() => {
    // Update internal state when defaultValue prop changes
    if (defaultValue !== undefined) {
      setInternalValue(defaultValue);
    }
  }, [defaultValue]);

  /**
   * Copy to clipboard logic
   */
  const inputString = String(debounceBy ? internalValue : props.value || '');

  return (
    <TextField
      value={debounceBy ? internalValue : value} //if debounce is enabled, use internalValue as the value
      defaultValue={debounceBy ? undefined : defaultValue} //if debounce is enabled, use undefined as the defaultValue
      onChange={handleChange}
      type={rest.type || !enableShowHide ? 'text' : displaySid ? 'text' : 'password'}
      inputRef={inputRef}
      InputProps={
        !isAnyEndAdornmentsEnabled
          ? {} //if no endAdornment, use empty object as to make input text field fill the entire input width
          : {
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{
                    paddingRight: rest.select ? '1.5rem' : 'initial' //if select is enabled, add padding to the right to accommodate the dropdown icon
                  }}
                >
                  <Stack direction={{ xs: 'row' }}>
                    {description && (
                      <FurtherInfo description={description} maxWidth={descriptionMaxWidth} />
                    )}
                    {enableCopyToClipboard && inputString && (
                      <CopyButton
                        textToCopy={inputString}
                        successText={`${props.label} copied to clipboard`}
                      />
                    )}
                    {enableShowHide && inputString && (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setDisplaySid((prevState) => !prevState)}
                        edge="end"
                      >
                        {displaySid ? <Icon type="eyeon" /> : <Icon type="eyeoff" />}
                      </IconButton>
                    )}
                    {endAdornments?.map((endAdornment, index) => (
                      <div key={index}>{endAdornment}</div>
                    ))}
                  </Stack>
                  {rest.InputProps?.endAdornment}
                </InputAdornment>
              ),
              ...InputProps
            }
      }
      {...rest}
    />
  );
};

export default CustomTextField;
