// material
import { useTheme, styled } from '@mui/material/styles';
import { Toolbar, Typography, InputAdornment, OutlinedInput } from '@mui/material';
import Icon from 'components/icons/Icon';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

type OrganizationListToolbarProps = {
  numSelected?: number;
  filterName: string;
  onFilterName: (value: string) => void;
  placeholder?: string;
};

export default function OrganizationListToolbar({
  numSelected,
  filterName,
  onFilterName,
  placeholder
}: OrganizationListToolbarProps) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <RootStyle
      sx={{
        ...(numSelected! > 0 && {
          color: isLight ? 'primary.main' : 'text.primary',
          bgcolor: isLight ? 'primary.lighter' : 'primary.dark'
        })
      }}
    >
      {numSelected! > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={(e) => onFilterName(e.target.value)}
          placeholder={placeholder ?? 'Search product...'}
          startAdornment={
            <InputAdornment position="start">
              <Icon type="search" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}
    </RootStyle>
  );
}
