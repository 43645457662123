// material
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  const theme = useTheme();
  const PRIMARY_WHITE = theme.palette.common.white;
  const PRIMARY_BLACK = theme.palette.common.black;

  return (
    <Box sx={{ width: 50, height: 50, ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="158.91001892089844, 140.35000610351562, 269.0599365234375, 306.1600036621094"
      >
        <g
          fill={theme.palette.mode === 'dark' ? PRIMARY_WHITE : PRIMARY_BLACK}
          fillRule="evenodd"
          stroke="none"
          strokeWidth="1"
        >
          <path
            className="cls-2"
            d="M365.89,221.53V363.67a20.76,20.76,0,1,1-41.48,1.65h.14V223.17a82.82,82.82,0,1,0-165.64,0V345.29a20,20,0,0,0,20,20h62.79V303.27H221v-80.1a20.79,20.79,0,1,1,41.55,0v21.52l-.22,119a82.82,82.82,0,1,0,165.64,0V221.53Z"
          />
        </g>
      </svg>
    </Box>
  );
}
