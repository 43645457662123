import { IconType } from '../../../@types/icons';
import StatusIcon from './StatusIcon';

type DisabledProps = {
  tooltip?: string;
  size?: string;
  color?: string;
};
const DisabledIcon = ({ tooltip, size, color = 'error.main' }: DisabledProps) => {
  return (
    <StatusIcon
      tooltip={tooltip ?? 'Disabled'}
      size={size}
      color={color}
      icon={IconType.disabled}
    />
  );
};
export default DisabledIcon;
