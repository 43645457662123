import React from 'react';
import { JSONSchema6 } from 'json-schema';
import { ThemeProps, withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from '@rjsf/material-ui';
import customWidgets from './CustomWidgets';
import RjsfWrapper from './RjsfWrapper';

MuiTheme.widgets = customWidgets;

type RJSFProps = {
  schema?: JSONSchema6;
  submitCount: number; //used by parent component to trigger submit
  formData: any;
  setRjsfFormData: (formData: any) => void;
};
class RjsfForm extends React.Component<RJSFProps> {
  constructor(props: RJSFProps) {
    super(props);
    this.state = {
      submitCount: props.submitCount
    } as RJSFProps;
  }

  submitBtnRef = React.createRef<HTMLButtonElement>();

  Form = withTheme(MuiTheme as ThemeProps);

  submitForm = () => {
    this.submitBtnRef.current?.click();
  };

  componentDidUpdate = (nextProps: RJSFProps) => {
    //submit form if count is above 0, and actually changed
    if (this.props.submitCount > 0 && nextProps.submitCount !== this.props.submitCount) {
      this.submitForm();
      this.setState({
        submitCount: this.props.submitCount
      });
    }
  };

  render() {
    return (
      <>
        {this.props.schema && (
          <RjsfWrapper>
            <this.Form
              showErrorList={false}
              schema={this.props.schema}
              formData={this.props.formData}
              children={
                <button type="submit" ref={this.submitBtnRef} style={{ display: 'none' }} />
              } //submit button is hidden, as parent component decides when to submit
              onChange={(event) => this.props.setRjsfFormData(event.formData)}
              onError={(event) => console.log('error', event)}
            />
          </RjsfWrapper>
        )}
      </>
    );
  }
}

export default RjsfForm;
