import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { Access } from '../@types/claims';
import ClaimBasedGuard from '../guards/ClaimBasedGuard';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/util/LoadingScreen';
import { PATH_DASHBOARD, ROOTS_APP } from './paths';
import OutlookInvite from 'pages/outlook/OutlookInvite';
import { AdminTab } from '../pages/admin/Admin';
import { CampaignsTab } from '../pages/campaigns/Campaigns';
import useAuth from '../hooks/useAuth';
import { IVRTab } from '../pages/ivr/IVR';
import { OrganisationTab } from '../pages/organizations/Organization';
import { OrdersTab } from '../pages/orders/Orders';
import AccessGuard from 'guards/AccessGuard';

// ----------------------------------------------------------------------
const Loadable = (Component: React.LazyExoticComponent<React.ComponentType<any>>) => {
  const LoadableComponent = (props: any) => {
    const { isDataReflectingCurrentOrg, isInitialized } = useAuth();
    const pathname = window.location.pathname;
    const isDashboard = pathname.includes('/home');

    return (
      <Suspense
        fallback={
          <div>
            {isInitialized && isDataReflectingCurrentOrg && (
              <LoadingScreen
                sx={{
                  ...(!isDashboard && {
                    top: 0,
                    left: 0,
                    width: 1,
                    zIndex: 9999,
                    position: 'fixed'
                  })
                }}
              />
            )}
          </div>
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };

  return LoadableComponent;
};
/*
const Loadable = (Component: React.ElementType) => (props: any) => {
  const pathname = window.location.pathname;
  const isDashboard = pathname.includes('/home');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
}; */

export default function Router() {
  return useRoutes([
    {
      path: ROOTS_APP.substring(1),
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={`${ROOTS_APP}/dashboard`} replace /> },
        { path: 'dashboard', element: <DashboardOverview /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to={`${ROOTS_APP}/user/profile`} replace /> },
            { path: 'profile', element: <UserAccount /> },
            { path: 'settings', element: <CustomizeSettings /> }
          ]
        },
        {
          path: 'campaigns',
          children: [
            {
              element: (
                <Navigate
                  to={`${ROOTS_APP}/campaigns/tabs/${CampaignsTab.ACTIVE_CAMPAIGNS}`}
                  replace
                />
              )
            },
            {
              path: 'tabs/:tab',
              children: [{ element: <Campaigns /> }]
            },
            { path: 'new', element: <CampaignNew /> },
            { path: ':id/edit', element: <CampaignEdit /> },
            {
              path: 'templates',
              children: [
                {
                  element: (
                    <Navigate
                      to={`${ROOTS_APP}/campaigns/tabs/${CampaignsTab.ACTIVE_CAMPAIGNS}`}
                      replace
                    />
                  )
                },
                { path: 'new', element: <TemplateNew /> },
                { path: ':id', element: <TemplateDetail /> }
              ]
            },
            {
              path: 'npsNotifications',
              children: [
                {
                  element: (
                    <Navigate
                      to={`${ROOTS_APP}/campaigns/tabs/${CampaignsTab.NPS_NOTIFICATIONS}`}
                      replace
                    />
                  )
                },
                { path: 'new', element: <NpsNotificationEdit isNew={true} /> },
                { path: ':id', element: <NpsNotificationEdit /> }
              ]
            },
            {
              path: ':id',
              children: [
                { element: <CampaignDetail /> },
                { path: 'send-survey', element: <CampaignSendSurvey /> },
                {
                  path: 'campaignMembers',
                  children: [
                    {
                      path: ':campaignMemberId',
                      element: <CampaignMemberDetail />,
                      children: [{ path: ':edit', element: <CampaignMemberDetail /> }]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: 'companies',
          children: [{ element: <Companies /> }]
        },
        {
          path: 'account',
          children: [
            {
              element: <Navigate to={`${ROOTS_APP}/account/${OrganisationTab.GENERAL}`} replace />
            },
            { path: 'users/:userId', element: <OrganizationUserDetail /> },
            {
              path: ':tab',
              children: [{ element: <Organization /> }]
            }
          ]
        },
        {
          path: 'orders',
          children: [
            {
              element: (
                <AccessGuard access="SuperAdmin">
                  <Navigate
                    to={`${PATH_DASHBOARD.orders.tabs}/${OrdersTab.SURVEY_ORDERS}`}
                    replace
                  />
                </AccessGuard>
              )
            },

            {
              path: 'tabs/:tab',
              element: (
                <AccessGuard access="SuperAdmin">
                  <Orders />
                </AccessGuard>
              )
            },
            {
              path: 'survey-orders/:id',
              element: (
                <AccessGuard access="SuperAdmin">
                  <SurveyOrderDetail />
                </AccessGuard>
              )
            },
            {
              path: 'survey-orders/new',
              element: (
                <AccessGuard access="SuperAdmin">
                  <NewSurveyOrder />
                </AccessGuard>
              )
            },
            {
              path: 'product-orders/:id',
              element: (
                <AccessGuard access="SuperAdmin">
                  <ProductOrderDetail />
                </AccessGuard>
              )
            },
            {
              path: 'product-orders/new',
              element: (
                <AccessGuard access="SuperAdmin">
                  <NewProductOrder />
                </AccessGuard>
              )
            },
            {
              path: 'billable-hours-orders/:id',
              element: (
                <AccessGuard access="SuperAdmin">
                  <BillableHoursOrderDetail />
                </AccessGuard>
              )
            },
            {
              path: 'billable-hours-orders/new',
              element: (
                <AccessGuard access="SuperAdmin">
                  <NewBillableHoursOrder />
                </AccessGuard>
              )
            }
          ]
        },
        {
          path: 'respondents',
          children: [
            { element: <Respondents /> },
            {
              path: ':id',
              element: <RespondentDetail />,
              children: [{ path: ':edit', element: <RespondentDetail /> }]
            }
          ]
        },
        {
          path: 'p-gap',
          children: [
            {
              element: (
                <ClaimBasedGuard accessType={Access.PAGE_PGAP}>
                  <PGap />
                </ClaimBasedGuard>
              )
            }
          ]
        },
        {
          path: 'integration-store',
          children: [
            {
              element: (
                <ClaimBasedGuard accessType={Access.PAGE_INTEGRATION_STORE}>
                  <IntegrationStore />
                </ClaimBasedGuard>
              )
            }
          ]
        },
        {
          path: 'ivr',
          children: [
            { element: <Navigate to={`${ROOTS_APP}/ivr/${IVRTab.CONNECTIONS}`} replace /> },
            {
              path: ':tab',
              children: [
                {
                  element: (
                    <ClaimBasedGuard accessType={Access.PAGE_IVR}>
                      <IVR />
                    </ClaimBasedGuard>
                  )
                }
              ]
            }
          ]
        },
        {
          path: 'outlook',
          children: [
            {
              element: (
                <ClaimBasedGuard accessType={Access.PAGE_OUTLOOK}>
                  <Outlook />
                </ClaimBasedGuard>
              )
            },
            {
              path: 'invite',
              element: (
                <ClaimBasedGuard accessType={Access.PAGE_OUTLOOK}>
                  <OutlookInvite />
                </ClaimBasedGuard>
              )
            }
          ]
        },
        {
          path: 'etrack1',
          children: [
            {
              element: (
                <ClaimBasedGuard accessType={Access.PAGE_ETRACK1}>
                  <Etrack1 />
                </ClaimBasedGuard>
              )
            }
          ]
        },
        {
          path: 'zapier',
          children: [
            {
              element: (
                <ClaimBasedGuard accessType={Access.PAGE_ZAPIER}>
                  <Zapier />
                </ClaimBasedGuard>
              )
            }
          ]
        },
        {
          path: 'product-configurations',
          children: [
            {
              element: (
                <AccessGuard displayNoAccess={true} access="PageProductConfiguration">
                  <ProductConfigurations />
                </AccessGuard>
              )
            }
          ]
        },
        {
          path: 'reports',
          children: [{ element: <Reports /> }]
        },
        {
          path: 'reviews',
          children: [
            {
              element: (
                <ClaimBasedGuard accessType={Access.PAGE_REVIEWS}>
                  <Reviews />
                </ClaimBasedGuard>
              )
            }
          ]
        },
        { path: 'employees', element: <Employees /> },
        {
          path: 'admin',
          children: [
            { element: <Navigate to={`${ROOTS_APP}/admin/${AdminTab.GENERAL}`} replace /> },
            {
              path: ':tab',
              children: [
                {
                  element: (
                    <ClaimBasedGuard
                      accessType={Access.PAGE_SUPER_ADMIN}
                      ignoreToggledClaims={true}
                    >
                      <Admin />
                    </ClaimBasedGuard>
                  )
                }
              ]
            }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'login/*',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'forgot-password',
          element: (
            <GuestGuard>
              <ForgotPassword />
            </GuestGuard>
          )
        },
        {
          path: 'update-password',
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          )
        },
        {
          path: 'sign-up',
          element: (
            <GuestGuard>
              <SignUp />
            </GuestGuard>
          )
        }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const ForgotPassword = Loadable(lazy(() => import('../pages/authentication/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const SignUp = Loadable(lazy(() => import('../pages/authentication/SignUp')));
// Dashboard
const Campaigns = Loadable(lazy(() => import('../pages/campaigns/Campaigns')));
const Companies = Loadable(lazy(() => import('../pages/companies/Companies')));
const CampaignDetail = Loadable(lazy(() => import('../pages/campaigns/CampaignDetail')));
const CampaignSendSurvey = Loadable(lazy(() => import('../pages/campaigns/CampaignSendSurvey')));
const CampaignEdit = Loadable(lazy(() => import('../pages/campaigns/CampaignEdit')));
const CampaignNew = Loadable(lazy(() => import('../pages/campaigns/CampaignNew')));
const DashboardOverview = Loadable(lazy(() => import('../pages/dashboard/DashboardOverview')));
const Employees = Loadable(lazy(() => import('../pages/employees/Employees')));
const Organization = Loadable(lazy(() => import('../pages/organizations/Organization')));
const OrganizationUserDetail = Loadable(
  lazy(() => import('../pages/organizations/OrganizationUserDetail'))
);
const UserAccount = Loadable(lazy(() => import('../pages/account/UserAccount')));
const CustomizeSettings = Loadable(lazy(() => import('../pages/customize/CustomizeSettings')));
const IntegrationStore = Loadable(lazy(() => import('../pages/integrationStore/IntegrationStore')));
const IVR = Loadable(lazy(() => import('../pages/ivr/IVR')));
const Outlook = Loadable(lazy(() => import('../pages/outlook/Outlook')));
const Etrack1 = Loadable(lazy(() => import('../pages/etrack1/Etrack1')));
const Zapier = Loadable(lazy(() => import('../pages/zapier/Zapier')));
const ProductConfigurations = Loadable(
  lazy(() => import('../pages/productConfiguration/ProductConfigurations'))
);

const PGap = Loadable(lazy(() => import('../pages/pgap/PGap')));
const Respondents = Loadable(lazy(() => import('../pages/respondents/Respondents')));
const RespondentDetail = Loadable(lazy(() => import('../pages/respondents/RespondentDetail')));
const TemplateDetail = Loadable(lazy(() => import('../pages/templates/TemplateDetail')));
const TemplateNew = Loadable(lazy(() => import('../pages/templates/TemplateNew')));
const Orders = Loadable(lazy(() => import('../pages/orders/Orders')));
const SurveyOrderDetail = Loadable(
  lazy(() => import('../pages/orders/components/surveyOrder/SurveyOrderDetails'))
);
const ProductOrderDetail = Loadable(
  lazy(() => import('../pages/orders/components/productOrder/ProductOrderDetails'))
);
const BillableHoursOrderDetail = Loadable(
  lazy(() => import('../pages/orders/components/billableHoursOrder/BillableHoursOrderDetails'))
);
const NewSurveyOrder = Loadable(
  lazy(() => import('../pages/orders/components/surveyOrder/NewSurveyOrderForm'))
);
const NewBillableHoursOrder = Loadable(
  lazy(() => import('../pages/orders/components/billableHoursOrder/NewBillableHoursOrderForm'))
);
const NewProductOrder = Loadable(
  lazy(() => import('../pages/orders/components/productOrder/NewProductOrderForm'))
);
const NpsNotificationEdit = Loadable(
  lazy(() => import('../pages/npsNotifications/NpsNotificationEdit'))
);
const Reports = Loadable(lazy(() => import('../pages/reports/Reports')));
const Reviews = Loadable(lazy(() => import('../pages/reviews/Reviews')));
const CampaignMemberDetail = Loadable(
  lazy(() => import('../pages/campaignMember/CampaignMemberDetail'))
);
// Admin
const Admin = Loadable(lazy(() => import('../pages/admin/Admin')));
// Main
const Page500 = Loadable(lazy(() => import('../pages/fallback/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/fallback/Page404')));
