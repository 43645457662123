import { Menu, MenuItem, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { MIconButton } from 'components/@material-extend';
import { useRef, useState } from 'react';
import AreYouSureModal, { AreYouSureModalType } from './AreYouSureModal';
import { startWithUppercase } from 'utils/convertString';
import Icon from 'components/icons/Icon';
import AccessGuard, { Access } from 'guards/AccessGuard';
import { useMemo } from 'react';
import { IconType } from '../../@types/icons';
import useIsMountedRef from 'hooks/useIsMountedRef';

type Action = {
  label: string;
  action: () => void;
  access?: keyof typeof Access;
  icon?: IconType | keyof typeof IconType | JSX.Element;
};

type CustomMenuItem = {
  onClick: () => void;
  title: string;
  icon: JSX.Element;
};

type MoreMenuButtonProps = {
  itemType: string;
  onEdit?: () => void;
  onEditAccess?: keyof typeof Access;
  onDisplay?: () => void;
  onSendAgain?: () => void;
  onMail?: () => void;
  onUnsubscribe?: () => void;
  onDelete?: (() => void) | (() => Promise<any>);
  onDeleteAccess?: keyof typeof Access;
  deleteAreYouSureContent?: JSX.Element | string;
  onTest?: () => void;
  onClone?: () => void;
  onInfo?: () => void;
  onOpen?: (() => void) | null;
  onClose?: (() => void) | null;
  closeAreYouSureContent?: JSX.Element | string;
  onDownload?: (() => void) | null;
  customMenuItems?: CustomMenuItem[];
  onMenuClick?: (opened: boolean) => void;
  useDeleteConfirmationSafeguard?: boolean;
};

const MoreMenuButton = ({
  itemType,
  onEdit,
  onEditAccess,
  onDisplay,
  onSendAgain,
  onUnsubscribe,
  onMail,
  onDelete,
  onDeleteAccess,
  deleteAreYouSureContent,
  onTest,
  onClone,
  onInfo,
  onOpen,
  onClose,
  closeAreYouSureContent,
  onDownload,
  customMenuItems,
  onMenuClick,
  useDeleteConfirmationSafeguard
}: MoreMenuButtonProps) => {
  const menuRef = useRef(null);
  const isMountedRef = useIsMountedRef();
  const [open, setOpen] = useState(false);
  const [displayAreYouSureModal, setDisplayAreYouSureModal] = useState(false);
  const [areYouSureModalAction, setAreYouSureModalAction] = useState<() => void>(() => {});
  const [areYouSureModalTitle, setAreYouSureModalTitle] = useState('');
  const [areYouSureModalDescription, setAreYouSureModalDescription] = useState<
    string | JSX.Element
  >('');
  const [areYouSureModalType, setAreYouSureModalType] =
    useState<keyof typeof AreYouSureModalType>('Continue');

  const handleOpen = () => {
    setOpen(true);
    onMenuClick?.(true);
  };

  const handleClose = () => {
    setOpen(false);
    onMenuClick?.(false);
  };

  const onActionThatNeedsConfirmation = ({
    action,
    title,
    description,
    type
  }: {
    action: () => void;
    title: string;
    description: string | JSX.Element;
    type: keyof typeof AreYouSureModalType;
  }) => {
    setAreYouSureModalAction(() => action);
    setAreYouSureModalTitle(title);
    setAreYouSureModalDescription(description);
    setDisplayAreYouSureModal(true);
    setAreYouSureModalType(type);
  };

  const IconWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '1.5rem',
    height: '1.5rem'
  });

  const handleClick = (action: () => void) => () => {
    setOpen(false);
    action();
  };

  const actions = useMemo<Action[]>(() => {
    const arr: Action[] = [];
    if (onEdit) arr.push({ label: 'Edit', action: onEdit, icon: 'edit', access: onEditAccess });
    if (onDisplay) arr.push({ label: 'Display', action: onDisplay, icon: 'display' });
    if (onSendAgain)
      arr.push({
        label: 'Resend Survey',
        action: () =>
          onActionThatNeedsConfirmation({
            action: onSendAgain,
            title: 'Send again?',
            description: 'Do you want to resend the survey to the respondent?',
            type: 'Continue'
          }),
        icon: 'resendsurvey'
      });
    if (onMail) arr.push({ label: 'Send Email', action: onMail, icon: 'email' });
    if (onUnsubscribe)
      arr.push({
        label: 'Unsubscribe',
        action: () =>
          onActionThatNeedsConfirmation({
            action: onUnsubscribe,
            title: 'Remove from the list?',
            description: 'Do you want to remove the respondent from the list?',
            type: 'Continue'
          }),
        icon: 'unsubscribe'
      });
    if (onTest) arr.push({ label: `Test ${itemType}`, action: onTest, icon: 'beta' });
    if (onClone)
      arr.push({
        label: `Clone ${itemType}`,
        action: () =>
          onActionThatNeedsConfirmation({
            action: onClone,
            title: `Clone ${itemType}`,
            description: `Do you want to clone the ${itemType}?`,
            type: 'Continue'
          }),
        icon: 'copy'
      });
    if (onInfo)
      arr.push({ label: `${startWithUppercase(itemType)} Info`, action: onInfo, icon: 'question' });
    if (onOpen) arr.push({ label: `Open ${itemType}`, action: onOpen, icon: 'campaignactive' });
    if (onClose)
      arr.push({
        label: `Close ${itemType}`,
        action: () =>
          onActionThatNeedsConfirmation({
            action: onClose,
            title: `Close ${itemType}`,
            description: closeAreYouSureContent || `Do you want to close the ${itemType}?`,
            type: 'Continue'
          }),
        icon: 'campaignclosed'
      });
    if (onDelete)
      arr.push({
        label: `Delete ${itemType}`,
        action: () =>
          onActionThatNeedsConfirmation({
            action: onDelete,
            title: `Delete ${itemType}`,
            description: deleteAreYouSureContent || `Do you want to delete the ${itemType}?`,
            type: 'Delete'
          }),
        icon: 'delete',
        access: onDeleteAccess
      });
    if (onDownload)
      arr.push({ label: `Download ${itemType}`, action: onDownload, icon: 'download' });
    if (customMenuItems) {
      customMenuItems.forEach((customItem) => {
        arr.push({
          label: customItem.title,
          action: customItem.onClick,
          icon: customItem.icon // Assuming customItem.icon is the type of icon
        });
      });
    }
    return arr;
  }, [
    onEdit,
    onDisplay,
    onSendAgain,
    onMail,
    onUnsubscribe,
    onDelete,
    onTest,
    onClone,
    onInfo,
    onOpen,
    onClose,
    onDownload,
    customMenuItems,
    itemType,
    closeAreYouSureContent,
    deleteAreYouSureContent,
    onEditAccess,
    onDeleteAccess
  ]);

  const { action, icon, label } = actions?.[0];
  return (
    <>
      {actions.length === 1 && (
        <div onClick={(event) => event.stopPropagation()}>
          <Tooltip title={label}>
            <MIconButton size="large" onClick={action}>
              <Icon type={icon as IconType} />
            </MIconButton>
          </Tooltip>
        </div>
      )}
      {actions.length > 1 && (
        <div onClick={(event) => event.stopPropagation()}>
          <MIconButton ref={menuRef} size="large" onClick={handleOpen}>
            <Icon type="moremenu" />
          </MIconButton>
          <Menu
            open={open}
            anchorEl={menuRef.current}
            onClose={handleClose}
            PaperProps={
              {
                //sx: { width: 200 }
              }
            }
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            style={{ display: 'flex', flexWrap: 'wrap' }}
          >
            <div>
              {customMenuItems?.map((menuItem, index) => (
                <MenuItem key={index} onClick={menuItem.onClick}>
                  <IconWrapper>{menuItem.icon}</IconWrapper>

                  <Typography variant="body2" sx={{ ml: 2 }}>
                    {menuItem.title}
                  </Typography>
                </MenuItem>
              ))}

              {actions.map((action, index) => (
                <AccessGuard key={index} access={action.access}>
                  <MenuItem onClick={handleClick(action.action)}>
                    <IconWrapper>
                      {IconType[action.icon as keyof typeof IconType] ? (
                        <Icon type={action.icon as keyof typeof IconType} />
                      ) : (
                        action.icon
                      )}
                    </IconWrapper>
                    <Typography variant="body2" sx={{ ml: 2 }}>
                      {action.label}
                    </Typography>
                  </MenuItem>
                </AccessGuard>
              ))}
            </div>
          </Menu>
        </div>
      )}
      <AreYouSureModal
        useConfirmationSafeguard={
          useDeleteConfirmationSafeguard && areYouSureModalType === AreYouSureModalType.Delete
        }
        type={areYouSureModalType}
        display={displayAreYouSureModal}
        hideModal={() => {
          if (!isMountedRef.current) return;
          setDisplayAreYouSureModal(false);
        }}
        title={areYouSureModalTitle}
        description={areYouSureModalDescription}
        onContinue={areYouSureModalAction}
      />
    </>
  );
};

export default MoreMenuButton;
