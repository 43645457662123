export enum MergeFieldCategory {
  Respondent,
  Employee,
  Company,
  Link,
  Campaign,
  Styling,
  Organization,
  Other
}

export type MergeField = {
  name: string;
  value: string;
  category: MergeFieldCategory;
};

type EnumName<E> = `${Extract<keyof E, string>}`;
export type MergeFieldCategoryName = EnumName<typeof MergeFieldCategory>;
export type GroupedMergeFields = {
  [key in MergeFieldCategoryName]: Pick<MergeField, 'name' | 'value'>[];
};

type Loading = {
  [key: string]: boolean;
};

export type MergeFieldsState = {
  isLoading: Loading;
  mergeFields: MergeField[];
  groupedMergeFields: GroupedMergeFields | undefined;
};
