import { ReactPlugin, useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { AxiosResponse } from 'axios';
import useDisplayMessage, { DisplayMessage } from 'components/messages/useMessage';
import { useRef } from 'react';
import { dispatch } from './store';
import { getErrorMessageContent } from 'utils/errorMessageHandling';
import { getSuccessMessageContent } from 'utils/successMessageHandling';

export type CustomDispachActionPromise = Promise<
  CustomDispatchSuccessResponse | CustomDispatchErrorResponse
>;

export type CustomDispatchSuccessResponse = {
  result?: AxiosResponse<any>;
  extraSuccessInfo?: any;
  defaultSuccessMessage: string;
};
export type CustomDispatchErrorResponse = {
  error: any;
  defaultErrorMessage: string;
};

type MandatoryCustomDispatchProps = {
  actionParameters?: any;
  disableAllMessages?: boolean;
  disableSuccessMessage?: boolean;
  disableErrorMessage?: boolean;
  successMessage?: string;
  errorMessage?: string;
  onSuccess?: (successResponse: CustomDispatchSuccessResponse) => void;
  onError?: (errorResponse: CustomDispatchErrorResponse) => void;
  onFinally?: () => void;
};
export type CustomDispatchProps = {
  action?: (parameters?: any) => () => CustomDispachActionPromise;
} & MandatoryCustomDispatchProps;

const useCustomDispatch = () => {
  const displayMessage = useDisplayMessage();
  const appInsights = useAppInsightsContext();

  const customDispatch = useRef(async (props: CustomDispatchProps) => {
    handleCustomDispatch({
      ...props,
      displayMessage: displayMessage,
      appInsights: appInsights
    });
  }).current;

  return customDispatch;
};

export default useCustomDispatch;

export type CustomDispatchWithoutPromiseProps = {
  action?: (parameters?: any) => void;
} & MandatoryCustomDispatchProps;
export const useCustomDispatchWithoutPromise = () => {
  const displayMessage = useDisplayMessage();
  const appInsights = useAppInsightsContext();

  const customDispatchWithoutPromise = useRef(async (props: CustomDispatchWithoutPromiseProps) => {
    return handleCustomDispatch({
      ...props,
      displayMessage: displayMessage,
      appInsights: appInsights
    });
  }).current;

  return customDispatchWithoutPromise;
};

const handleCustomDispatch = async ({
  action,
  actionParameters,
  disableAllMessages,
  disableSuccessMessage,
  disableErrorMessage,
  successMessage,
  errorMessage,
  onSuccess,
  onError,
  onFinally,
  displayMessage,
  appInsights
}: (CustomDispatchProps | CustomDispatchWithoutPromiseProps) & {
  displayMessage: DisplayMessage;
  appInsights: ReactPlugin;
}) => {
  try {
    const successResponse = (await dispatch(
      action?.(actionParameters) || actionParameters
    )) as CustomDispatchSuccessResponse;

    if (successMessage) {
      successResponse.defaultSuccessMessage = successMessage;
    }

    if (!disableSuccessMessage && !disableAllMessages) {
      displayMessage.success({
        message: getSuccessMessageContent({
          successResponse: successResponse
        })
      });
    }

    onSuccess?.(successResponse);
  } catch (error: any) {
    const errorResponse = error as CustomDispatchErrorResponse;

    if (errorMessage) {
      errorResponse.defaultErrorMessage = errorMessage;
    }

    if (!disableErrorMessage && !disableAllMessages) {
      displayMessage.error({ message: getErrorMessageContent({ errorResponse: errorResponse }) });
    }

    //log error with appInsights
    const CRITICAL_ERROR_CONTENTS = ['Uncaught', 'ErrorEvent', '[object Object]'];
    const errorString = JSON.stringify(errorResponse);
    const isErrorCritical = CRITICAL_ERROR_CONTENTS.some((word) =>
      errorString.toLowerCase().includes(word.toLowerCase())
    );
    appInsights.trackException({
      exception: new Error(errorString),
      severityLevel: isErrorCritical ? SeverityLevel.Critical : SeverityLevel.Error
    });

    //custom callback
    onError?.(errorResponse);
  } finally {
    onFinally?.();
  }
};
