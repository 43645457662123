import { Box, Divider, ListItemIcon, MenuItem, Stack, Typography } from '@mui/material';
// material
import { alpha, useTheme } from '@mui/material/styles';
import useDisplayMessage from 'components/messages/useMessage';
import CustomButton from 'components/util/CustomButton';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useCustomDispatch from 'redux/dispatch';
// components
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/util/MenuPopover';
import MyAvatar from '../../components/util/MyAvatar';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { getOrganizationProfile, getOrganizations } from '../../redux/slices/organization';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import CurrentOrg from './CurrentOrg';
import { IconType } from '../../@types/icons';
import Icon from 'components/icons/Icon';
import { ListItemText } from '@mui/material';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Profile',
    icon: IconType.user,
    linkTo: PATH_DASHBOARD.user.profile
  },
  {
    label: 'Customize',
    icon: IconType.customize,
    linkTo: PATH_DASHBOARD.user.settings
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const theme = useTheme();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const isMountedRef = useIsMountedRef();
  const displayMessage = useDisplayMessage();
  const [open, setOpen] = useState(false);
  const customDispatch = useCustomDispatch();

  useEffect(() => {
    customDispatch({ action: getOrganizationProfile, disableAllMessages: true });
    customDispatch({ action: getOrganizations, disableSuccessMessage: true });
  }, [customDispatch]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout?.();
      if (isMountedRef.current) {
        navigate('/');
        handleClose();
      }
    } catch (error) {
      displayMessage.error({ message: { title: 'Unable to logout' } });
    }
  };

  return (
    <>
      <Stack direction={'column'} alignItems={'end'} justifyContent="space-between">
        <Typography color={theme.palette.text.primary} variant="subtitle1" noWrap>
          {user?.name ?? user?.email}
        </Typography>
        <CurrentOrg />
      </Stack>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box p={1} />
        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            {option?.icon && (
              <ListItemIcon>
                <Icon type={option.icon} />
              </ListItemIcon>
            )}
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}

        <Divider />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <CustomButton fullWidth color="error" variant="contained" onClick={handleLogout}>
            Logout
          </CustomButton>
        </Box>
      </MenuPopover>
    </>
  );
}
