import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import { createSlice } from '@reduxjs/toolkit';
import {
  getFilterQueryParam,
  getOrderbyQueryParam,
  getSkipQueryParam,
  getTopQueryParam
} from 'utils/getQueryParams';
import { PGapState } from '../../@types/pgap';
// utils
import axios from '../../utils/axios';
import { fDateHyphen } from '../../utils/formatTime';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: PGapState = {
  isLoading: false,
  PGap: { total: 0, results: [], skip: 0, take: 0 }
};

const slice = createSlice({
  name: 'pgap',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // GET PGAP
    getPGapSuccess(state, action) {
      state.isLoading = false;
      state.PGap = action.payload;
    },

    // DELETE PGAP
    deletePGapSuccess(state) {
      state.isLoading = false;
    },

    // UPDATE PGAP
    updatePGapSuccess(state) {
      state.isLoading = false;
    },

    // UPDATE PGAP
    addPGapSuccess(state, action) {
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { changeCampaign, dismissError } = slice.actions;

// ----------------------------------------------------------------------

export function getPGap({
  sortModel,
  filterModel,
  top,
  skip
}: {
  sortModel?: GridSortModel | undefined;
  filterModel?: GridFilterModel | undefined;
  top?: number;
  skip?: number;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let date = new Date();
      date.setMonth(date.getMonth() - 1);

      const response = await axios.get(
        `/odata/pgapperiods?$count=true${getOrderbyQueryParam(sortModel)}${getFilterQueryParam(
          filterModel
        )}${getSkipQueryParam(skip)}${getTopQueryParam(top)}&$expand=campaign,employee`
      );
      dispatch(
        slice.actions.getPGapSuccess({
          results: response.data.value,
          total: response.data['@odata.count']
        })
      );
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched P-Gap'
      });
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch P-Gap'
      });
    }
  };
}

export function deletePGap(ids: number[]) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete('/employees/pgapperiod', {
        data: ids
      });
      dispatch(slice.actions.deletePGapSuccess());
      return await Promise.resolve({
        defaultSuccessMessage: `Removed ${ids.length} P-Gap period${ids.length > 1 ? 's' : ''}`
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not delete P-Gap periods'
      });
    }
  };
}

type UpdatePGapProps = {
  ids: number[];
  startDate?: Date;
  endDate?: Date;
  campaignId?: number;
};
export function updatePGap({ ids, startDate, endDate, campaignId }: UpdatePGapProps) {
  dispatch(slice.actions.startLoading());
  let updatedPGap: {
    id: number;
    startDate: string | undefined;
    endDate: string | undefined;
    campaignId: number | undefined;
  }[] = [];

  let newStartDate;
  let newEndDate;

  if (startDate) newStartDate = fDateHyphen(startDate);
  if (endDate) newEndDate = fDateHyphen(endDate);

  for (let id of ids) {
    updatedPGap.push({
      id: id,
      startDate: newStartDate,
      endDate: newEndDate,
      campaignId: campaignId
    });
  }

  return async () => {
    try {
      await axios.put('/employees/pgapperiod', updatedPGap);
      dispatch(slice.actions.updatePGapSuccess());
      return await Promise.resolve({
        defaultSuccessMessage: `Edited ${ids.length} P-Gap period${ids.length > 1 ? 's' : ''}`
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not update P-Gap periods'
      });
    }
  };
}

/***
 * Adds pgap period to all campaigns and employees
 * @param employeeIds Array with employee ids
 * @param startDate Date with start date of period
 * @param endDate Date with end date of period
 * @param campaignIds Array with campaign ids
 */
type AddPGapProps = {
  employeeIds: number[];
  startDate: Date;
  endDate: Date;
  campaignIds: number[];
};

export function addPGap({ employeeIds, startDate, endDate, campaignIds }: AddPGapProps) {
  let addPGap: {
    employeeId: number;
    startDate: string;
    endDate: string;
    campaignId: number;
  }[] = [];

  let newStartDate = fDateHyphen(startDate);
  let newEndDate = fDateHyphen(endDate);

  for (let campaignId of campaignIds) {
    for (let employeeId of employeeIds) {
      addPGap.push({
        employeeId: employeeId,
        startDate: newStartDate,
        endDate: newEndDate,
        campaignId: campaignId
      });
    }
  }

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/employees/pgapperiod', addPGap);
      dispatch(slice.actions.addPGapSuccess(response.data));

      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'P-Gap training session added'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not add P-Gap training session`
      });
    }
  };
}
