import { Grid, Stack, Tooltip } from '@mui/material';
import Icon from 'components/icons/Icon';
import { CustomLoadingButton } from 'components/util/CustomButton';
import DisabledIcon from 'components/util/Status/DisabledIcon';
import EnabledIcon from 'components/util/Status/EnabledIcon';
import useCustomDispatch from 'redux/dispatch';
import { resendUserInvite } from 'redux/slices/organization';
import { RootState, useSelector } from 'redux/store';

type EmailConfirmedProps = {
  userId: string;
  userEmail: string;
  emailConfirmed: boolean;
  center?: boolean;
};
const EmailConfirmed = ({
  userId,
  userEmail,
  emailConfirmed,
  center = true
}: EmailConfirmedProps) => {
  const customDispatch = useCustomDispatch();
  const { loading } = useSelector((state: RootState) => state.organization);
  return (
    <Stack alignItems={center ? 'center' : ''} sx={{ width: 1, textAlign: 'center' }}>
      {emailConfirmed ? (
        <EnabledIcon tooltip="" />
      ) : (
        <>
          {center ? (
            <Grid container>
              <Grid item xs={4} />
              <Grid
                item
                xs={4}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <DisabledIcon tooltip="" />
              </Grid>
              <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                <Tooltip title="Resend invite">
                  <div>
                    <CustomLoadingButton
                      loading={loading?.[`userInvite${userId}`]}
                      startIcon={<Icon type="resendsurvey" />}
                      color="primary"
                      onClick={async (event) => {
                        event.stopPropagation();
                        customDispatch({
                          action: resendUserInvite,
                          actionParameters: { userId: userId, userEmail: userEmail }
                        });
                      }}
                    />
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DisabledIcon tooltip="" />
              <Tooltip title="Resend invite">
                <div>
                  <CustomLoadingButton
                    loading={loading?.[`userInvite${userId}`]}
                    startIcon={<Icon type="resendsurvey" />}
                    color="primary"
                    onClick={async (event) => {
                      event.stopPropagation();
                      customDispatch({
                        action: resendUserInvite,
                        actionParameters: { userId: userId, userEmail: userEmail }
                      });
                    }}
                  />
                </div>
              </Tooltip>
            </div>
          )}
        </>
      )}
    </Stack>
  );
};

export default EmailConfirmed;
