import { useEffect, useState, useRef } from 'react';

type usePreventSpammingProps = {
  msDelay: number;
};
const usePreventSpamming = ({ msDelay }: usePreventSpammingProps) => {
  const [isClickAllowed, setIsClickAllowed] = useState(true);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Clear the timeout when the component unmounts
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const handleDisableOnClick = () => {
    //if no delay, return
    if (msDelay <= 0) {
      return;
    }
    setIsClickAllowed(false);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      setIsClickAllowed(true);
    }, msDelay);
  };

  return { isClickAllowed, handleDisableOnClick };
};

export default usePreventSpamming;
