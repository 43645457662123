import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';
import { BaseOrder, OrderDataState } from '../../@types/order';
import { IntegrationType } from '../../@types/integrations';

// ----------------------------------------------------------------------

const initialState: OrderDataState = {
  isLoading: false,
  isDeleteLoading: false,
  orderData: { count: 0, orders: [] },
  isOrderLoading: false,
  order: undefined,
  isIntegrationEnabled: {}
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // General use
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    startDeleteLoading(state) {
      state.isDeleteLoading = true;
    },

    stopDeleteLoading(state) {
      state.isDeleteLoading = false;
    },

    addOrderSuccess(state, action) {
      state.isLoading = false;
    },

    startGetOrder(state) {
      state.isOrderLoading = true;
    },

    stopIsOrderLoading(state) {
      state.isOrderLoading = false;
    },

    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orderData.count = action.payload.count;
      state.orderData.orders = action.payload;
    },

    editOrderSuccess(state, action) {
      const newOrder = JSON.parse(action.payload.config.data);
      state.orderData.orders = state.orderData.orders.map((o) =>
        newOrder.id === o.id ? newOrder : o
      );
      state.isLoading = false;
    },
    setOrder(state, action) {
      state.order = action.payload;
      state.isOrderLoading = false;
    },
    resetOrders(state) {
      state.orderData.count = 0;
      state.orderData.orders = [];
    },
    updateIsIntegrationEnabled(state, action) {
      const integrationType = action.payload.integration as IntegrationType;
      const isEnabled = action.payload.enabled as boolean;
      state.isIntegrationEnabled[integrationType] = isEnabled;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setOrder } = slice.actions;

// ----------------------------------------------------------------------

type GetIsIntegrationActiveProps = {
  integration: IntegrationType;
};
export const getIsIntegrationActive = ({ integration }: GetIsIntegrationActiveProps) => {
  return async () => {
    try {
      const response = await axios.get(`/orders/product-orders/${integration}/enabled`);

      const isEnabled = response?.data;

      dispatch(slice.actions.updateIsIntegrationEnabled({ integration, enabled: isEnabled }));
      return await Promise.resolve({
        defaultSuccessMessage: `Integration ${IntegrationType[integration]} is ${
          isEnabled ? 'enabled' : 'disabled'
        }`
      });
    } catch (error) {
      dispatch(slice.actions.updateIsIntegrationEnabled({ integration, enabled: false }));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch if integration ${IntegrationType[integration]} is enabled or disabled`
      });
    }
  };
};

export function resetOrders() {
  dispatch(slice.actions.resetOrders());
}

type OrderEndpoint = 'survey-orders' | 'product-orders' | 'billable-hours-orders';

type GetOrdersProps = {
  endpoint: OrderEndpoint;
};
export function getOrders({ endpoint }: GetOrdersProps) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/orders/${endpoint}`);
      dispatch(slice.actions.getOrdersSuccess(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Orders fetched'
      });
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch orders'
      });
    }
  };
}

type EditOrderProps = {
  endpoint: OrderEndpoint;
  order: BaseOrder;
};
export function editOrder({ endpoint, order }: EditOrderProps) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      const response = await axios.put(`/orders/${endpoint}`, order);
      dispatch(slice.actions.editOrderSuccess(response));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Order updated'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not update order'
      });
    }
  };
}

type DeleteOrderProps = {
  endpoint: OrderEndpoint;
  id: string;
};
export function deleteOrder({ endpoint, id }: DeleteOrderProps) {
  return async () => {
    dispatch(slice.actions.startDeleteLoading());
    try {
      await axios.delete(`/orders/${endpoint}/` + id);
      dispatch(slice.actions.stopDeleteLoading());

      return await Promise.resolve({
        defaultSuccessMessage: `Deleted order with id ${id}`
      });
    } catch (error: any) {
      dispatch(slice.actions.stopDeleteLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not delete order'
      });
    }
  };
}

type AddOrderProps = {
  endpoint: OrderEndpoint;
  order: BaseOrder;
};
export function addOrder({ order, endpoint }: AddOrderProps) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      const response = await axios.post(`orders/${endpoint}`, order);
      dispatch(slice.actions.addOrderSuccess(response));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Order added'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not add order'
      });
    }
  };
}

type GetOrderProps = {
  id: string;
  endpoint: OrderEndpoint;
};
export const getOrder = ({ id, endpoint }: GetOrderProps) => {
  return async () => {
    dispatch(slice.actions.startGetOrder());
    try {
      const response = await axios.get(`/orders/${endpoint}/${id}`);

      dispatch(slice.actions.setOrder(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Order fetched'
      });
    } catch (error) {
      dispatch(slice.actions.stopIsOrderLoading());
      dispatch(slice.actions.setOrder(undefined));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch order'
      });
    }
  };
};
