import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { Access } from '../../../@types/claims';
import { getClaimsFromAccessType, useHasAccess } from 'guards/ClaimBasedGuard';
import CustomButton, { CustomLoadingButton } from 'components/util/CustomButton';
import { ProviderType } from '../../../@types/providers';
import { firstLower } from 'components/util/stringFormat';
import ContactNpsForHelp from 'components/addEditContentText/ContactNpsForHelp';
import useCustomDispatch from 'redux/dispatch';
import { changeProvider } from 'redux/slices/providers';
import { RootState, useSelector } from 'redux/store';

export type ProviderChange = {
  currentName?: string;
  newName: string;
  providerType: ProviderType;
};
type ChangeProviderDialogProps = {
  providerChange?: ProviderChange;
  isOpen: boolean;
  closeDialog: () => void;
};
const ChangeProviderDialog = ({
  providerChange,
  isOpen,
  closeDialog
}: ChangeProviderDialogProps) => {
  const customDispatch = useCustomDispatch();
  const allowedToEditProviders = Boolean(
    useHasAccess(getClaimsFromAccessType(Access.ACTION_EDIT_PROVIDERS))
  );
  const { isLoading } = useSelector((state: RootState) => state.providers);

  const providerNameAndType = `${providerChange?.providerType} provider`;

  const title = `Change ${providerNameAndType}`;
  const requestDescription = `Change the ${providerNameAndType} ${
    (providerChange?.currentName?.length ? `from ` : '') + providerChange?.currentName
  } to ${providerChange?.newName}`;
  const description = allowedToEditProviders ? (
    `Are you sure you want to ${firstLower(requestDescription)}.`
  ) : (
    <ContactNpsForHelp subject={requestDescription} />
  );

  const onClose = () => {
    closeDialog();
  };

  const onChange = () => {
    customDispatch({
      action: changeProvider,
      actionParameters: {
        providerType: providerChange?.providerType,
        newProviderName: providerChange?.newName
      },
      onSuccess: () => {
        onClose();
      }
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} disableRestoreFocus>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {allowedToEditProviders && (
          <CustomLoadingButton
            loading={isLoading.changeProvider}
            color="primary"
            variant="contained"
            onClick={onChange}
          >
            Continue
          </CustomLoadingButton>
        )}
        <CustomButton color="primary" onClick={onClose}>
          {allowedToEditProviders ? 'Cancel' : 'Close'}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeProviderDialog;
