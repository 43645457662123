import { TextField } from '@mui/material';
import { RootState, useSelector } from 'redux/store';
import useCustomDispatch from 'redux/dispatch';

import {
  addThrottlingGroup,
  deleteThrottlingGroup,
  editThrottlingGroup,
  getThrottlingGroups
} from 'redux/slices/throttlingGroup';
import { ThrottlingGroup } from '../../../@types/throttlingGroup';
import { FormikProvider, getIn, useFormik } from 'formik';
import * as Yup from 'yup';
import EditItemDialog from 'components/util/EditItemDialog';

const AddThrottlingGroupDialog = ({
  isOpen,
  close,
  fetchThrottlingGroupsOnSuccess,
  throttlingGroupToEdit
}: {
  isOpen: boolean;
  close: () => void;
  fetchThrottlingGroupsOnSuccess?: boolean;
  throttlingGroupToEdit?: ThrottlingGroup;
}) => {
  const customDispatch = useCustomDispatch();
  const { isLoading } = useSelector((state: RootState) => state.throttlingGroup);
  const isThrottlingGroupEditable =
    !Boolean(throttlingGroupToEdit) || Boolean(throttlingGroupToEdit?.id);

  const ThrottlingGroupSchema = Yup.object().shape({
    name: Yup.string().required('Name is required')
  });

  const onClose = () => {
    resetForm();
    close();
  };

  const formik = useFormik<ThrottlingGroup>({
    enableReinitialize: true,
    initialValues: {
      id: throttlingGroupToEdit?.id,
      name: throttlingGroupToEdit?.name ?? '',
      campaignIds: throttlingGroupToEdit?.campaignIds ?? []
    },
    validationSchema: ThrottlingGroupSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const onSuccess = () => {
        if (fetchThrottlingGroupsOnSuccess) {
          customDispatch({ action: getThrottlingGroups, disableSuccessMessage: true });
        }

        onClose();
      };
      const onFinally = () => {
        setSubmitting(false);
      };

      if (throttlingGroupToEdit) {
        customDispatch({
          action: editThrottlingGroup,
          actionParameters: { updatedThrottlingGroup: { ...throttlingGroupToEdit, ...values } },
          disableSuccessMessage: true,
          onSuccess,
          onFinally
        });
      } else {
        customDispatch({
          action: addThrottlingGroup,
          actionParameters: { ...values },
          disableSuccessMessage: true,
          onSuccess,
          onFinally
        });
      }
    }
  });

  const { submitForm, resetForm, dirty, isSubmitting, getFieldProps, errors, touched } = formik;

  const onDelete = () => {
    if (!throttlingGroupToEdit?.id) return;
    customDispatch({
      action: deleteThrottlingGroup,
      actionParameters: { id: throttlingGroupToEdit.id },
      disableSuccessMessage: true,
      onSuccess: () => {
        if (fetchThrottlingGroupsOnSuccess) {
          customDispatch({ action: getThrottlingGroups, disableSuccessMessage: true });
        }

        onClose();
      }
    });
  };

  return (
    <div>
      <FormikProvider value={formik}>
        <div>
          <EditItemDialog
            itemType="throttling group"
            editable={isThrottlingGroupEditable}
            isOpen={isOpen}
            title={`${throttlingGroupToEdit ? 'Edit' : 'Add new'} throttling group`}
            editContent={[
              <>
                <TextField
                  disabled={!isThrottlingGroupEditable}
                  autoFocus
                  {...getFieldProps('name')}
                  label="Name"
                  fullWidth
                  error={Boolean(getIn(errors, 'name') && getIn(touched, 'name'))}
                  helperText={
                    !isThrottlingGroupEditable
                      ? `The ${throttlingGroupToEdit?.name} throttling group is not editable`
                      : getIn(touched, 'name') && getIn(errors, 'name')
                  }
                />
              </>
            ]}
            dirty={dirty}
            isSubmitting={isSubmitting}
            close={onClose}
            resetForm={resetForm}
            submitForm={submitForm}
            delete={
              (throttlingGroupToEdit &&
                throttlingGroupToEdit.campaignIds.length <= 0 &&
                onDelete) ||
              undefined
            }
            isLoading={isLoading.addThrottlingGroup || isLoading.editThrottlingGroup}
            isDeleteLoading={isLoading.deleteThrottlingGroup}
          />
        </div>
      </FormikProvider>
    </div>
  );
};

export default AddThrottlingGroupDialog;
