import { Autocomplete, TextField } from '@mui/material';
import EditItemDialog from 'components/util/EditItemDialog';
import { Form, FormikProvider, getIn, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { CampaignSummary } from '../../../@types/campaign';
import { IvrMap } from '../../../@types/organization';
import { addIvrMap, getIvrMap, getIvrMaps, updateIvrMap } from '../../../redux/slices/organization';
import { RootState, useSelector } from '../../../redux/store';
import RjsfForm from '../../../components/rjsf/RjsfForm';
import { IVRType } from '../../../@types/ivr';
import { setIvrToTest } from 'redux/slices/ivr';
import useCustomDispatch, { useCustomDispatchWithoutPromise } from 'redux/dispatch';
import CustomButton from 'components/util/CustomButton';
import { useRegisterUnsavedFormChanges } from 'components/form/useRegisterUnsavedFormChanges';
import { getIvrTestUrl } from '../util/getIvrTestUrl';

export type IVRDialogProps = {
  campaigns: CampaignSummary[];
  open: boolean;
  onClose: any;
  ivrIdToEdit: number | null;
  onDelete: () => void;
};

export default function IVRDialog({
  open,
  onClose,
  campaigns,
  ivrIdToEdit,
  onDelete
}: IVRDialogProps) {
  const customDispatch = useCustomDispatch();
  const customDispatchWithoutPromise = useCustomDispatchWithoutPromise();
  const [rjsfSubmitCount, setRjsfSubmitCount] = useState(0);
  const { ivrTypes } = useSelector((state: RootState) => state.ivr);
  const { loading, ivrMap } = useSelector((state: RootState) => state.organization);
  const isLoading = loading.ivrMap;

  useEffect(() => {
    customDispatch({
      action: getIvrMap,
      actionParameters: ivrIdToEdit,
      disableSuccessMessage: true
    });
  }, [customDispatch, ivrIdToEdit]);

  const validationShape = {
    name: Yup.string().required('Name is required'),
    campaignId: Yup.number().required('Campaign is required'),
    ivrid: Yup.number().required('IVR is required'),
    isQueueIdRequired: Yup.boolean(),
    queueId: Yup.string().when('isQueueIdRequired', {
      is: true,
      then: Yup.string().required('Queue Id is required')
    })
  };

  const Schema = Yup.object().shape(validationShape);

  const getEditedIvrObject = (formValues: any) => {
    const ivr = { ...formValues };
    if (rjsfFormData) {
      ivr.properties = rjsfFormData;
    }
    return ivr;
  };

  const formik = useFormik<Partial<IvrMap>>({
    enableReinitialize: true,
    initialValues: {
      id: ivrMap?.id,
      name: ivrMap?.name ?? '',
      campaignId: ivrMap?.campaign?.id ?? null,
      queueId: ivrMap?.queueId ?? '',
      ivrid: ivrMap?.provider?.id ?? null
    },
    validationSchema: Schema,
    onSubmit: async (values, { setSubmitting }) => {
      const ivr = getEditedIvrObject(values);
      if (ivrIdToEdit) {
        customDispatch({
          action: updateIvrMap,
          actionParameters: ivr,
          onSuccess: () => {
            resetForm();
            closeDialog();
            customDispatch({ action: getIvrMaps, disableSuccessMessage: true });
          },
          onFinally: () => {
            setSubmitting(false);
          }
        });
      } else {
        customDispatch({
          action: addIvrMap,
          actionParameters: ivr,
          onSuccess: () => {
            resetForm();
            closeDialog();
            customDispatch({ action: getIvrMaps, disableSuccessMessage: true });
          },
          onFinally: () => {
            setSubmitting(false);
          }
        });
      }
    }
  });
  const {
    values,
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
    resetForm,
    submitForm
  } = formik;

  const onSubmit = () => {
    setRjsfSubmitCount((prevState: number) => prevState + 1);
    return Promise.resolve();
  };

  const [rjsfFormData, setRjsfFormData] = useState<any>(ivrMap?.properties);

  useEffect(() => {
    setRjsfFormData(ivrMap?.properties);
  }, [ivrMap?.properties]);

  useEffect(() => {
    if (!ivrMap) return;
    if (!values.ivrid) return;
    if (values.ivrid !== ivrMap?.provider?.id) {
      setRjsfFormData({});
      return;
    }
  }, [values.ivrid, ivrMap]);

  useEffect(() => {
    if (ivrIdToEdit) return;
    if (!values.ivrid) return;
    if (values.ivrid !== ivrMap?.provider?.id) {
      setRjsfFormData({});
      return;
    }
  }, [values.ivrid, ivrIdToEdit, ivrMap?.provider?.id]);

  const closeDialog = () => {
    onClose();
    setRjsfFormData({});
    setRjsfSubmitCount(0);
    resetForm();
  };

  const rjsfSchema = ivrTypes?.find((ivrType: IVRType) => ivrType?.id === values.ivrid)?.properties;
  const isRjsfDirty =
    rjsfSchema && JSON.stringify(ivrMap?.properties) !== JSON.stringify(rjsfFormData);

  //queueId is required, if the IVR provider does not have any properties
  useEffect(() => {
    setFieldValue('isQueueIdRequired', !Boolean(rjsfSchema));
  }, [values.ivrid, setFieldValue, rjsfSchema]);

  useEffect(() => {
    //first render
    if (rjsfSubmitCount <= 0) return;
    //if no schema avil. submit form without rjsf data
    if (!rjsfSchema) {
      submitForm();
    }
  }, [rjsfSubmitCount, submitForm, rjsfSchema]);

  const isFormDirty = () => {
    const currrentValues = values;

    let isFormDirty = false;

    //stanard fields
    //if new IVR
    if (!ivrMap) {
      isFormDirty = Boolean(
        currrentValues.campaignId ||
          currrentValues.ivrid ||
          currrentValues.name ||
          currrentValues.queueId
      );
    } else {
      const initialValues = {
        campaignId: ivrMap?.campaign?.id,
        id: ivrMap?.id,
        ivrid: ivrMap?.provider?.id,
        name: ivrMap?.name,
        queueId: ivrMap?.queueId
      };
      isFormDirty =
        currrentValues.campaignId !== initialValues.campaignId ||
        currrentValues.ivrid !== initialValues.ivrid ||
        currrentValues.name !== initialValues.name ||
        currrentValues.queueId !== initialValues.queueId;
    }

    //React Json Schema Fields
    return Boolean(isFormDirty || isRjsfDirty);
  };

  useRegisterUnsavedFormChanges(isFormDirty());

  const onClickTest = () => {
    const ivrToTest = getEditedIvrObject(values);

    //the test endpoint only needs the properties, and the ivrId, wrapped as below
    customDispatchWithoutPromise({
      action: setIvrToTest,
      actionParameters: {
        provider: { id: ivrToTest?.ivrid },
        properties: { ...ivrToTest.properties }
      },
      disableSuccessMessage: true
    });
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <EditItemDialog
            itemType="IVR connection"
            isLoading={isLoading}
            extraMenuButtons={[
              <div key={0}>
                {getIvrTestUrl({ ivrId: Number(values.ivrid), ivrTypes }) && (
                  <CustomButton onClick={onClickTest}>Test</CustomButton>
                )}
              </div>
            ]}
            editable
            isOpen={open}
            title={ivrIdToEdit ? 'Update IVR' : 'Add IVR'}
            editContent={[
              <TextField
                key={1}
                fullWidth
                label="Name"
                {...getFieldProps('name')}
                error={Boolean(getIn(errors, 'name') && getIn(touched, 'name'))}
                helperText={getIn(touched, 'name') && getIn(errors, 'name')}
              />,
              <Autocomplete
                key={2}
                {...getFieldProps('ivrid')}
                onChange={(_, val) => {
                  setFieldValue('ivrid', val?.id ?? '');
                }}
                getOptionLabel={(ivr: any) =>
                  ivrTypes?.find((x) => x.id === ivr || x.id === ivr.id)?.name ?? ''
                }
                isOptionEqualToValue={(option, value) =>
                  option.id === value || option.id === value?.id
                }
                options={ivrTypes}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="IVR Provider"
                    error={Boolean(getIn(errors, 'ivrid') && getIn(touched, 'ivrid'))}
                    helperText={getIn(touched, 'ivrid') && getIn(errors, 'ivrid')}
                  />
                )}
              />,
              <Autocomplete
                key={3}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                {...getFieldProps('campaignId')}
                onChange={(_, val) => {
                  setFieldValue('campaignId', val?.id ?? '');
                }}
                getOptionLabel={(ivr: any) =>
                  campaigns.find((x) => x.id === ivr || x.id === ivr.id)?.name ?? ''
                }
                isOptionEqualToValue={(option, value) =>
                  option.id === value || option.id === value?.id
                }
                options={campaigns}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Campaign"
                    error={Boolean(getIn(errors, 'campaignId') && getIn(touched, 'campaignId'))}
                    helperText={getIn(touched, 'campaignId') && getIn(errors, 'campaignId')}
                  />
                )}
              />,
              <TextField
                key={4}
                fullWidth
                label="Queue Id"
                {...getFieldProps('queueId')}
                error={Boolean(getIn(errors, 'queueId') && getIn(touched, 'queueId'))}
                helperText={getIn(touched, 'queueId') && getIn(errors, 'queueId')}
              />,
              <div key={5}>
                <RjsfForm
                  formData={rjsfFormData}
                  setRjsfFormData={setRjsfFormData}
                  submitCount={rjsfSubmitCount}
                  schema={rjsfSchema}
                />
              </div>
            ]}
            displayContent={[]}
            dirty={isFormDirty()}
            isSubmitting={isSubmitting}
            //delete={ivrIdToEdit ? onDelete : undefined} TODO fix
            close={closeDialog}
            resetForm={resetForm}
            submitForm={onSubmit}
          />
        </Form>
      </FormikProvider>
    </>
  );
}
