import { WritableDraft } from 'immer/dist/internal';
import { Campaign } from './campaign';
import { Employee } from './employee';
import { History } from './history';
import { Note } from './note';
import { Respondent } from './respondent';
import { Response } from './response';

export enum CampaignMemberCategory {
  RESPONSES = 'responses',
  DELIVERED = 'delivered',
  SCHEDULED = 'scheduled',
  UNSUBSCRIBED = 'unsubscribed',
  FAILED = 'failed'
}

export const campaignMemberCategoryaAditionStatusMap = new Map([
  [CampaignMemberCategory.RESPONSES, { min: 2500, max: 2999 }],
  [CampaignMemberCategory.DELIVERED, { min: 2000, max: 2499 }],
  [CampaignMemberCategory.SCHEDULED, { max: 999 }],
  [CampaignMemberCategory.UNSUBSCRIBED, { min: 3000, max: 3999 }],
  [CampaignMemberCategory.FAILED, { min: 4000 }]
]);

export type CampaignMembersData = {
  total: number;
  skip: number;
  take: number;
  campaignMembers: CampaignMember[];
};

export type CampaignMemberState = {
  isLoading: {
    [key: string]: boolean;
  };
  campaignMember: CampaignMember | undefined;
  notes: Note[];
  history: History[];
  campaignMembersData: WritableDraft<{
    [key in CampaignMemberCategory]: CampaignMembersData;
  }>;
  updateCampaignMembers: number;
};

export type CampaignMember = {
  id: number;
  publicGuid: string;
  note: null;
  noteBy: null;
  respondentId: number;
  campaignId: number;
  responsibleEmployeeId: number;
  doNotContact: boolean;
  sender: number;
  firstName: string;
  lastName: null;
  title: null;
  department: null;
  address1: null;
  address2: null;
  city: null;
  country: null;
  zipCode: null;
  phoneNumber: null;
  externalId: null;
  emailAddress: string;
  companyId: null;
  companyName: null;
  companyIndustry: null;
  companyAccountNo: null;
  companySegment: null;
  companyEmailAddress: null;
  companyPhoneNumber: null;
  companyAddress1: null;
  companyAddress2: null;
  companyCity: null;
  companyCountry: null;
  companyZipCode: null;
  created: Date;
  custom: null | string;
  agentRating: null;
  agentAnswers: null;
  additionStatus: number;
  firstSurveyExposure: Date;
  orgId: string;
  lastChangeBy: string;
  anonymous: boolean;
  response?: Response;
  responsibleEmployee?: Employee;
  respondent?: Respondent;
  campaign: Campaign;
  emails: Email[];
  sms: Sms[];

  /* Not part of original object */
  surveySendTime?: Date;
  surveyScheduledTime?: Date;
  reminderSendTime?: Date;
  reminderScheduledTime?: Date;
};

export type Email = {
  emailStatus: string;
  emailType: string;
  scheduledTime?: Date;
  sendTime?: Date;
};

export type Sms = {
  smsStatus: string;
  smsType: string;
  scheduledTime?: Date;
  sendTime?: Date;
};
