import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from 'redux/store';
import { Seat, SeatsState } from '../../@types/seats';

// ----------------------------------------------------------------------
const initialState: SeatsState = {
  isLoading: false,
  hasError: false,
  seats: []
};

const slice = createSlice({
  name: 'seats',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setSeats(state, action) {
      state.seats = action.payload;
      state.isLoading = false;
      state.hasError = false;
    },
    setError(state) {
      state.hasError = true;
      state.isLoading = false;
      state.seats = [];
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
//export const {} = slice.actions;

// ----------------------------------------------------------------------

export const getSeats = () => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/organization/seats');
      dispatch(
        slice.actions.setSeats(response.data?.filter((seat: Seat) => seat.total !== null) ?? [])
      );

      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Seats fetched'
      });
    } catch (error: any) {
      dispatch(slice.actions.setError());

      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch seats'
      });
    }
  };
};
