import { DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { Dialog } from '@mui/material';
import { Box } from '@mui/system';
import { TimeSpan } from '../../@types/dashboard';
import { AxiosResponse } from 'axios';
import CustomButton from 'components/util/CustomButton';
import DisabledIcon from 'components/util/Status/DisabledIcon';
import EnabledIcon from 'components/util/Status/EnabledIcon';
import UpdateButton from 'components/util/UpdateButton';
import { useCallback, useRef, useState } from 'react';
import useCustomDispatch, {
  CustomDispatchErrorResponse,
  CustomDispatchProps,
  CustomDispatchSuccessResponse,
  CustomDispatchWithoutPromiseProps,
  useCustomDispatchWithoutPromise
} from 'redux/dispatch';
import FromToDatePicker, {
  getFromToFilterFromTimespan
} from 'components/util/datePicker/FromToDatePicker';
import { useTheme } from '@mui/material';

export type TimespanParams = {
  initialTimespan: TimeSpan;
  maxTimeSpan?: TimeSpan;
};

export type ExclusiveDispatchProps =
  | { dispatchProps: CustomDispatchProps; dispatchPropsWithoutPromise?: never }
  | { dispatchProps?: never; dispatchPropsWithoutPromise: CustomDispatchWithoutPromiseProps };

type TestEndpointDialogProps = {
  isOpen: boolean;
  close: () => void;
  title: string;
  timespanParams?: TimespanParams;
  onExited?: () => void;
} & ExclusiveDispatchProps;
const TestEndpointDialog = ({
  isOpen,
  close,
  title,
  dispatchProps,
  dispatchPropsWithoutPromise,
  timespanParams,
  onExited
}: TestEndpointDialogProps) => {
  const theme = useTheme();
  const customDispatch = useCustomDispatch();
  const customDispatchWithoutPromise = useCustomDispatchWithoutPromise();

  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<AxiosResponse<any> | undefined | any>(undefined);

  const initialTimespan = timespanParams?.initialTimespan ?? TimeSpan.TODAY;
  const { from: initialFrom, to: initialTo } = getFromToFilterFromTimespan(initialTimespan);
  const [timespan, setTimespan] = useState<TimeSpan>(initialTimespan);
  const [fromDateFilter, setFromDateFilter] = useState(initialFrom?.getTime?.());
  const fromDateFilterRef = useRef(fromDateFilter);
  const [toDateFilter, setToDateFilter] = useState(initialTo?.getTime?.());
  const toDateFilterRef = useRef(toDateFilter);

  const updateDateFilter =
    ({ from, to, timespan }: { from: any; to: any; timespan: TimeSpan }) =>
    () => {
      setTimespan(timespan);
      const updatedFrom = from?.getTime?.() ?? from;
      const updatedTo = to?.getTime?.() ?? to;
      fromDateFilterRef.current = updatedFrom;
      toDateFilterRef.current = updatedTo;
      setFromDateFilter(updatedFrom);
      setToDateFilter(updatedTo);

      onCallEndpoint();

      return Promise.resolve({
        defaultSuccessMessage: 'Timespan updated'
      });
    };

  const onClose = () => {
    close();
  };

  const onSuccess = (successResponse: CustomDispatchSuccessResponse | undefined) =>
    setResponse(successResponse?.result ?? successResponse);
  const onError = (errorResponse: CustomDispatchErrorResponse | undefined) => {
    setResponse(
      errorResponse?.error?.response ??
        errorResponse?.error ??
        errorResponse?.error ??
        errorResponse
    );
  };
  const onFinally = () => setIsLoading(false);
  const onCallEndpoint = useCallback(() => {
    if (!isOpen) return;

    setIsLoading(true);

    if (dispatchProps) {
      customDispatch({
        action: dispatchProps.action,
        actionParameters: {
          ...dispatchProps.actionParameters,
          from: fromDateFilterRef.current,
          to: toDateFilterRef.current
        },
        disableAllMessages: true,
        onSuccess,
        onError,
        onFinally
      });
    } else {
      customDispatchWithoutPromise({
        action: dispatchPropsWithoutPromise.action,
        actionParameters: {
          ...dispatchPropsWithoutPromise.actionParameters,
          from: fromDateFilterRef.current,
          to: toDateFilterRef.current
        },
        disableAllMessages: true,
        onSuccess,
        onError,
        onFinally
      });
    }
  }, [
    customDispatch,
    customDispatchWithoutPromise,
    dispatchProps,
    isOpen,
    dispatchPropsWithoutPromise
  ]);

  const isSuccess = response?.status && response?.status >= 200 && response?.status < 300;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      TransitionProps={{ onExited: onExited }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        display="flex"
        style={{ flexWrap: 'wrap' }}
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogActions style={{ padding: '1rem', paddingBottom: 0 }}>
          <UpdateButton
            onClickUpdate={onCallEndpoint}
            isLoading={isLoading}
            lastUpdated={undefined}
          />
        </DialogActions>
      </Stack>

      {timespanParams && (
        <Box
          sx={{
            margin: '1rem',
            display: 'flex',
            height: 'min-content',
            backgroundColor: theme.palette.background.paper,
            zIndex: 2
          }}
        >
          <FromToDatePicker
            timespan={timespan}
            toDateFilter={toDateFilter}
            fromDateFilter={fromDateFilter}
            updateDateFilter={updateDateFilter}
            maxTimeSpan={timespanParams.maxTimeSpan ?? TimeSpan.LAST_MONTH}
          />
        </Box>
      )}
      <DialogContent style={{ height: '100vh', paddingTop: 0 }}>
        {response && (
          <>
            <div
              style={{ display: 'flex', alignItems: 'center', gap: '.5rem', marginBottom: '1rem' }}
            >
              {isSuccess ? <EnabledIcon tooltip="" /> : <DisabledIcon tooltip="" />}
              <Typography>Response:</Typography>
            </div>
            <pre style={{ whiteSpace: 'pre-wrap' }}>
              {JSON.stringify(response?.data || 'Empty response', null, 2)}
            </pre>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton color="primary" variant="contained" onClick={onClose}>
          Close
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default TestEndpointDialog;
