import { createSlice } from '@reduxjs/toolkit';
import { RootReducerTypes } from 'redux/rootReducer';
import { dispatch, store } from 'redux/store';
import { BuildNumber, VersioningState } from '../../@types/versioning';

// ----------------------------------------------------------------------

const initialState: VersioningState = {
  frontendVersioning: {
    buildNumberUsedSinceWipe: undefined,
    latestBuildNumber: undefined
  }
};

const slice = createSlice({
  name: 'versioning',
  initialState,
  reducers: {
    setLatestFrontendBuildNumber(state, action) {
      state.frontendVersioning.latestBuildNumber = action.payload;
    },
    setBuildNumberUsedSinceWipe(state, action) {
      state.frontendVersioning.buildNumberUsedSinceWipe = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

export const handleCurrentFrontendBuildNumber = (currentBuildNumber: BuildNumber) => {
  return async () => {
    try {
      const frontendVersioningState = store.getState().versioning.frontendVersioning;
      const latestBuildNumber = frontendVersioningState.latestBuildNumber;
      if (currentBuildNumber !== latestBuildNumber) {
        dispatch(slice.actions.setLatestFrontendBuildNumber(currentBuildNumber));
      }

      //if latestBuildNumber is saved in session storage, use that, otherwise use redux value
      const buildNumberFromSessionStorage = sessionStorage.getItem('latestBuildNumber');
      const buildNumberUsedSinceWipe =
        buildNumberFromSessionStorage || frontendVersioningState.buildNumberUsedSinceWipe;

      //if the build number used since last wipe is different from the actual latest buld number
      if (buildNumberUsedSinceWipe && buildNumberUsedSinceWipe !== currentBuildNumber) {
        //update buildNumberUsedSinceWipe to current buildNumber, before wiping
        dispatch(slice.actions.setBuildNumberUsedSinceWipe(currentBuildNumber));

        //if buildNumber is not available, return - fx build number not available when running application locally
        if (!currentBuildNumber || currentBuildNumber === '#{frontendBuildNumber}#') {
          return await Promise.resolve({
            defaultSuccessMessage: 'No new frontend build number found'
          });
        }

        //return dispatch type to wipe data, and do a hard refresh
        return await Promise.resolve({
          extraSuccessInfo: RootReducerTypes.REFRESH_ON_NEW_VERSION,
          defaultSuccessMessage: 'New frontend build number found'
        });
      }

      //if buildNumberUsedSinceWipe was incorrectly updated, update now
      dispatch(
        slice.actions.setBuildNumberUsedSinceWipe(
          buildNumberFromSessionStorage || currentBuildNumber
        )
      );
      return await Promise.resolve({
        defaultSuccessMessage: 'Buildnumber was incorrectly updated, updating now'
      });
    } catch (error: any) {
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not handle new frontend build number'
      });
    }
  };
};
