import { CSSProperties, forwardRef, ReactNode } from 'react';
import { isString } from 'lodash';
// material
import { Box, Typography, Link } from '@mui/material';
//
import { MBreadcrumbs } from '../@material-extend';
import { MBreadcrumbsProps } from '../@material-extend/MBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';

// ----------------------------------------------------------------------

export interface HeaderBreadcrumbsProps extends MBreadcrumbsProps {
  action?: ReactNode;
  heading: string | JSX.Element;
  moreLink?: string | string[];
  headingIcon?: JSX.Element;
}

const HeaderBreadcrumbs = forwardRef<HTMLDivElement, HeaderBreadcrumbsProps>(
  ({ headingIcon, links, action, heading, moreLink = '' || [], sx, ...other }, ref) => {
    let wrapperStyle: CSSProperties = {
      width: 'min-content',
      paddingRight: '1rem',
      minHeight: '6rem',
      display: 'flex'
    };

    return (
      <Box sx={{ ...sx }} style={wrapperStyle} ref={ref}>
        <Box sx={{ display: 'flex', alignItems: 'center', width: 'max-content' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h4" gutterBottom>
              <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem', height: '2rem' }}>
                {headingIcon}
                {heading}
              </div>
            </Typography>
            <MBreadcrumbs
              links={[{ name: 'Home', href: PATH_DASHBOARD.root }, ...links]}
              {...other}
            />
          </Box>
          {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
        </Box>

        <Box>
          {isString(moreLink) ? (
            <Link href={moreLink} target="_blank" variant="body2">
              {moreLink}
            </Link>
          ) : (
            moreLink.map((href) => (
              <Link
                noWrap
                key={href}
                href={href}
                variant="body2"
                target="_blank"
                sx={{ display: 'table' }}
              >
                {href}
              </Link>
            ))
          )}
        </Box>
      </Box>
    );
  }
);

export default HeaderBreadcrumbs;
