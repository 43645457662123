import { createSlice } from '@reduxjs/toolkit';
import { dispatch, store } from 'redux/store';
import { OutlookState } from '../../@types/outlook';
import { getProfiles } from './profile';
import { Profile } from '../../@types/profile';
import { getSeats } from './seats';
import { Seat } from '../../@types/seats';
import axios from '../../utils/axios';
import { convertFileToFormData } from 'components/util/convert';
import { Claim } from '../../@types/organization';
import { getDropzoneError } from 'components/CustomDropzone';

// ----------------------------------------------------------------------
const initialState: OutlookState = {
  isProfilesLoading: false,
  isSeatsLoading: false,
  isSendInviteLoading: false,
  hasProfilesError: false,
  hasSeatsError: false,
  hasSendInviteError: false,
  outlookProfiles: [],
  outlookSeats: { name: 'Outlook', total: 0, taken: 0 }
};

const slice = createSlice({
  name: 'outlook',
  initialState,
  reducers: {
    //profiles
    startProfileLoading(state) {
      state.isProfilesLoading = true;
    },
    setOutlookProfiles(state, action) {
      state.outlookProfiles = action.payload;
      state.isProfilesLoading = false;
      state.hasProfilesError = false;
    },
    setErrorProfile(state) {
      state.hasProfilesError = true;
      state.isProfilesLoading = false;
      state.outlookProfiles = [];
    },

    //seats
    startSeatsLoading(state) {
      state.isSeatsLoading = true;
    },
    setOutlookSeats(state, action) {
      state.outlookSeats = action.payload ?? initialState.outlookSeats;
      state.isSeatsLoading = false;
      state.hasSeatsError = false;
    },
    setErrorSeats(state) {
      state.hasSeatsError = true;
      state.isSeatsLoading = false;
      state.outlookSeats = initialState.outlookSeats;
    },

    //invite
    startSendInviteLoading(state) {
      state.isSendInviteLoading = true;
    },
    successSendInvite(state) {
      state.isSendInviteLoading = false;
      state.hasSendInviteError = false;
    },
    setErrorSendInvite(state) {
      state.hasSendInviteError = true;
      state.isSendInviteLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
//export const {} = slice.actions;

// ----------------------------------------------------------------------

export const getOutlookProfiles = () => {
  return async () => {
    dispatch(slice.actions.startProfileLoading());
    try {
      //update profiles
      await dispatch(getProfiles());

      //get all profiles
      const profileState = store.getState().profile;

      //find outlook profiles
      const outlookProfiles = profileState.profiles?.filter((profile: Profile) =>
        profile.claims?.find((claim: Claim) => claim.claimValue === 'outlook')
      );

      dispatch(slice.actions.setOutlookProfiles(outlookProfiles));

      return await Promise.resolve({
        defaultSuccessMessage: 'Fetched Outlook profiles'
      });
    } catch (error: any) {
      dispatch(slice.actions.setErrorProfile());

      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch profiles'
      });
    }
  };
};

export const getOutlookSeats = () => {
  return async () => {
    dispatch(slice.actions.startSeatsLoading());
    try {
      //update seats
      await dispatch(getSeats());

      //get all seats
      const seatsState = store.getState().seats;

      //find outlook seats
      const outlookSeats = seatsState.seats.find((seat: Seat) => seat.name === 'Outlook');

      dispatch(slice.actions.setOutlookSeats(outlookSeats));

      return await Promise.resolve({
        defaultSuccessMessage: 'Fetched Outlook seats'
      });
    } catch (error: any) {
      dispatch(slice.actions.setErrorSeats());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch Outlook seats'
      });
    }
  };
};

type SendInviteProps = {
  email: string;
};
export const sendInvite = ({ email }: SendInviteProps) => {
  return async () => {
    dispatch(slice.actions.startSendInviteLoading());
    try {
      //define invite props
      const params = {
        email: email,
        claims: [{ claimType: 'Applications', claimValue: 'outlook' }]
      };

      //send invite
      await axios.post('/user/invite', params);

      dispatch(slice.actions.successSendInvite());

      return await Promise.resolve({
        defaultSuccessMessage: `Invite sent to ${email}`
      });
    } catch (error: any) {
      dispatch(slice.actions.setErrorSendInvite());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not send invite to ${email}`
      });
    }
  };
};

type SendInvitesExcelProps = {
  file: File | undefined;
};
export const sendInvitesExcel = ({ file }: SendInvitesExcelProps) => {
  return async () => {
    if (!file) {
      return Promise.reject({
        defaultErrorMessage: 'No file to upload'
      });
    }

    dispatch(slice.actions.startSendInviteLoading());
    try {
      await axios.post(`/user/outlookInvite`, convertFileToFormData(file));
      dispatch(slice.actions.successSendInvite());

      return await Promise.resolve({
        defaultSuccessMessage: `Invites send`
      });
    } catch (error: any) {
      dispatch(slice.actions.setErrorSendInvite());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: getDropzoneError(`Could not sent invites`)
      });
    }
  };
};
