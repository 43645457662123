import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from 'redux/store';
import {
  CampaignMember,
  CampaignMemberCategory,
  campaignMemberCategoryaAditionStatusMap,
  CampaignMembersData,
  CampaignMemberState,
  Email,
  Sms
} from '../../@types/campaignMember';
import { Note } from '../../@types/note';
import { GridFilterModel, GridLinkOperator, GridSortModel } from '@mui/x-data-grid';
import { getFilterQueryParam, getOrderbyQueryParam } from 'utils/getQueryParams';

// ----------------------------------------------------------------------
const initialCampaignMembersData: CampaignMembersData = {
  take: 0,
  skip: 0,
  campaignMembers: [],
  total: 0
};
const initialState: CampaignMemberState = {
  isLoading: {},
  campaignMember: undefined,
  notes: [],
  history: [],
  campaignMembersData: {
    [CampaignMemberCategory.RESPONSES]: initialCampaignMembersData,
    [CampaignMemberCategory.DELIVERED]: initialCampaignMembersData,
    [CampaignMemberCategory.SCHEDULED]: initialCampaignMembersData,
    [CampaignMemberCategory.UNSUBSCRIBED]: initialCampaignMembersData,
    [CampaignMemberCategory.FAILED]: initialCampaignMembersData
  },
  updateCampaignMembers: 0
};

const slice = createSlice({
  name: 'campaignMember',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    stopLoading(state, action) {
      state.isLoading[action.payload] = false;
    },
    setCampaignMembers(state, action) {
      const { campaignMembersData, campaignMemberCategory } = action.payload;
      state.campaignMembersData[campaignMemberCategory as CampaignMemberCategory] =
        campaignMembersData;
      state.isLoading[`getCampaignMembers${campaignMemberCategory}`] = false;
    },
    setCampaignMember(state, action) {
      state.campaignMember = action.payload;
      state.isLoading.getCampaignMember = false;
    },
    setNotes(state, action) {
      const newNotes = action.payload.notes as Note[];
      state.notes = newNotes.sort((a, b) => {
        const timeA = new Date(a.created).getTime();
        const timeB = new Date(b.created).getTime();
        return timeA - timeB;
      });
      state.isLoading.getNotes = false;

      const campaignMemberId = action.payload.campaignMemberId;
      if (campaignMemberId) {
        //update the notes of the relevant campaignMember, in campaignMembersData
        for (const categoryIndex in CampaignMemberCategory) {
          const category =
            CampaignMemberCategory[categoryIndex as keyof typeof CampaignMemberCategory];

          const relevantCampaignMember = state.campaignMembersData[
            category as CampaignMemberCategory
          ]?.campaignMembers?.find?.(
            (campaignMember: CampaignMember) => campaignMember.id === campaignMemberId
          );
          if (relevantCampaignMember) {
            //update entry with new notes
            state.campaignMembersData[category as CampaignMemberCategory].campaignMembers =
              state.campaignMembersData[category as CampaignMemberCategory].campaignMembers.map(
                (campaignMember) => {
                  if (campaignMember.id === relevantCampaignMember.id) {
                    return { ...campaignMember, notes: newNotes };
                  }
                  return campaignMember;
                }
              );

            // Exit the loop after the first hit is found
            break;
          }
        }
      }
    },
    setHistory(state, action) {
      state.history = action.payload;
      state.isLoading.getHistory = false;
    },
    onDeleteCampaignMemberSuccess(state) {
      state.campaignMember = initialState.campaignMember;
      state.isLoading.deleteCampaignMember = false;
    },
    campaignMemebersShouldUpdate(state) {
      state.updateCampaignMembers = state.updateCampaignMembers + 1;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { campaignMemebersShouldUpdate, setCampaignMember, setHistory } = slice.actions;

// ----------------------------------------------------------------------

//return the campaignmember with 4 extra props: surveySendTime, surveyScheduledTime, reminderSendTime, and reminderScheduledTime
const getModifiedCampaignMember = (campaignMember: CampaignMember): CampaignMember => {
  const emails = campaignMember?.emails;
  const smses = campaignMember?.sms;
  const contactEntries = emails?.length ? emails : smses.length ? smses : [];

  let surveySendTime = null;
  let surveyScheduledTime = null;
  let reminderScheduledTime = null;
  let reminderSendTime = null;
  if (contactEntries?.length) {
    //reverse entrie, so first index is the last entry
    const reversedContactEntries: (Email | Sms)[] = contactEntries.reverse();

    //survey sent, survey scheduled
    const newestMainEntry = reversedContactEntries.find((entry: any) => {
      const status = entry.emailStatus ?? entry.smsStatus;
      const type = entry.emailType ?? entry.smsType;
      return (
        (status === 'Scheduled' ||
          status === 'Added' ||
          status === 'Sent' ||
          status === 'Opened' ||
          status === 'Delivered' ||
          status === 'Bounced' ||
          status === 'Clicked' ||
          status === 'CancelledInactive' ||
          status === 'CancelledDoNotContact' ||
          status === 'CancelledEndedCampaign' ||
          status === 'CancelledReminder' ||
          status === 'Cancelled' ||
          status === 'CancelledThrottle' ||
          status === 'NotCapableOfReceivingSMS') &&
        (type === 'SurveyMail' || type === 'Survey' || type === 'Delivered')
      );
    });
    surveySendTime = newestMainEntry?.sendTime;
    surveyScheduledTime = newestMainEntry?.scheduledTime;

    //reminder sent, reminder scheduled
    const newestReminderEntry = reversedContactEntries.find((entry: any) => {
      const status = entry.emailStatus ?? entry.smsStatus;
      const type = entry.emailType ?? entry.smsType;
      return (
        (status === 'Scheduled' ||
          status === 'Added' ||
          status === 'Sent' ||
          status === 'Opened' ||
          status === 'Delivered' ||
          status === 'Bounced' ||
          status === 'Clicked' ||
          status === 'CancelledInactive' ||
          status === 'CancelledDoNotContact' ||
          status === 'CancelledEndedCampaign' ||
          status === 'CancelledReminder' ||
          status === 'Cancelled' ||
          status === 'CancelledThrottle' ||
          status === 'NotCapableOfReceivingSMS') &&
        (type === 'Reminder' || type === 'ManualResend')
      );
    });
    reminderSendTime = newestReminderEntry?.sendTime;
    reminderScheduledTime = newestReminderEntry?.scheduledTime;
  }

  return {
    ...campaignMember,
    surveySendTime: surveySendTime ?? undefined,
    surveyScheduledTime: surveyScheduledTime ?? undefined,
    reminderScheduledTime: reminderScheduledTime ?? undefined,
    reminderSendTime: reminderSendTime ?? undefined
  };
};

type GetCampaignMembersProps = {
  page?: number;
  take: number;
  campaignId: string;
  awaiting: boolean;
  sortModel: GridSortModel | undefined;
  filterModel: GridFilterModel | undefined;
  from?: string;
  to?: string;
  campaignMemberCategory: CampaignMemberCategory;
};
export const getCampaignMembers = ({
  page,
  take,
  campaignId,
  awaiting,
  sortModel,
  filterModel,
  from,
  to,
  campaignMemberCategory
}: GetCampaignMembersProps) => {
  return async () => {
    const isLoadingIndex = `getCampaignMembers${campaignMemberCategory}`;
    dispatch(slice.actions.startLoading(isLoadingIndex));
    try {
      const aditionStatusRange =
        campaignMemberCategoryaAditionStatusMap.get(campaignMemberCategory);
      const adtitionStatusMinFilter =
        typeof aditionStatusRange?.min === 'number'
          ? `additionStatus ge ${aditionStatusRange?.min}`
          : '';
      const adtitionStatusMaxFilter =
        typeof aditionStatusRange?.max === 'number'
          ? `additionStatus le ${aditionStatusRange?.max}`
          : '';

      //ratedTime, used for Responses
      let fromDataFilterRatedTime = from ? `response/rated gt ${from}` : '';
      let toDataFilterRatedTime = to ? `response/rated lt ${to}` : '';
      //firstSurveyExposure, used for Delivered, Scheduled and Failed
      let fromDataFilterFirstSurveyExposure = from ? `firstSurveyExposure gt ${from}` : '';
      let toDataFilterFirstSurveyExposure = to ? `firstSurveyExposure lt ${to}` : '';

      /**
       * FILTER
       */
      const campaignMemberFilterItems = [`campaignId eq ${campaignId}`];

      //responsibleEmployee filter logic is handled below, so remove if present
      let updatedFilterModel = filterModel
        ? {
            ...filterModel,
            items: filterModel.items.filter(
              (filterItem) => filterItem.columnField !== 'responsibleEmployee'
            )
          }
        : undefined;
      let responsibleEmployeeFilter = '';
      if (filterModel) {
        //if responsible employee is filtered, filter on firstName, lastName, and email
        const responsibleEmployeeFilterItem = filterModel?.items.find(
          (filterItem) => filterItem.columnField === 'responsibleEmployee'
        );

        if (responsibleEmployeeFilterItem && responsibleEmployeeFilterItem.value) {
          //create array of each entered word, removing empty strings
          const responsibleEmployeeFilterWords = responsibleEmployeeFilterItem.value
            .split(' ')
            .filter(Boolean);
          //each entered word should be contained in firstName, lastName, or email
          responsibleEmployeeFilter = responsibleEmployeeFilterWords
            .map(
              (word: string) =>
                `(${getFilterQueryParam(
                  {
                    items: [
                      {
                        columnField: 'responsibleEmployee/firstName',
                        operatorValue: 'contains',
                        value: word
                      },
                      {
                        columnField: 'responsibleEmployee/lastName',
                        operatorValue: 'contains',
                        value: word
                      },
                      {
                        columnField: 'responsibleEmployee/email',
                        operatorValue: 'contains',
                        value: word
                      }
                    ],
                    linkOperator: GridLinkOperator.Or
                  },
                  true,
                  true
                )})`
            )
            .join(' and ');
        }
      }
      const customFilter = getFilterQueryParam(updatedFilterModel, true, true);
      if (customFilter) {
        campaignMemberFilterItems.push(customFilter);
      }
      if (responsibleEmployeeFilter) {
        campaignMemberFilterItems.push(responsibleEmployeeFilter);
      }

      //TODO: remove below once we have ensured that aditionStatus codes are updated correctly in all scenarios, and add aditionStatuisFilters to all campaignmember categories
      if (campaignMemberCategory === CampaignMemberCategory.RESPONSES) {
        campaignMemberFilterItems.push(`response ${awaiting ? 'eq' : 'ne'} null`);
        campaignMemberFilterItems.push(fromDataFilterRatedTime);
        campaignMemberFilterItems.push(toDataFilterRatedTime);
      } else {
        campaignMemberFilterItems.push(adtitionStatusMinFilter);
        campaignMemberFilterItems.push(adtitionStatusMaxFilter);
        campaignMemberFilterItems.push(fromDataFilterFirstSurveyExposure);
        campaignMemberFilterItems.push(toDataFilterFirstSurveyExposure);
      }

      //TODO: add below once we have ensured that aditionStatus codes are updated correctly in all scenarios, and add aditionStatuisFilters to all campaignmember categories
      /*
      campaignMemberFilterItems.push(adtitionStatusMinFilter);
      campaignMemberFilterItems.push(adtitionStatusMaxFilter);
      if (campaignMemberCategory === CampaignMemberCategory.RESPONSES) {
        campaignMemberFilterItems.push(fromDataFilterRatedTime);
        campaignMemberFilterItems.push(toDataFilterRatedTime);
      } else {
        campaignMemberFilterItems.push(fromDataFilterFirstSurveyExposure);
        campaignMemberFilterItems.push(toDataFilterFirstSurveyExposure);
      }
      */

      const campaignMemberFilter = `$filter=${campaignMemberFilterItems
        .filter((x) => x)
        .join(' and ')}`;

      /**
       * ORDERBY
       */
      let updatedSortModel = sortModel?.filter(
        (sortModelItem) => sortModelItem.field !== 'responsibleEmployee'
      ) as GridSortModel;
      //if responsible employee is sorted, sort on firstName, lastName, and email, in that order
      const responsibleEmployeeSortItem = sortModel?.find(
        (sortModelItem) => sortModelItem.field === 'responsibleEmployee'
      );
      if (responsibleEmployeeSortItem) {
        updatedSortModel.push({
          ...responsibleEmployeeSortItem,
          field: 'responsibleEmployee/firstName'
        });
        updatedSortModel.push({
          ...responsibleEmployeeSortItem,
          field: 'responsibleEmployee/lastName'
        });
        updatedSortModel.push({
          ...responsibleEmployeeSortItem,
          field: 'responsibleEmployee/email'
        });
      }

      const skip = (page || 0) * take;
      const response = await axios.get(
        `/odata/campaignmembers?$count=true&$top=${take}&$skip=${skip}&${campaignMemberFilter}${getOrderbyQueryParam(
          updatedSortModel
        )}&$expand=response($expand=category),responsibleEmployee,respondent,campaign($expand=template),notes,emails,sms`
      );

      const campaignMembers =
        response.data.value?.map((campaignMember: CampaignMember) =>
          getModifiedCampaignMember(campaignMember)
        ) ?? [];

      const newCampaignMembersData = {
        take: take,
        skip: skip,
        campaignMembers: campaignMembers,
        total: response.data?.['@odata.count'] || 0
      };

      dispatch(
        slice.actions.setCampaignMembers({
          campaignMembersData: newCampaignMembersData,
          campaignMemberCategory: campaignMemberCategory
        })
      );
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Campaign members fetched'
      });
    } catch (error) {
      dispatch(
        slice.actions.setCampaignMembers({
          campaignMembersData: [],
          campaignMemberCategory: campaignMemberCategory
        })
      );
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch campaign members'
      });
    }
  };
};

type GetCampaignMemberProps = {
  id: number;
};
export const getCampaignMember = ({ id }: GetCampaignMemberProps) => {
  return async () => {
    dispatch(slice.actions.startLoading('getCampaignMember'));
    try {
      const response = await axios.get(
        `/odata/campaignmembers(${id})?$expand=response($expand=category),responsibleEmployee,respondent($expand=company),campaign($expand=template)`
      );
      dispatch(slice.actions.setCampaignMember(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Campaign member fetched'
      });
    } catch (error) {
      dispatch(slice.actions.setCampaignMember(undefined));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch campaign member'
      });
    }
  };
};

type EditCampaignMemberProps = {
  campaignMemberId: string;
  updatedCampaignMember: Partial<CampaignMember>;
};
export const editCampaignMember = ({
  campaignMemberId,
  updatedCampaignMember
}: EditCampaignMemberProps) => {
  return async () => {
    dispatch(slice.actions.startLoading('editCampaignMember'));
    try {
      const response = await axios.put(
        `/odata/campaignmembers(${campaignMemberId})`,
        updatedCampaignMember
      );
      dispatch(slice.actions.stopLoading('editCampaignMember'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Campaign member edited'
      });
    } catch (error) {
      dispatch(slice.actions.stopLoading('editCampaignMember'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not edit campaign member'
      });
    }
  };
};

type DeleteCampaignMemberProps = {
  campaignMember: CampaignMember;
};
export function deleteCampaignMember({ campaignMember }: DeleteCampaignMemberProps) {
  return async () => {
    dispatch(slice.actions.startLoading('deleteCampaignMember'));
    await axios
      .delete(`campaigns/${campaignMember.campaignId}/members/${campaignMember.id}`)
      .catch((error: any) => {
        dispatch(slice.actions.stopLoading('deleteCampaignMember'));
        return Promise.reject({
          error: error,
          defaultErrorMessage: 'Campaignmember could not be deleted'
        });
      });
    dispatch(slice.actions.onDeleteCampaignMemberSuccess());
    return Promise.resolve({
      defaultSuccessMessage: 'Campaignmember deleted'
    });
  };
}

type GetNotesProps = {
  campaignMemberId: number;
};
export const getNotes = ({ campaignMemberId }: GetNotesProps) => {
  return async () => {
    dispatch(slice.actions.startLoading('getNotes'));
    try {
      const response = await axios.get(`/v2/campaignMembers/${campaignMemberId}/notes`);
      dispatch(
        slice.actions.setNotes({ notes: response.data, campaignMemberId: campaignMemberId })
      );
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Notes fetched'
      });
    } catch (error) {
      dispatch(slice.actions.setNotes({ notes: [], campaignMemberId: campaignMemberId }));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch notes'
      });
    }
  };
};

type CreateNoteProps = {
  campaignMemberId: number;
  text: string;
};
export const createNote = ({ campaignMemberId, text }: CreateNoteProps) => {
  return async () => {
    dispatch(slice.actions.startLoading('createNote'));
    try {
      const response = await axios.post(`/v2/campaignMembers/${campaignMemberId}/notes`, {
        note: text
      });
      dispatch(slice.actions.stopLoading('createNote'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Note added'
      });
    } catch (error) {
      dispatch(slice.actions.stopLoading('createNote'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not add note'
      });
    }
  };
};

type EditNoteProps = {
  campaignMemberId: number;
  noteId: string;
  text: string;
};
export const editNote = ({ campaignMemberId, noteId, text }: EditNoteProps) => {
  return async () => {
    const isLoadingIndex = `editNote${noteId}`;
    dispatch(slice.actions.startLoading(isLoadingIndex));
    try {
      const response = await axios.put(`/v2/campaignMembers/${campaignMemberId}/notes/${noteId}`, {
        note: text
      });
      dispatch(slice.actions.stopLoading(isLoadingIndex));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Note edited'
      });
    } catch (error) {
      dispatch(slice.actions.stopLoading(isLoadingIndex));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not edit note'
      });
    }
  };
};

type DeleteNoteProps = {
  campaignMemberId: number;
  noteId: string;
};
export const deleteNote = ({ campaignMemberId, noteId }: DeleteNoteProps) => {
  return async () => {
    const isLoadingIndex = `deleteNote${noteId}`;
    dispatch(slice.actions.startLoading(isLoadingIndex));
    try {
      const response = await axios.delete(
        `/v2/campaignMembers/${campaignMemberId}/notes/${noteId}`
      );
      dispatch(slice.actions.stopLoading(isLoadingIndex));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Notes deleted'
      });
    } catch (error) {
      dispatch(slice.actions.stopLoading(isLoadingIndex));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not delete note'
      });
    }
  };
};

type GetHistoryProps = {
  publicGuid: string;
};
export function getHistory({ publicGuid }: GetHistoryProps) {
  return async () => {
    dispatch(slice.actions.startLoading('getHistory'));
    try {
      const response = await axios.get(`/history/campaignMembers/additionStatus/${publicGuid}`);
      dispatch(slice.actions.setHistory(response.data));

      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched history'
      });
    } catch (error) {
      dispatch(slice.actions.setHistory([]));

      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch history'
      });
    }
  };
}
