import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
//
import DashboardNavbar, { APPBAR_HEIGHT } from './DashboardNavbar';
import DashboardSidebar, { COLLAPSE_WIDTH, DRAWER_WIDTH } from './DashboardSidebar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100vh',
  position: 'relative'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  minHeight: '100%',
  width: '100%',
  position: 'relative',
  marginTop: APPBAR_HEIGHT,
  marginBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick, isDrawerForcedHidden } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  const drawerWidth = collapseClick ? COLLAPSE_WIDTH : DRAWER_WIDTH;

  return (
    <RootStyle style={{ maxWidth: '100vw' }}>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle
        sx={{
          maxWidth: isDrawerForcedHidden ? '100vw' : `calc(100% - ${drawerWidth}px)`
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
