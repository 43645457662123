import { Box, Stack, Tooltip, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { GridColumnVisibilityModel, GridFilterItem, GridFilterModel } from '@mui/x-data-grid';
import { GridColDef, GridRowData } from '@mui/x-data-grid';
import {
  CustomGridToolbar,
  CustomLoadingOverlay,
  CustomServerDataGrid
} from 'components/datagrid/Custom';
import formatHtmlStringToText from 'components/util/formatHtmlStringToText';
import DisabledIcon from 'components/util/Status/DisabledIcon';
import EnabledIcon from 'components/util/Status/EnabledIcon';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Template } from '../../../@types/campaign';
import { PRIMARY_COLOR } from '../../../contexts/SettingsContext';
import { RootState, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { fDate, fShortDate } from '../../../utils/formatTime';
import Icon from 'components/icons/Icon';
import { DataGridKey } from 'redux/slices/datagrid';
import Rating from 'components/campaignOverview/Rating';
import useCustomDispatch from 'redux/dispatch';
import { getCampaignsList } from 'redux/slices/campaign';
import { getThrottlingGroups } from 'redux/slices/throttlingGroup';
import { ActiveListItem } from 'components/datagrid/listItems/ActiveListItem';
import { EditListItem } from 'components/datagrid/listItems/EditListItem';
import { useTranslator } from 'translation/useTranslator';

// ----------------------------------------------------------------------

let thumbSize = 48;

export function getNpsColor(npsScore: number): string {
  if (npsScore < -1) return PRIMARY_COLOR[2].main;
  if (npsScore > 30) return PRIMARY_COLOR[0].main;
  else return PRIMARY_COLOR[1].main;
}

enum CAMPAIGN_TYPES {
  BLOCK_SEND_OUTS = 'Block send outs',
  LINK = 'Link',
  SMS = 'SMS',
  EMAIL = 'E-mail'
}

const getCampaignType = (gridRow: GridRowData) => {
  const template = gridRow.template as Template;
  if (template.manualDelivery as boolean) {
    return CAMPAIGN_TYPES.BLOCK_SEND_OUTS;
  }
  if (gridRow.universalLinkCampaign as boolean) {
    return CAMPAIGN_TYPES.LINK;
  }
  if (template.smsCampaign as boolean) {
    return CAMPAIGN_TYPES.SMS;
  }
  return CAMPAIGN_TYPES.EMAIL;
};

// ----------------------------------------------------------------------

interface CampaignListProps {
  selectionModel: any;
  setSelectionModel: any;
  initialFilterModel?: GridFilterModel;
  enforcedFilterItem?: GridFilterItem;
  dataGridKey?: DataGridKey;
  height?: string;
  intitialColumnsHidden?: GridColumnVisibilityModel;
}

export default function CampaignList(props: CampaignListProps) {
  const translator = useTranslator();
  const DATA_GRID_KEY = props.dataGridKey || DataGridKey.CampaignList;
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const customDispatch = useCustomDispatch();
  const { throttlingGroups } = useSelector((state: RootState) => state.throttlingGroup);
  const { campaigns, isLoading } = useSelector((state: RootState) => state.campaign);
  const { density, filtering, sorting } = useSelector((state: RootState) => state.datagrid);
  const navigate = useNavigate();

  useEffect(() => {
    if (DATA_GRID_KEY !== DataGridKey.CampaignList) return;
    customDispatch({ action: getThrottlingGroups, disableSuccessMessage: true });
  }, [customDispatch, DATA_GRID_KEY]);

  useEffect(() => {
    customDispatch({
      action: getCampaignsList,
      actionParameters: {
        sortModel: sorting[DATA_GRID_KEY],
        filterModel: filtering[DATA_GRID_KEY],
        enforcedFilterModel: props.enforcedFilterItem
          ? {
              items: [
                {
                  ...props.enforcedFilterItem
                }
              ],
              linkOperator: 'and',
              quickFilterValues: [],
              quickFilterLogicOperator: 'and'
            }
          : undefined,
        modifyFilterModel: (newFilterModel?: GridFilterModel) => {
          return !newFilterModel
            ? newFilterModel
            : {
                ...newFilterModel,
                items:
                  newFilterModel.items?.map((item) => {
                    if (item.columnField === 'throttlingGroupId' && item.value === 'default') {
                      return {
                        id: item.id,
                        columnField: 'throttleByDefaultGroup',
                        operatorValue: 'is',
                        value: true
                      };
                    }
                    if (item.columnField === 'type') {
                      const typeFilterItem = {
                        ...item,
                        operatorValue: 'is'
                      } as GridFilterItem;

                      switch (typeFilterItem.value as CAMPAIGN_TYPES) {
                        case CAMPAIGN_TYPES.BLOCK_SEND_OUTS:
                          typeFilterItem.columnField = 'template/manualDelivery';
                          typeFilterItem.value = true;
                          break;
                        case CAMPAIGN_TYPES.LINK:
                          typeFilterItem.columnField = 'universalLinkCampaign';
                          typeFilterItem.value = true;
                          break;
                        case CAMPAIGN_TYPES.SMS:
                          typeFilterItem.columnField = 'template/smsCampaign';
                          typeFilterItem.value = true;
                          break;
                        case CAMPAIGN_TYPES.EMAIL:
                        default:
                          typeFilterItem.columnField = 'template/smsCampaign';
                          typeFilterItem.value = false;
                          break;
                      }
                      return typeFilterItem;
                    }
                    return item;
                  }) ?? []
              };
        },
        top: pageSize,
        skip: page * pageSize
      },
      disableSuccessMessage: true
    });
  }, [customDispatch, props.enforcedFilterItem, sorting, filtering, DATA_GRID_KEY, page, pageSize]);

  useEffect(() => {
    if (density[DATA_GRID_KEY] === 'compact') {
      thumbSize = 30;
    } else if (density[DATA_GRID_KEY] === 'standard') {
      thumbSize = 46;
    } else if (density[DATA_GRID_KEY] === 'comfortable') {
      thumbSize = 62;
    }
  }, [density, DATA_GRID_KEY]);

  /**
   * Below columns with a field name ending with '_analytics', are used when fetching campaigns in the redux action getCampaignsList.
   */
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
        type: 'number'
      },
      {
        field: 'logo',
        headerName: translator.logo(),
        headerAlign: 'center',
        width: 120,
        align: 'center',
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          const url = params.row.template.logoURL as string;
          if (url) {
            return (
              <Avatar
                imgProps={{ sx: { objectFit: 'contain' } }}
                variant="square"
                src={url}
                sx={{ width: thumbSize, height: thumbSize }}
              />
            );
          }
        }
      },
      {
        field: 'score_analytics',
        filterable: false,
        headerName: translator.nps(),
        headerAlign: 'center',
        width: 120,
        align: 'center',
        type: 'number',
        renderCell: (params) => {
          const npsScore = params.row.npsScore as number;
          return <Rating rating={npsScore} dataGridKey={DATA_GRID_KEY} />;
        }
      },
      {
        field: 'name',
        headerName: translator.campaignName(),
        flex: 1
      },
      {
        field: 'respondentCount_analytics',
        filterable: false,
        headerName: translator.respondents(),
        type: 'number',
        valueGetter: (params) => params.row.respondentCount
      },
      {
        field: 'responseCount_analytics',
        filterable: false,
        headerName: translator.responses(),
        type: 'number',
        valueGetter: (params) => params.row.responseCount
      },
      {
        field: 'responsePercentage_analytics',
        filterable: false,
        headerName: translator.responsePercentage(),
        type: 'number',
        valueGetter: (params) =>
          typeof params.row.responsePercentage === 'number'
            ? `${Math.round(params.row.responsePercentage)}%`
            : ''
      },
      {
        field: 'created',
        headerName: translator.created(),
        width: 120,
        type: 'date',
        renderCell: (params) => {
          const created = params.row.created as string;
          return (
            <Tooltip title={fDate(created)}>
              <Typography noWrap variant="body2">
                {fShortDate(created)}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'template/reminderDelay',
        headerName: translator.reminderDelay(),
        type: 'number',
        valueGetter: (params) => {
          const isReminderDelayEnabled = params.row.template.sendReminder as boolean;
          if (!isReminderDelayEnabled) {
            return;
          }
          const reminderDelay = params.row.template.reminderDelay as number;
          const days = Math.floor(reminderDelay / 24);
          const hours = reminderDelay % 24;
          return days + hours;
        },
        renderCell: (params) => {
          const isReminderDelayEnabled = params.row.template.sendReminder as boolean;
          if (!isReminderDelayEnabled) {
            return <DisabledIcon />;
          }
          const reminderDelay = params.row.template.reminderDelay as number;
          const days = Math.floor(reminderDelay / 24);
          const hours = reminderDelay % 24;
          return `${days ? `${days}d ` : ' '} ${hours ? `${hours}h` : ' '}`;
        }
      },
      {
        field: 'template/sendoutDelay',
        headerName: translator.sendoutDelayHeading(),
        type: 'number',
        valueGetter: (params) => {
          const sendoutDelay = params.row.template.sendoutDelay as string;
          if (!sendoutDelay || sendoutDelay === '00:00:00') {
            return;
          }
          const sendoutArray = sendoutDelay.split(/[.:]/);
          if (sendoutArray.length === 4) {
            const days = sendoutArray[0];
            const hours = sendoutArray[1];
            const minutes = sendoutArray[2];
            const seconds = sendoutArray[3];
            return days + hours + minutes + seconds;
          } else {
            const hours = sendoutArray[0];
            const minutes = sendoutArray[1];
            const seconds = sendoutArray[2];
            return hours + minutes + seconds;
          }
        },
        renderCell: (params) => {
          const sendoutDelay = (params.row.template.sendoutDelay as string) || '00:00:00';
          const sendoutArray = sendoutDelay.split(/[.:]/);
          const days = Number(sendoutArray[0]);
          const hours = Number(sendoutArray[1]);
          const minutes = Number(sendoutArray[2]);
          const seconds = Number(sendoutArray[3]);

          if (!(days || hours || minutes || seconds)) {
            return <DisabledIcon />;
          }

          return `${days ? `${days}d ` : ' '}${hours ? `${hours}h ` : ' '}${
            minutes ? `${minutes}m ` : ' '
          }${seconds ? `${seconds}s` : ''}`;
        }
      },
      {
        field: 'throttlingDays',
        headerName: translator.throttlingTitle(),
        type: 'number',
        renderCell: (params) => {
          const isThrottlingEnabled = params.row.enableThrottling as boolean;
          if (!isThrottlingEnabled) {
            return <DisabledIcon />;
          }
          const throttlingDays = params.row.throttlingDays as number;
          if (throttlingDays < 1) {
            return <DisabledIcon />;
          }
          return `${throttlingDays}d`;
        }
      },
      {
        field: 'throttlingGroupId',
        headerName: translator.throttlingGroup(),
        type: 'singleSelect',
        sortable: false,
        valueOptions: throttlingGroups.map((throttlingGroup) => {
          return {
            id: throttlingGroup.id,
            value: throttlingGroup.id || 'default',
            label: throttlingGroup.name
          };
        }),
        renderCell: (params) => {
          const throttleByDefaultGroup = params.row.throttleByDefaultGroup as boolean;
          const throttlingGroupId = params.row.throttlingGroupId as string;

          if (!throttlingGroupId && !throttleByDefaultGroup) return '';

          const throttlingGroup = throttlingGroups.find((throttlingGroup) => {
            if (throttleByDefaultGroup) {
              return !throttlingGroup.id;
            }
            return throttlingGroup.id === throttlingGroupId;
          });
          return throttlingGroup?.name;
        },
        valueGetter: (params) => {
          const throttleByDefaultGroup = params.row.throttleByDefaultGroup as boolean;
          const throttlingGroupId = params.row.throttlingGroupId as string;

          if (!throttlingGroupId && !throttleByDefaultGroup) return '';

          const throttlingGroup = throttlingGroups.find((throttlingGroup) => {
            if (throttleByDefaultGroup) {
              return !throttlingGroup.id;
            }
            return throttlingGroup.id === throttlingGroupId;
          });
          return throttlingGroup?.name;
        }
      },
      {
        field: 'template/forceAnonymous',
        headerName: translator.forceAnonymousTitle(),
        type: 'boolean',
        renderCell: (params) => {
          const isForceAnonymous = params.row.template.forceAnonymous as boolean;
          return isForceAnonymous ? <EnabledIcon /> : <DisabledIcon />;
        }
      },
      {
        field: 'template/allowDuplicates',
        headerName: translator.allowDuplicates(),
        type: 'boolean',
        valueGetter: (params) => {
          return params.row.template.allowDuplicates as boolean;
        },
        renderCell: (params) => {
          const allowDuplicates = params.row.template.allowDuplicates;
          return <ActiveListItem active={allowDuplicates} />;
        }
      },
      {
        field: 'template/content',
        headerName: translator.question(),
        flex: 1,
        valueGetter: (params) => {
          return params.row.template.content as string;
        },
        renderCell: (params) => {
          const content = params.row.template.content as string;
          const plainText = formatHtmlStringToText(content);
          return (
            <Tooltip title={plainText}>
              <Typography noWrap variant="body2">
                {plainText}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'stopTime',
        headerName: translator.active(),
        flex: 1,
        sortable: false,
        filterable: false,
        type: 'boolean',
        valueGetter: (params) => {
          return !Boolean(params.row?.stopTime);
        },
        renderCell: (params) => {
          return (
            <ActiveListItem
              active={!Boolean(params.row?.stopTime)}
              activeTooltip={translator.campaignIsActive()}
              inactiveTooltip={translator.campaignIsClosed()}
            />
          );
        }
      },
      {
        field: 'type',
        valueGetter: (params) => getCampaignType(params.row),
        headerName: translator.type(),
        width: 120,
        // hide: false,
        align: 'center',
        sortable: false,
        renderCell: (params) => {
          const manualDelivery = params.row.template.manualDelivery as boolean;
          const universalLinkCampaign = params.row.universalLinkCampaign as boolean;
          const smsCampaign = params.row.template.smsCampaign as boolean;

          return (
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              {manualDelivery && (
                <Tooltip title={translator.blockSendOuts()}>
                  <Icon type="manual" />
                </Tooltip>
              )}
              {universalLinkCampaign && (
                <Tooltip title={translator.linkCampaign()}>
                  <Icon type="link" />
                </Tooltip>
              )}
              {smsCampaign && (
                <Tooltip title={translator.smsCampaign()}>
                  <Icon type="sms" />
                </Tooltip>
              )}
              {!smsCampaign && (
                <Tooltip title={translator.emailCampaign()}>
                  <Icon type="email" />
                </Tooltip>
              )}
            </Stack>
          );
        },
        type: 'singleSelect',
        valueOptions: Object.values(CAMPAIGN_TYPES).map((campaignType) => {
          return {
            id: campaignType,
            value: campaignType,
            label: campaignType
          };
        })
      },
      {
        field: 'edit',
        headerName: translator.edit(),
        width: 60,
        hideable: false,
        editable: false,
        align: 'center',
        hideSortIcons: true,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        disableExport: true,
        renderCell: (cellValues) => {
          return (
            <EditListItem
              onClick={() => {
                navigate(
                  PATH_DASHBOARD.campaigns.editCampaignByID.replace(
                    ':id',
                    cellValues.id?.toString?.() ?? ''
                  )
                );
              }}
            />
          );
        }
      }
    ],
    [navigate, throttlingGroups, DATA_GRID_KEY, translator]
  );

  return (
    <Box>
      <CustomServerDataGrid
        height={props.height}
        intitialColumnsHidden={
          props.intitialColumnsHidden || {
            logo: false,
            'template/allowDuplicates': false,
            'template/forceAnonymous': false,
            throttlingDays: false,
            throttlingGroupId: false,
            'template/sendoutDelay': false,
            'template/reminderDelay': false,
            stopTime: false
          }
        }
        rowsPerPageOptions={[5, 10, 20]}
        pageSize={pageSize}
        page={page}
        onPageChange={(page) => {
          setPage(page);
        }}
        onPageSizeChange={(pageSize) => {
          setPageSize(pageSize);
        }}
        paginationMode="server"
        modifySortModelOnChange={(newSortModel) => {
          return newSortModel;
        }}
        dataGridKey={DATA_GRID_KEY}
        columns={columns}
        rows={campaigns.results}
        rowCount={campaigns.total}
        loading={isLoading}
        disableSelectionOnClick
        pagination
        onCellClick={(params) => {
          document.body.style.cursor = 'default';
          if (params.colDef.field !== 'edit')
            navigate(`${PATH_DASHBOARD.campaigns.root}/${params.id}`);
        }}
        onSelectionModelChange={(newSelectionModel) => {
          props.setSelectionModel(newSelectionModel);
        }}
        selectionModel={props.selectionModel}
        components={{
          Toolbar: CustomGridToolbar,

          LoadingOverlay: CustomLoadingOverlay
        }}
      />
    </Box>
  );
}
