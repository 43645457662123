import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import axiosDefault from 'axios';
import { dispatch } from 'redux/store';
import { IVRState } from '../../@types/ivr';
import { IvrMap } from '../../@types/organization';

// ----------------------------------------------------------------------
const initialState: IVRState = {
  isLoading: false,
  error: false,
  ivrTypes: [],
  isTestLoading: false,
  ivrToTest: undefined,
  ivrIdToTest: undefined,
  testResponse: undefined
};

const slice = createSlice({
  name: 'ivr',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setIvrTypes(state, action) {
      state.ivrTypes = action.payload;
    },
    startTest(state) {
      state.isTestLoading = true;
    },
    setTestResponse(state, action) {
      state.isTestLoading = false;
      state.testResponse = action.payload;
    },
    setIvrToTest(state, action) {
      state.ivrToTest = action.payload;
    },
    setIvrIdToTest(state, action) {
      state.ivrIdToTest = action.payload;
    },
    resetIvrTest(state) {
      state.ivrToTest = undefined;
      state.ivrIdToTest = undefined;
      state.testResponse = undefined;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setIvrToTest, setIvrIdToTest, resetIvrTest } = slice.actions;

// ----------------------------------------------------------------------

export const getIVRProviders = () => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/ivr/providers`);
      dispatch(slice.actions.setIvrTypes(response.data));
      dispatch(slice.actions.stopLoading());
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched IVR providers'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch IVR providers`
      });
    }
  };
};

export const getIvrMapToTest = (ivrId: number) => {
  return async () => {
    dispatch(slice.actions.startTest());

    try {
      const response = await axios.get('/ivr/maps/' + ivrId);
      dispatch(slice.actions.setIvrToTest(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched IVR map to test'
      });
    } catch (error: any) {
      dispatch(slice.actions.setTestResponse(error));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch IVR map to test'
      });
    }
  };
};

export const testIvrConnection = ({
  ivr,
  testUrl,
  from,
  to
}: {
  ivr?: IvrMap;
  testUrl: string;
  from: number;
  to: number;
}) => {
  return async () => {
    dispatch(slice.actions.startTest());

    const params = {
      from: typeof from === 'number' ? new Date(from)?.toISOString?.() : from,
      to: typeof to === 'number' ? new Date(to)?.toISOString?.() : to
    };

    try {
      const response = await axiosDefault.post(testUrl, ivr, { params });
      dispatch(slice.actions.setTestResponse(response));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'IVR connection test recieved result'
      });
    } catch (error: any) {
      dispatch(slice.actions.setTestResponse(error));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'IVR connection test failed'
      });
    }
  };
};
