import { GridColDef } from '@mui/x-data-grid';
import { CustomDataGrid } from 'components/datagrid/Custom';
import { useEffect } from 'react';
import { DataGridKey } from 'redux/slices/datagrid';
import { Box } from '@mui/system';
import { PATH_DASHBOARD } from 'routes/paths';
import { useNavigate } from 'react-router';
import useCustomDispatch, { useCustomDispatchWithoutPromise } from 'redux/dispatch';
import { RootState, useSelector } from 'redux/store';
import { getOrders, resetOrders } from 'redux/slices/orders';
import { IntegrationLogo } from 'pages/integrationStore/components/IntegrationLogo';
import { Integration, ProductCategory } from '../../../../@types/integrations';
import { baseOrderColumns, baseOrderInitialColumnsHidden } from '../baseOrder/baseOrderColumns';

export default function ProductOrderList() {
  const navigate = useNavigate();
  const customDispatch = useCustomDispatch();
  const customDispatchWithoutPromise = useCustomDispatchWithoutPromise();
  const { isLoading, orderData } = useSelector((state: RootState) => state.order);
  useEffect(() => {
    (async () => {
      await customDispatch({
        action: getOrders,
        actionParameters: { endpoint: 'product-orders' },
        disableSuccessMessage: true
      });
    })();
    return () => {
      customDispatchWithoutPromise({
        action: resetOrders,
        disableAllMessages: true
      });
    };
  }, [customDispatch, customDispatchWithoutPromise]);

  const columns: GridColDef[] = [
    {
      field: 'integration',
      headerName: 'Product',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={'flex'} justifyContent={'space-between'} flex={1} alignItems={'center'}>
            {params.row.integration.name}
            <IntegrationLogo integration={params.row.integration as Integration} />
          </Box>
        );
      }
    },
    {
      field: 'integration/productCategory',
      headerName: 'Type',
      flex: 1,
      valueGetter: (params) => {
        return params.row.integration.productCategory as ProductCategory;
      },
      renderCell: (params) => {
        return ProductCategory[params.row.integration.productCategory as ProductCategory];
      }
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      type: 'number'
    },
    ...baseOrderColumns
  ];
  return (
    <CustomDataGrid
      intitialColumnsHidden={{
        integration: true,
        ...baseOrderInitialColumnsHidden
      }}
      dataGridKey={DataGridKey.Orders}
      columns={columns}
      loading={isLoading}
      rows={orderData.orders}
      onCellClick={(params) => {
        navigate(`${PATH_DASHBOARD.orders.productOrders}/${params.id}`);
      }}
      pagination
      pageSize={20}
    />
  );
}
