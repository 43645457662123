export enum IconType {
  account = 'account',
  add = 'add',
  arrowright = 'arrowright',
  beta = 'beta',
  campaignactive = 'campaignactive',
  campaignclosed = 'campaignclosed',
  calendar = 'calendar',
  campaigns = 'campaigns',
  clear = 'clear',
  clock = 'clock',
  copy = 'copy',
  companies = 'companies',
  customize = 'customize',
  darkmode = 'darkmode',
  dashboard = 'dashboard',
  dataretention = 'dataretention',
  delete = 'delete',
  disable = 'disable',
  disabled = 'disabled',
  display = 'display',
  download = 'download',
  edit = 'edit',
  email = 'email',
  employees = 'employees',
  enable = 'enable',
  enabled = 'enabled',
  expanddown = 'expanddown',
  eyeoff = 'eyeoff',
  eyeon = 'eyeon',
  flag = 'flag',
  forwardemail = 'forwardemail',
  fullscreen = 'fullscreen',
  general = 'general',
  information = 'information',
  integrationstore = 'integrationstore',
  ivr = 'ivr',
  key = 'key',
  license = 'license',
  lightmode = 'lightmode',
  link = 'link',
  logo = 'logo',
  manual = 'manual',
  moremenu = 'moremenu',
  notreload = 'notreload',
  note = 'note',
  npsnotification = 'npsnotification',
  orderandusage = 'orderandusage',
  organizations = 'organizations',
  overview = 'overview',
  pgap = 'pgap',
  paragraphCenterAlign = 'paragraphCenterAlign',
  paragraphLeftAlign = 'paragraphLeftAlign',
  paragraphRightAlign = 'paragraphRightAlign',
  password = 'password',
  person = 'person',
  phone = 'phone',
  providers = 'providers',
  question = 'question',
  questionwithoutcircle = 'questionwithoutcircle',
  reload = 'reload',
  reports = 'reports',
  resendsurvey = 'resendsurvey',
  reset = 'reset',
  respondents = 'respondents',
  reviews = 'reviews',
  search = 'search',
  security = 'security',
  senders = 'senders',
  settings = 'settings',
  sms = 'sms',
  superadmin = 'superadmin',
  template = 'template',
  throttlinggroup = 'throttlinggroup',
  unsubscribe = 'unsubscribe',
  user = 'user',
  users = 'users',
  equal = 'equal',
  navigationnext = 'navigationnext',
  navigationprevious = 'navigationprevious',
  swap = 'swap',
  connection = 'connection',
  curlybrackets = 'curlybrackets',
  success = 'success',
  warning = 'warning',
  error = 'error',
  menu = 'menu',
  pin = 'pin',
  productconfigurations = 'productconfigurations',
  qrcode = 'qrcode',
  robot = 'robot'
}
