import { hasFlag } from 'country-flag-icons';
import Flags from 'country-flag-icons/react/3x2';
import { getCountryCodeFromLanguageName } from 'utils/language';

type CountryFlagProps = {
  country: string;
  height?: string;
};
const CountryFlag = ({ country, height = '1rem' }: CountryFlagProps) => {
  let countryCode = country;

  if (!hasFlag(country)) {
    countryCode = getCountryCodeFromLanguageName(country);
  }

  const Flag = Flags[countryCode as keyof typeof Flags];
  return <>{Flag && <Flag style={{ height: height }} />}</>;
};

export default CountryFlag;
